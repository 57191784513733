/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SimulationStatusDetail } from './SimulationStatusDetail';
import {
    SimulationStatusDetailFromJSON,
    SimulationStatusDetailFromJSONTyped,
    SimulationStatusDetailToJSON,
} from './SimulationStatusDetail';

/**
 * 
 * @export
 * @interface SimulationOutput
 */
export interface SimulationOutput {
    /**
     * 
     * @type {string}
     * @memberof SimulationOutput
     */
    simulationId: string;
    /**
     * 
     * @type {string}
     * @memberof SimulationOutput
     */
    facilityId: string;
    /**
     * 
     * @type {string}
     * @memberof SimulationOutput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SimulationOutput
     */
    modelId: string;
    /**
     * 
     * @type {string}
     * @memberof SimulationOutput
     */
    modelName: string;
    /**
     * 
     * @type {string}
     * @memberof SimulationOutput
     */
    scenarioId: string;
    /**
     * 
     * @type {string}
     * @memberof SimulationOutput
     */
    status: string;
    /**
     * 
     * @type {SimulationStatusDetail}
     * @memberof SimulationOutput
     */
    statusDetail: SimulationStatusDetail;
    /**
     * 
     * @type {number}
     * @memberof SimulationOutput
     */
    startDate: number;
}


/**
 * Check if a given object implements the SimulationOutput interface.
 */
export function instanceOfSimulationOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "simulationId" in value;
    isInstance = isInstance && "facilityId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "modelId" in value;
    isInstance = isInstance && "modelName" in value;
    isInstance = isInstance && "scenarioId" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "statusDetail" in value;
    isInstance = isInstance && "startDate" in value;

    return isInstance;
}

export function SimulationOutputFromJSON(json: any): SimulationOutput {
    return SimulationOutputFromJSONTyped(json, false);
}

export function SimulationOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimulationOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'simulationId': json['simulationId'],
        'facilityId': json['facilityId'],
        'name': json['name'],
        'modelId': json['modelId'],
        'modelName': json['modelName'],
        'scenarioId': json['scenarioId'],
        'status': json['status'],
        'statusDetail': SimulationStatusDetailFromJSON(json['statusDetail']),
        'startDate': json['startDate'],
    };
}

export function SimulationOutputToJSON(value?: SimulationOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'simulationId': value.simulationId,
        'facilityId': value.facilityId,
        'name': value.name,
        'modelId': value.modelId,
        'modelName': value.modelName,
        'scenarioId': value.scenarioId,
        'status': value.status,
        'statusDetail': SimulationStatusDetailToJSON(value.statusDetail),
        'startDate': value.startDate,
    };
}

