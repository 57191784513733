import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import { useConfig } from "./ConfigProvider";

export const RouteGuard: React.FC<{
  element: JSX.Element;
  adminLock?: boolean;
}> = ({ element, adminLock }) => {
  const config = useConfig();
  const authInfo = useAuth();
  const isAuthenticated = authInfo.isAuthenticated;
  const isAdmin = authInfo.isAdmin;

  if (!authInfo.authChecked) {
    return null;
  }

  if (isAuthenticated) {
    if (adminLock) {
      if (isAdmin) {
        return element;
      } else {
        return <Navigate to="/" replace={true} />;
      }
    } else {
      return element;
    }
  } else {
    window.location.assign(config.data?.authUrl);
    return null; // Unreachable, but just to make Typescript happy
  }
};
