import { Container, Header, SpaceBetween } from "@cloudscape-design/components";
import { PageWrapper } from "../../../layout/PageWrapper";
import { NetworkDashboards } from "../../Dashboard/components/NetworkDashboards";
import FacilityMap from "../../Map/components/Map";

const HomeView = (): JSX.Element => {
  return (
    <PageWrapper
      title="Facility Hub Home"
      description="View global facilities status map, network dashboards"
    >
      <SpaceBetween direction="vertical" size="l">
        <Container>
          <FacilityMap />
        </Container>
        <Container header={<Header>Network Level Dashboards</Header>}>
          <NetworkDashboards />
        </Container>
      </SpaceBetween>
    </PageWrapper>
  );
};

export default HomeView;
