import { AppLayout } from "@cloudscape-design/components";
import { Outlet } from "react-router-dom";
import { Appbar } from "./Appbar";
import { NavDrawer } from "./NavDrawer";

function DefaultLayout(): JSX.Element {
  return (
    <>
      <Appbar />
      <AppLayout
        stickyNotifications
        toolsHide
        content={<Outlet />}
        navigation={<NavDrawer />}
      />
    </>
  );
}
export default DefaultLayout;
