import {
  Container,
  SpaceBetween,
  Spinner,
  Tabs,
} from "@cloudscape-design/components";
import {
  FacilityFieldsComplete,
  useGetFacility,
} from "api-typescript-react-query-hooks";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../app/components/AuthProvider";
import { PageWrapper } from "../../../layout/PageWrapper";
import { strToTitleCase } from "../../../utils";
import { FacilityAlert } from "../../Alert/components/FacilityAlert";
import { FacilityDashboard } from "../../Dashboard/components/FacilityDashboard";
import NotFoundView from "../../NotFound/views/NotFoundView";
import { FacilitySimulations } from "../../Simulation/components/FacilitySimulations";
import { FacilityAdmin } from "../components/FacilityAdmin";
import { FacilityDetails } from "../components/FacilityDetails";

const FacilityView = (): JSX.Element => {
  const [facility, setFacility] = useState<FacilityFieldsComplete>();
  const { facilityId } = useParams();

  if (!facilityId) return <NotFoundView />;

  const facilityDetails = useGetFacility({ facilityId }).data?.facility;

  const auth = useAuth();
  useEffect(() => {
    if (facilityDetails) {
      setFacility(facilityDetails);
    }
  }, [facilityDetails]);

  if (!facility) return <Spinner />;

  const FacilityTabs = [
    {
      label: "Facility Info",
      id: "Info",
      content: <FacilityDetails facility={facility} />,
    },
    {
      label: "Dashboards",
      id: "Dashboards",
      content: <FacilityDashboard facility={facility} />,
    },
    {
      label: "Alerts",
      id: "Alerts",
      content: <FacilityAlert facility={facility} />,
    },
    {
      label: "Simulations",
      id: "Simulations",
      content: <FacilitySimulations facility={facility} />,
    },
  ];

  const AdminTabs = [
    {
      label: "Admin Actions",
      id: "Admin",
      content: <FacilityAdmin facility={facility} />,
    },
  ];

  const DisplayTabs = () => {
    if (auth.isAdmin) {
      return FacilityTabs.concat(AdminTabs);
    } else {
      return FacilityTabs;
    }
  };

  const title = strToTitleCase(facility.name);
  return (
    <PageWrapper
      title={title}
      description={`View ${title} facility details, profile, dashboards, BIM files and alerts`}
      objectId={facilityId}
      objectType="FacilitySite"
      bookmarkName={facility.name}
    >
      <SpaceBetween size="xl">
        <Container disableContentPaddings>
          <Tabs tabs={DisplayTabs()} />
        </Container>
      </SpaceBetween>
    </PageWrapper>
  );
};

export default FacilityView;
