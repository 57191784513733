/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddDashboardRequestContent,
  AddDashboardResponseContent,
  AddFacilityRequestContent,
  AddFacilityResponseContent,
  AddTagRequestContent,
  AddTagResponseContent,
  AddUserBookmarkRequestContent,
  AddUserFacilityRequestContent,
  AlertSourceType,
  BadRequestErrorResponseContent,
  BookmarkObjectType,
  CreateSchematicRequestContent,
  CreateSchematicResponseContent,
  DashboardReportsType,
  FacilityStatusEnum,
  GenerateDashboardEmbedUrlRequestContent,
  GenerateDashboardEmbedUrlResponseContent,
  GetDashboardResponseContent,
  GetDashboardTagsResponseContent,
  GetFacilityResponseContent,
  GetFacilityStatusResponseContent,
  GetFacilityTagsResponseContent,
  GetSimulationCredentialsResponseContent,
  GetSimulationResponseContent,
  GetSimulationResultsResponseContent,
  GetSimulationStatusResponseContent,
  InternalFailureErrorResponseContent,
  ListAlertsResponseContent,
  ListFacilitiesResponseContent,
  ListFacilitiesStatusResponseContent,
  ListFacilityDashboardsResponseContent,
  ListFacilityUsersResponseContent,
  ListNetworkLevelDashboardsResponseContent,
  ListSchematicsResponseContent,
  ListSimulationModelsResponseContent,
  ListSimulationsResponseContent,
  ListTagsResponseContent,
  ListUserBookmarksResponseContent,
  ListUserFacilitiesResponseContent,
  NewSimulationRequestContent,
  NewSimulationResponseContent,
  NotAuthorizedErrorResponseContent,
  NotFoundErrorResponseContent,
  SearchResourcesResponseContent,
  SearchUsersResponseContent,
  UpdateDashboardRequestContent,
  UpdateFacilityRequestContent,
  UpdateSchematicRequestContent,
  UploadPictureResponseContent,
} from '../models';
import {
    AddDashboardRequestContentFromJSON,
    AddDashboardRequestContentToJSON,
    AddDashboardResponseContentFromJSON,
    AddDashboardResponseContentToJSON,
    AddFacilityRequestContentFromJSON,
    AddFacilityRequestContentToJSON,
    AddFacilityResponseContentFromJSON,
    AddFacilityResponseContentToJSON,
    AddTagRequestContentFromJSON,
    AddTagRequestContentToJSON,
    AddTagResponseContentFromJSON,
    AddTagResponseContentToJSON,
    AddUserBookmarkRequestContentFromJSON,
    AddUserBookmarkRequestContentToJSON,
    AddUserFacilityRequestContentFromJSON,
    AddUserFacilityRequestContentToJSON,
    AlertSourceTypeFromJSON,
    AlertSourceTypeToJSON,
    BadRequestErrorResponseContentFromJSON,
    BadRequestErrorResponseContentToJSON,
    BookmarkObjectTypeFromJSON,
    BookmarkObjectTypeToJSON,
    CreateSchematicRequestContentFromJSON,
    CreateSchematicRequestContentToJSON,
    CreateSchematicResponseContentFromJSON,
    CreateSchematicResponseContentToJSON,
    DashboardReportsTypeFromJSON,
    DashboardReportsTypeToJSON,
    FacilityStatusEnumFromJSON,
    FacilityStatusEnumToJSON,
    GenerateDashboardEmbedUrlRequestContentFromJSON,
    GenerateDashboardEmbedUrlRequestContentToJSON,
    GenerateDashboardEmbedUrlResponseContentFromJSON,
    GenerateDashboardEmbedUrlResponseContentToJSON,
    GetDashboardResponseContentFromJSON,
    GetDashboardResponseContentToJSON,
    GetDashboardTagsResponseContentFromJSON,
    GetDashboardTagsResponseContentToJSON,
    GetFacilityResponseContentFromJSON,
    GetFacilityResponseContentToJSON,
    GetFacilityStatusResponseContentFromJSON,
    GetFacilityStatusResponseContentToJSON,
    GetFacilityTagsResponseContentFromJSON,
    GetFacilityTagsResponseContentToJSON,
    GetSimulationCredentialsResponseContentFromJSON,
    GetSimulationCredentialsResponseContentToJSON,
    GetSimulationResponseContentFromJSON,
    GetSimulationResponseContentToJSON,
    GetSimulationResultsResponseContentFromJSON,
    GetSimulationResultsResponseContentToJSON,
    GetSimulationStatusResponseContentFromJSON,
    GetSimulationStatusResponseContentToJSON,
    InternalFailureErrorResponseContentFromJSON,
    InternalFailureErrorResponseContentToJSON,
    ListAlertsResponseContentFromJSON,
    ListAlertsResponseContentToJSON,
    ListFacilitiesResponseContentFromJSON,
    ListFacilitiesResponseContentToJSON,
    ListFacilitiesStatusResponseContentFromJSON,
    ListFacilitiesStatusResponseContentToJSON,
    ListFacilityDashboardsResponseContentFromJSON,
    ListFacilityDashboardsResponseContentToJSON,
    ListFacilityUsersResponseContentFromJSON,
    ListFacilityUsersResponseContentToJSON,
    ListNetworkLevelDashboardsResponseContentFromJSON,
    ListNetworkLevelDashboardsResponseContentToJSON,
    ListSchematicsResponseContentFromJSON,
    ListSchematicsResponseContentToJSON,
    ListSimulationModelsResponseContentFromJSON,
    ListSimulationModelsResponseContentToJSON,
    ListSimulationsResponseContentFromJSON,
    ListSimulationsResponseContentToJSON,
    ListTagsResponseContentFromJSON,
    ListTagsResponseContentToJSON,
    ListUserBookmarksResponseContentFromJSON,
    ListUserBookmarksResponseContentToJSON,
    ListUserFacilitiesResponseContentFromJSON,
    ListUserFacilitiesResponseContentToJSON,
    NewSimulationRequestContentFromJSON,
    NewSimulationRequestContentToJSON,
    NewSimulationResponseContentFromJSON,
    NewSimulationResponseContentToJSON,
    NotAuthorizedErrorResponseContentFromJSON,
    NotAuthorizedErrorResponseContentToJSON,
    NotFoundErrorResponseContentFromJSON,
    NotFoundErrorResponseContentToJSON,
    SearchResourcesResponseContentFromJSON,
    SearchResourcesResponseContentToJSON,
    SearchUsersResponseContentFromJSON,
    SearchUsersResponseContentToJSON,
    UpdateDashboardRequestContentFromJSON,
    UpdateDashboardRequestContentToJSON,
    UpdateFacilityRequestContentFromJSON,
    UpdateFacilityRequestContentToJSON,
    UpdateSchematicRequestContentFromJSON,
    UpdateSchematicRequestContentToJSON,
    UploadPictureResponseContentFromJSON,
    UploadPictureResponseContentToJSON,
} from '../models';

export interface AddDashboardRequest {
    addDashboardRequestContent?: AddDashboardRequestContent;
}

export interface AddFacilityRequest {
    addFacilityRequestContent?: AddFacilityRequestContent;
}

export interface AddTagRequest {
    addTagRequestContent?: AddTagRequestContent;
}

export interface AddUserBookmarkRequest {
    addUserBookmarkRequestContent: AddUserBookmarkRequestContent;
}

export interface AddUserFacilityRequest {
    userName: string;
    addUserFacilityRequestContent: AddUserFacilityRequestContent;
}

export interface AssociateDashboardTagRequest {
    dashboardId: string;
    tagId: string;
}

export interface AssociateFacilityTagRequest {
    facilityId: string;
    tagId: string;
}

export interface CreateSchematicRequest {
    facilityId: string;
    createSchematicRequestContent: CreateSchematicRequestContent;
}

export interface DeleteDashboardRequest {
    dashboardId: string;
}

export interface DeleteFacilityRequest {
    facilityId: string;
}

export interface DeletePictureRequest {
    facilityId: string;
    key: string;
}

export interface DeleteSchematicRequest {
    facilityId: string;
    schematicId: string;
}

export interface DeleteTagRequest {
    tagId: string;
}

export interface DeleteUserBookmarkRequest {
    objectId: string;
    objectType: BookmarkObjectType;
}

export interface DeleteUserFacilityRequest {
    userName: string;
    facilityId: string;
}

export interface DisassociateDashboardTagRequest {
    dashboardId: string;
    tagId: string;
}

export interface DisassociateFacilityTagRequest {
    facilityId: string;
    tagId: string;
}

export interface GenerateDashboardEmbedUrlRequest {
    generateDashboardEmbedUrlRequestContent: GenerateDashboardEmbedUrlRequestContent;
}

export interface GetDashboardRequest {
    dashboardId: string;
}

export interface GetDashboardTagsRequest {
    dashboardId: string;
}

export interface GetFacilityRequest {
    facilityId: string;
}

export interface GetFacilityStatusRequest {
    facilityId: string;
}

export interface GetFacilityTagsRequest {
    facilityId: string;
}

export interface GetSimulationRequest {
    facilityId: string;
    simulationId: string;
}

export interface GetSimulationResultsRequest {
    facilityId: string;
    simulationId: string;
    modelId: string;
}

export interface GetSimulationStatusRequest {
    facilityId: string;
    simulationId: string;
}

export interface ListAlertsRequest {
    sourceType?: AlertSourceType;
    reportsType?: DashboardReportsType;
    facilityId?: string;
    token?: string;
    size?: number;
}

export interface ListFacilitiesRequest {
    pageToken?: string;
    pageSize?: number;
}

export interface ListFacilityDashboardsRequest {
    facilityId: string;
    reportsType?: DashboardReportsType;
    pageToken?: string;
    pageSize?: number;
}

export interface ListFacilityUsersRequest {
    facilityId: string;
}

export interface ListNetworkLevelDashboardsRequest {
    reportsType?: DashboardReportsType;
    pageToken?: string;
    pageSize?: number;
}

export interface ListSchematicsRequest {
    facilityId: string;
    token?: string;
    size?: number;
}

export interface ListSimulationModelsRequest {
    pageToken?: string;
    pageSize?: number;
}

export interface ListSimulationsRequest {
    facilityId: string;
    pageToken?: string;
    pageSize?: number;
}

export interface ListUserFacilitiesRequest {
    userName: string;
}

export interface MapFacilityToDashboardRequest {
    dashboardId: string;
    facilityId: string;
}

export interface NewSimulationRequest {
    facilityId: string;
    newSimulationRequestContent: NewSimulationRequestContent;
}

export interface SearchResourcesRequest {
    text?: string;
    tags?: Array<string>;
    pageToken?: string;
    pageSize?: number;
    status?: FacilityStatusEnum;
}

export interface SearchUsersRequest {
    text?: string;
    pageToken?: string;
    pageSize?: number;
}

export interface UpdateDashboardRequest {
    dashboardId: string;
    updateDashboardRequestContent: UpdateDashboardRequestContent;
}

export interface UpdateFacilityRequest {
    facilityId: string;
    updateFacilityRequestContent: UpdateFacilityRequestContent;
}

export interface UpdateSchematicRequest {
    facilityId: string;
    schematicId: string;
    updateSchematicRequestContent: UpdateSchematicRequestContent;
}

export interface UploadPictureRequest {
    facilityId: string;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async addDashboardRaw(requestParameters: AddDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AddDashboardResponseContent>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/dashboards`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddDashboardRequestContentToJSON(requestParameters.addDashboardRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddDashboardResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async addDashboard(requestParameters: AddDashboardRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AddDashboardResponseContent> {
        const response = await this.addDashboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async addFacilityRaw(requestParameters: AddFacilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AddFacilityResponseContent>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/facilities`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddFacilityRequestContentToJSON(requestParameters.addFacilityRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddFacilityResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async addFacility(requestParameters: AddFacilityRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AddFacilityResponseContent> {
        const response = await this.addFacilityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async addTagRaw(requestParameters: AddTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AddTagResponseContent>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tags`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddTagRequestContentToJSON(requestParameters.addTagRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddTagResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async addTag(requestParameters: AddTagRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AddTagResponseContent> {
        const response = await this.addTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async addUserBookmarkRaw(requestParameters: AddUserBookmarkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.addUserBookmarkRequestContent === null || requestParameters.addUserBookmarkRequestContent === undefined) {
            throw new runtime.RequiredError('addUserBookmarkRequestContent','Required parameter requestParameters.addUserBookmarkRequestContent was null or undefined when calling addUserBookmark.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/bookmarks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddUserBookmarkRequestContentToJSON(requestParameters.addUserBookmarkRequestContent),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async addUserBookmark(requestParameters: AddUserBookmarkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addUserBookmarkRaw(requestParameters, initOverrides);
    }

    /**
     */
    async addUserFacilityRaw(requestParameters: AddUserFacilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userName === null || requestParameters.userName === undefined) {
            throw new runtime.RequiredError('userName','Required parameter requestParameters.userName was null or undefined when calling addUserFacility.');
        }

        if (requestParameters.addUserFacilityRequestContent === null || requestParameters.addUserFacilityRequestContent === undefined) {
            throw new runtime.RequiredError('addUserFacilityRequestContent','Required parameter requestParameters.addUserFacilityRequestContent was null or undefined when calling addUserFacility.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/{userName}/facilities`.replace(`{${"userName"}}`, encodeURIComponent(String(requestParameters.userName))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddUserFacilityRequestContentToJSON(requestParameters.addUserFacilityRequestContent),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async addUserFacility(requestParameters: AddUserFacilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addUserFacilityRaw(requestParameters, initOverrides);
    }

    /**
     */
    async associateDashboardTagRaw(requestParameters: AssociateDashboardTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.dashboardId === null || requestParameters.dashboardId === undefined) {
            throw new runtime.RequiredError('dashboardId','Required parameter requestParameters.dashboardId was null or undefined when calling associateDashboardTag.');
        }

        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId','Required parameter requestParameters.tagId was null or undefined when calling associateDashboardTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/dashboards/{dashboardId}/tags/{tagId}`.replace(`{${"dashboardId"}}`, encodeURIComponent(String(requestParameters.dashboardId))).replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async associateDashboardTag(requestParameters: AssociateDashboardTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.associateDashboardTagRaw(requestParameters, initOverrides);
    }

    /**
     */
    async associateFacilityTagRaw(requestParameters: AssociateFacilityTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling associateFacilityTag.');
        }

        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId','Required parameter requestParameters.tagId was null or undefined when calling associateFacilityTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/facilities/{facilityId}/tags/{tagId}`.replace(`{${"facilityId"}}`, encodeURIComponent(String(requestParameters.facilityId))).replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async associateFacilityTag(requestParameters: AssociateFacilityTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.associateFacilityTagRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createSchematicRaw(requestParameters: CreateSchematicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateSchematicResponseContent>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling createSchematic.');
        }

        if (requestParameters.createSchematicRequestContent === null || requestParameters.createSchematicRequestContent === undefined) {
            throw new runtime.RequiredError('createSchematicRequestContent','Required parameter requestParameters.createSchematicRequestContent was null or undefined when calling createSchematic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/facilities/{facilityId}/schematics`.replace(`{${"facilityId"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSchematicRequestContentToJSON(requestParameters.createSchematicRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateSchematicResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async createSchematic(requestParameters: CreateSchematicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateSchematicResponseContent> {
        const response = await this.createSchematicRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteDashboardRaw(requestParameters: DeleteDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.dashboardId === null || requestParameters.dashboardId === undefined) {
            throw new runtime.RequiredError('dashboardId','Required parameter requestParameters.dashboardId was null or undefined when calling deleteDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/dashboards/{dashboardId}`.replace(`{${"dashboardId"}}`, encodeURIComponent(String(requestParameters.dashboardId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteDashboard(requestParameters: DeleteDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDashboardRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteFacilityRaw(requestParameters: DeleteFacilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling deleteFacility.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/facilities/{facilityId}`.replace(`{${"facilityId"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteFacility(requestParameters: DeleteFacilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteFacilityRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deletePictureRaw(requestParameters: DeletePictureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling deletePicture.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling deletePicture.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/facilities/{facilityId}/pictures/{key}`.replace(`{${"facilityId"}}`, encodeURIComponent(String(requestParameters.facilityId))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePicture(requestParameters: DeletePictureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePictureRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteSchematicRaw(requestParameters: DeleteSchematicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling deleteSchematic.');
        }

        if (requestParameters.schematicId === null || requestParameters.schematicId === undefined) {
            throw new runtime.RequiredError('schematicId','Required parameter requestParameters.schematicId was null or undefined when calling deleteSchematic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/facilities/{facilityId}/schematics/{schematicId}`.replace(`{${"facilityId"}}`, encodeURIComponent(String(requestParameters.facilityId))).replace(`{${"schematicId"}}`, encodeURIComponent(String(requestParameters.schematicId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteSchematic(requestParameters: DeleteSchematicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSchematicRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteTagRaw(requestParameters: DeleteTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId','Required parameter requestParameters.tagId was null or undefined when calling deleteTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tags/{tagId}`.replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteTag(requestParameters: DeleteTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTagRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteUserBookmarkRaw(requestParameters: DeleteUserBookmarkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.objectId === null || requestParameters.objectId === undefined) {
            throw new runtime.RequiredError('objectId','Required parameter requestParameters.objectId was null or undefined when calling deleteUserBookmark.');
        }

        if (requestParameters.objectType === null || requestParameters.objectType === undefined) {
            throw new runtime.RequiredError('objectType','Required parameter requestParameters.objectType was null or undefined when calling deleteUserBookmark.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/bookmarks/{objectType}/{objectId}`.replace(`{${"objectId"}}`, encodeURIComponent(String(requestParameters.objectId))).replace(`{${"objectType"}}`, encodeURIComponent(String(requestParameters.objectType))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUserBookmark(requestParameters: DeleteUserBookmarkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserBookmarkRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteUserFacilityRaw(requestParameters: DeleteUserFacilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userName === null || requestParameters.userName === undefined) {
            throw new runtime.RequiredError('userName','Required parameter requestParameters.userName was null or undefined when calling deleteUserFacility.');
        }

        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling deleteUserFacility.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/{userName}/facilities/{facilityId}`.replace(`{${"userName"}}`, encodeURIComponent(String(requestParameters.userName))).replace(`{${"facilityId"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUserFacility(requestParameters: DeleteUserFacilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserFacilityRaw(requestParameters, initOverrides);
    }

    /**
     */
    async disassociateDashboardTagRaw(requestParameters: DisassociateDashboardTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.dashboardId === null || requestParameters.dashboardId === undefined) {
            throw new runtime.RequiredError('dashboardId','Required parameter requestParameters.dashboardId was null or undefined when calling disassociateDashboardTag.');
        }

        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId','Required parameter requestParameters.tagId was null or undefined when calling disassociateDashboardTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/dashboards/{dashboardId}/tags/{tagId}`.replace(`{${"dashboardId"}}`, encodeURIComponent(String(requestParameters.dashboardId))).replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async disassociateDashboardTag(requestParameters: DisassociateDashboardTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.disassociateDashboardTagRaw(requestParameters, initOverrides);
    }

    /**
     */
    async disassociateFacilityTagRaw(requestParameters: DisassociateFacilityTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling disassociateFacilityTag.');
        }

        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId','Required parameter requestParameters.tagId was null or undefined when calling disassociateFacilityTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/facilities/{facilityId}/tags/{tagId}`.replace(`{${"facilityId"}}`, encodeURIComponent(String(requestParameters.facilityId))).replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async disassociateFacilityTag(requestParameters: DisassociateFacilityTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.disassociateFacilityTagRaw(requestParameters, initOverrides);
    }

    /**
     */
    async generateDashboardEmbedUrlRaw(requestParameters: GenerateDashboardEmbedUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenerateDashboardEmbedUrlResponseContent>> {
        if (requestParameters.generateDashboardEmbedUrlRequestContent === null || requestParameters.generateDashboardEmbedUrlRequestContent === undefined) {
            throw new runtime.RequiredError('generateDashboardEmbedUrlRequestContent','Required parameter requestParameters.generateDashboardEmbedUrlRequestContent was null or undefined when calling generateDashboardEmbedUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/dashboard/generate_embed_url`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GenerateDashboardEmbedUrlRequestContentToJSON(requestParameters.generateDashboardEmbedUrlRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GenerateDashboardEmbedUrlResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async generateDashboardEmbedUrl(requestParameters: GenerateDashboardEmbedUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenerateDashboardEmbedUrlResponseContent> {
        const response = await this.generateDashboardEmbedUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getDashboardRaw(requestParameters: GetDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDashboardResponseContent>> {
        if (requestParameters.dashboardId === null || requestParameters.dashboardId === undefined) {
            throw new runtime.RequiredError('dashboardId','Required parameter requestParameters.dashboardId was null or undefined when calling getDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/dashboards/{dashboardId}`.replace(`{${"dashboardId"}}`, encodeURIComponent(String(requestParameters.dashboardId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDashboardResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async getDashboard(requestParameters: GetDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDashboardResponseContent> {
        const response = await this.getDashboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getDashboardTagsRaw(requestParameters: GetDashboardTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDashboardTagsResponseContent>> {
        if (requestParameters.dashboardId === null || requestParameters.dashboardId === undefined) {
            throw new runtime.RequiredError('dashboardId','Required parameter requestParameters.dashboardId was null or undefined when calling getDashboardTags.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/dashboards/{dashboardId}/tags`.replace(`{${"dashboardId"}}`, encodeURIComponent(String(requestParameters.dashboardId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDashboardTagsResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async getDashboardTags(requestParameters: GetDashboardTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDashboardTagsResponseContent> {
        const response = await this.getDashboardTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFacilityRaw(requestParameters: GetFacilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetFacilityResponseContent>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling getFacility.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/facilities/{facilityId}`.replace(`{${"facilityId"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFacilityResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async getFacility(requestParameters: GetFacilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetFacilityResponseContent> {
        const response = await this.getFacilityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFacilityStatusRaw(requestParameters: GetFacilityStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetFacilityStatusResponseContent>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling getFacilityStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/facilities/{facilityId}/status`.replace(`{${"facilityId"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFacilityStatusResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async getFacilityStatus(requestParameters: GetFacilityStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetFacilityStatusResponseContent> {
        const response = await this.getFacilityStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFacilityTagsRaw(requestParameters: GetFacilityTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetFacilityTagsResponseContent>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling getFacilityTags.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/facilities/{facilityId}/tags`.replace(`{${"facilityId"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFacilityTagsResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async getFacilityTags(requestParameters: GetFacilityTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetFacilityTagsResponseContent> {
        const response = await this.getFacilityTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSimulationRaw(requestParameters: GetSimulationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSimulationResponseContent>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling getSimulation.');
        }

        if (requestParameters.simulationId === null || requestParameters.simulationId === undefined) {
            throw new runtime.RequiredError('simulationId','Required parameter requestParameters.simulationId was null or undefined when calling getSimulation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/facilities/{facilityId}/simulations/{simulationId}`.replace(`{${"facilityId"}}`, encodeURIComponent(String(requestParameters.facilityId))).replace(`{${"simulationId"}}`, encodeURIComponent(String(requestParameters.simulationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSimulationResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async getSimulation(requestParameters: GetSimulationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSimulationResponseContent> {
        const response = await this.getSimulationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSimulationCredentialsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSimulationCredentialsResponseContent>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/simulations/credentials`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSimulationCredentialsResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async getSimulationCredentials(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSimulationCredentialsResponseContent> {
        const response = await this.getSimulationCredentialsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getSimulationResultsRaw(requestParameters: GetSimulationResultsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSimulationResultsResponseContent>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling getSimulationResults.');
        }

        if (requestParameters.simulationId === null || requestParameters.simulationId === undefined) {
            throw new runtime.RequiredError('simulationId','Required parameter requestParameters.simulationId was null or undefined when calling getSimulationResults.');
        }

        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling getSimulationResults.');
        }

        const queryParameters: any = {};

        if (requestParameters.modelId !== undefined) {
            queryParameters['modelId'] = requestParameters.modelId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/facilities/{facilityId}/simulations/{simulationId}/output`.replace(`{${"facilityId"}}`, encodeURIComponent(String(requestParameters.facilityId))).replace(`{${"simulationId"}}`, encodeURIComponent(String(requestParameters.simulationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSimulationResultsResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async getSimulationResults(requestParameters: GetSimulationResultsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSimulationResultsResponseContent> {
        const response = await this.getSimulationResultsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSimulationStatusRaw(requestParameters: GetSimulationStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSimulationStatusResponseContent>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling getSimulationStatus.');
        }

        if (requestParameters.simulationId === null || requestParameters.simulationId === undefined) {
            throw new runtime.RequiredError('simulationId','Required parameter requestParameters.simulationId was null or undefined when calling getSimulationStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/facilities/{facilityId}/simulations/{simulationId}/status`.replace(`{${"facilityId"}}`, encodeURIComponent(String(requestParameters.facilityId))).replace(`{${"simulationId"}}`, encodeURIComponent(String(requestParameters.simulationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSimulationStatusResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async getSimulationStatus(requestParameters: GetSimulationStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSimulationStatusResponseContent> {
        const response = await this.getSimulationStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listAlertsRaw(requestParameters: ListAlertsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAlertsResponseContent>> {
        const queryParameters: any = {};

        if (requestParameters.sourceType !== undefined) {
            queryParameters['sourceType'] = requestParameters.sourceType;
        }

        if (requestParameters.reportsType !== undefined) {
            queryParameters['reportsType'] = requestParameters.reportsType;
        }

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facilityId'] = requestParameters.facilityId;
        }

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alerts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAlertsResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async listAlerts(requestParameters: ListAlertsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAlertsResponseContent> {
        const response = await this.listAlertsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listFacilitiesRaw(requestParameters: ListFacilitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListFacilitiesResponseContent>> {
        const queryParameters: any = {};

        if (requestParameters.pageToken !== undefined) {
            queryParameters['pageToken'] = requestParameters.pageToken;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/facilities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListFacilitiesResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async listFacilities(requestParameters: ListFacilitiesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListFacilitiesResponseContent> {
        const response = await this.listFacilitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listFacilitiesStatusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListFacilitiesStatusResponseContent>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/status/facilities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListFacilitiesStatusResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async listFacilitiesStatus(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListFacilitiesStatusResponseContent> {
        const response = await this.listFacilitiesStatusRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async listFacilityDashboardsRaw(requestParameters: ListFacilityDashboardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListFacilityDashboardsResponseContent>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling listFacilityDashboards.');
        }

        const queryParameters: any = {};

        if (requestParameters.reportsType !== undefined) {
            queryParameters['reportsType'] = requestParameters.reportsType;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['pageToken'] = requestParameters.pageToken;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/facilities/{facilityId}/dashboards`.replace(`{${"facilityId"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListFacilityDashboardsResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async listFacilityDashboards(requestParameters: ListFacilityDashboardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListFacilityDashboardsResponseContent> {
        const response = await this.listFacilityDashboardsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listFacilityUsersRaw(requestParameters: ListFacilityUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListFacilityUsersResponseContent>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling listFacilityUsers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/facilities/{facilityId}/users`.replace(`{${"facilityId"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListFacilityUsersResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async listFacilityUsers(requestParameters: ListFacilityUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListFacilityUsersResponseContent> {
        const response = await this.listFacilityUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listNetworkLevelDashboardsRaw(requestParameters: ListNetworkLevelDashboardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListNetworkLevelDashboardsResponseContent>> {
        const queryParameters: any = {};

        if (requestParameters.reportsType !== undefined) {
            queryParameters['reportsType'] = requestParameters.reportsType;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['pageToken'] = requestParameters.pageToken;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/dashboards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListNetworkLevelDashboardsResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async listNetworkLevelDashboards(requestParameters: ListNetworkLevelDashboardsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListNetworkLevelDashboardsResponseContent> {
        const response = await this.listNetworkLevelDashboardsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listSchematicsRaw(requestParameters: ListSchematicsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListSchematicsResponseContent>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling listSchematics.');
        }

        const queryParameters: any = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/facilities/{facilityId}/schematics`.replace(`{${"facilityId"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListSchematicsResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async listSchematics(requestParameters: ListSchematicsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListSchematicsResponseContent> {
        const response = await this.listSchematicsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listSimulationModelsRaw(requestParameters: ListSimulationModelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListSimulationModelsResponseContent>> {
        const queryParameters: any = {};

        if (requestParameters.pageToken !== undefined) {
            queryParameters['pageToken'] = requestParameters.pageToken;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/simulation/models`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListSimulationModelsResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async listSimulationModels(requestParameters: ListSimulationModelsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListSimulationModelsResponseContent> {
        const response = await this.listSimulationModelsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listSimulationsRaw(requestParameters: ListSimulationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListSimulationsResponseContent>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling listSimulations.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageToken !== undefined) {
            queryParameters['pageToken'] = requestParameters.pageToken;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/facilities/{facilityId}/simulations`.replace(`{${"facilityId"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListSimulationsResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async listSimulations(requestParameters: ListSimulationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListSimulationsResponseContent> {
        const response = await this.listSimulationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listTagsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListTagsResponseContent>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListTagsResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async listTags(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListTagsResponseContent> {
        const response = await this.listTagsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async listUserBookmarksRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListUserBookmarksResponseContent>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/bookmarks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListUserBookmarksResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async listUserBookmarks(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListUserBookmarksResponseContent> {
        const response = await this.listUserBookmarksRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async listUserFacilitiesRaw(requestParameters: ListUserFacilitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListUserFacilitiesResponseContent>> {
        if (requestParameters.userName === null || requestParameters.userName === undefined) {
            throw new runtime.RequiredError('userName','Required parameter requestParameters.userName was null or undefined when calling listUserFacilities.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/{userName}/facilities`.replace(`{${"userName"}}`, encodeURIComponent(String(requestParameters.userName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListUserFacilitiesResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async listUserFacilities(requestParameters: ListUserFacilitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListUserFacilitiesResponseContent> {
        const response = await this.listUserFacilitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mapFacilityToDashboardRaw(requestParameters: MapFacilityToDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.dashboardId === null || requestParameters.dashboardId === undefined) {
            throw new runtime.RequiredError('dashboardId','Required parameter requestParameters.dashboardId was null or undefined when calling mapFacilityToDashboard.');
        }

        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling mapFacilityToDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/facilities/{facilityId}/dashboards/{dashboardId}`.replace(`{${"dashboardId"}}`, encodeURIComponent(String(requestParameters.dashboardId))).replace(`{${"facilityId"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async mapFacilityToDashboard(requestParameters: MapFacilityToDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.mapFacilityToDashboardRaw(requestParameters, initOverrides);
    }

    /**
     */
    async newSimulationRaw(requestParameters: NewSimulationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NewSimulationResponseContent>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling newSimulation.');
        }

        if (requestParameters.newSimulationRequestContent === null || requestParameters.newSimulationRequestContent === undefined) {
            throw new runtime.RequiredError('newSimulationRequestContent','Required parameter requestParameters.newSimulationRequestContent was null or undefined when calling newSimulation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/facility/{facilityId}/simulation/new`.replace(`{${"facilityId"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewSimulationRequestContentToJSON(requestParameters.newSimulationRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewSimulationResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async newSimulation(requestParameters: NewSimulationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NewSimulationResponseContent> {
        const response = await this.newSimulationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchResourcesRaw(requestParameters: SearchResourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchResourcesResponseContent>> {
        const queryParameters: any = {};

        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }

        if (requestParameters.tags) {
            queryParameters['tags'] = requestParameters.tags;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['pageToken'] = requestParameters.pageToken;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/search/resources`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchResourcesResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async searchResources(requestParameters: SearchResourcesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchResourcesResponseContent> {
        const response = await this.searchResourcesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchUsersRaw(requestParameters: SearchUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchUsersResponseContent>> {
        const queryParameters: any = {};

        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['pageToken'] = requestParameters.pageToken;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/search/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchUsersResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async searchUsers(requestParameters: SearchUsersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchUsersResponseContent> {
        const response = await this.searchUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateDashboardRaw(requestParameters: UpdateDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.dashboardId === null || requestParameters.dashboardId === undefined) {
            throw new runtime.RequiredError('dashboardId','Required parameter requestParameters.dashboardId was null or undefined when calling updateDashboard.');
        }

        if (requestParameters.updateDashboardRequestContent === null || requestParameters.updateDashboardRequestContent === undefined) {
            throw new runtime.RequiredError('updateDashboardRequestContent','Required parameter requestParameters.updateDashboardRequestContent was null or undefined when calling updateDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/dashboards/{dashboardId}`.replace(`{${"dashboardId"}}`, encodeURIComponent(String(requestParameters.dashboardId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDashboardRequestContentToJSON(requestParameters.updateDashboardRequestContent),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateDashboard(requestParameters: UpdateDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateDashboardRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateFacilityRaw(requestParameters: UpdateFacilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling updateFacility.');
        }

        if (requestParameters.updateFacilityRequestContent === null || requestParameters.updateFacilityRequestContent === undefined) {
            throw new runtime.RequiredError('updateFacilityRequestContent','Required parameter requestParameters.updateFacilityRequestContent was null or undefined when calling updateFacility.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/facilities/{facilityId}`.replace(`{${"facilityId"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateFacilityRequestContentToJSON(requestParameters.updateFacilityRequestContent),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateFacility(requestParameters: UpdateFacilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateFacilityRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateSchematicRaw(requestParameters: UpdateSchematicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling updateSchematic.');
        }

        if (requestParameters.schematicId === null || requestParameters.schematicId === undefined) {
            throw new runtime.RequiredError('schematicId','Required parameter requestParameters.schematicId was null or undefined when calling updateSchematic.');
        }

        if (requestParameters.updateSchematicRequestContent === null || requestParameters.updateSchematicRequestContent === undefined) {
            throw new runtime.RequiredError('updateSchematicRequestContent','Required parameter requestParameters.updateSchematicRequestContent was null or undefined when calling updateSchematic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/facilities/{facilityId}/schematics/{schematicId}`.replace(`{${"facilityId"}}`, encodeURIComponent(String(requestParameters.facilityId))).replace(`{${"schematicId"}}`, encodeURIComponent(String(requestParameters.schematicId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSchematicRequestContentToJSON(requestParameters.updateSchematicRequestContent),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateSchematic(requestParameters: UpdateSchematicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateSchematicRaw(requestParameters, initOverrides);
    }

    /**
     */
    async uploadPictureRaw(requestParameters: UploadPictureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadPictureResponseContent>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling uploadPicture.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/facilities/{facilityId}/pictures`.replace(`{${"facilityId"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadPictureResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async uploadPicture(requestParameters: UploadPictureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadPictureResponseContent> {
        const response = await this.uploadPictureRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
