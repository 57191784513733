/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SearchResultUser } from './SearchResultUser';
import {
    SearchResultUserFromJSON,
    SearchResultUserFromJSONTyped,
    SearchResultUserToJSON,
} from './SearchResultUser';

/**
 * 
 * @export
 * @interface SearchUsersResponseContent
 */
export interface SearchUsersResponseContent {
    /**
     * 
     * @type {string}
     * @memberof SearchUsersResponseContent
     */
    nextToken?: string;
    /**
     * 
     * @type {Array<SearchResultUser>}
     * @memberof SearchUsersResponseContent
     */
    page: Array<SearchResultUser>;
}


/**
 * Check if a given object implements the SearchUsersResponseContent interface.
 */
export function instanceOfSearchUsersResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "page" in value;

    return isInstance;
}

export function SearchUsersResponseContentFromJSON(json: any): SearchUsersResponseContent {
    return SearchUsersResponseContentFromJSONTyped(json, false);
}

export function SearchUsersResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchUsersResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nextToken': !exists(json, 'nextToken') ? undefined : json['nextToken'],
        'page': ((json['page'] as Array<any>).map(SearchResultUserFromJSON)),
    };
}

export function SearchUsersResponseContentToJSON(value?: SearchUsersResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nextToken': value.nextToken,
        'page': ((value.page as Array<any>).map(SearchResultUserToJSON)),
    };
}

