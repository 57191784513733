/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSimulationResultsResponseContent
 */
export interface GetSimulationResultsResponseContent {
    /**
     * 
     * @type {any}
     * @memberof GetSimulationResultsResponseContent
     */
    result?: any | null;
}


/**
 * Check if a given object implements the GetSimulationResultsResponseContent interface.
 */
export function instanceOfGetSimulationResultsResponseContent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetSimulationResultsResponseContentFromJSON(json: any): GetSimulationResultsResponseContent {
    return GetSimulationResultsResponseContentFromJSONTyped(json, false);
}

export function GetSimulationResultsResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSimulationResultsResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : json['result'],
    };
}

export function GetSimulationResultsResponseContentToJSON(value?: GetSimulationResultsResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': value.result,
    };
}

