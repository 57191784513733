import {
  Box,
  ColumnLayout,
  Container,
  Header,
  Icon,
  SpaceBetween,
  Spinner,
} from "@cloudscape-design/components";
import styled from "@emotion/styled";
import {
  DashboardOutput,
  GetDashboardTagsRequest,
  TagListItem,
  useGetDashboardTags,
} from "api-typescript-react-query-hooks";
import { useState } from "react";
import { DashboardModal } from "./DashboardModal";
import { strToTitleCase } from "../../../utils";

interface DashboardCardProps extends DashboardOutput {
  selected?: string;
  setSelectedDashboard?: (dashboardId: string) => void;
}

export const DashboardCard = (dashboard: DashboardCardProps): JSX.Element => {
  const [visible, setVisible] = useState(false);

  const params: GetDashboardTagsRequest = {
    dashboardId: dashboard.dashboardId,
  };
  const tags = useGetDashboardTags(params).data?.tags;

  if (!dashboard) {
    return <Spinner />;
  }

  return (
    <Container>
      <Header>
        <Title onClick={() => setVisible(true)}>
          <H3>{dashboard.title}</H3>
          <Icon name="expand" variant="subtle" />
        </Title>
      </Header>
      <SpaceBetween direction="vertical" size="l">
        <Box variant="p">{dashboard.description}</Box>
        <ColumnLayout columns={2}>
          <Description
            title={"Dashboard Type"}
            value={strToTitleCase(dashboard.dashboardReportsType)}
          />
          <Description title={"Tags"} tags={tags} />
        </ColumnLayout>
      </SpaceBetween>
      {visible && (
        <DashboardModal
          setVisible={setVisible}
          visible={visible}
          title={dashboard.title}
          embedUrl={dashboard.embedUrl}
        />
      )}
    </Container>
  );
};

interface DescriptionProps {
  title: string;
  value?: string;
  tags?: TagListItem[] | undefined;
}

// TODO Make these tags actual tags
const Description = ({ title, value, tags }: DescriptionProps) => {
  return (
    <Box>
      <Box variant="awsui-key-label">{title}</Box>
      {value ? (
        <div>{value}</div>
      ) : (
        <div>{tags?.map((tag) => strToTitleCase(tag.name)).join(", ")}</div>
      )}
    </Box>
  );
};

const Title = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const H3 = styled.h3`
  color: #2883d9;
  margin: 0 5px 0;
  text-decoration: underline;
`;
