import { jwtDecode, JwtPayload } from "jwt-decode";
import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  ReactNode,
} from "react";

type JwtCognitoPayload = JwtPayload & {
  email: string;
  "cognito:groups": Array<string>;
  "cognito:username": string;
  given_name: string;
  family_name: string;
  preferred_username: string;
};

interface AuthContextProps {
  token: string;
  userPoolId: string;
  isAdmin: boolean;
  username: string;
  fullName: string;
  email: string;
  group: string;
  isAuthenticated: boolean;
  authChecked: boolean;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [authChecked, setAuthChecked] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");
  const [userPoolId, setUserPoolId] = useState<string>("");
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [group, setGroup] = useState<string>("");
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const handleLoginRedirect = () => {
    const params = new URLSearchParams(window.location.hash.substring(1)); // Remove '#' before parsing
    let idToken = params.get("id_token");

    if (process.env.NODE_ENV == "development") {
      console.log("dev mode");
      setAuthChecked(true);
      setIsAdmin(true);
      setIsAuthenticated(true);
      setUsername("testUser");
      setFullName("Test User");
      setEmail("testuser@amazon.com");
      setGroup("FacilityHub-Administrators");
      return;
    }

    if (!idToken) {
      idToken = localStorage.getItem("id_token");
    }

    localStorage.setItem("id_token", idToken || "");

    if (idToken) {
      setToken(idToken);
      const decoded = jwtDecode<JwtCognitoPayload>(idToken);

      setUserPoolId(decoded.iss?.replace("https://", "") ?? ""); // TODO add validation to this so there is an error when no pool is provided

      const groups = decoded["cognito:groups"];
      const groupName = groups.at(0) || "";
      setGroup(groupName);

      if (groupName == "FacilityHub-Administrators") {
        setIsAdmin(true);
      }

      setUsername(decoded["cognito:username"]);
      setFullName(`${decoded.given_name} ${decoded.family_name}`);
      setEmail(decoded.email);

      const now = Date.now();
      const exp = (decoded.exp || 0) * 1000;
      if (exp > now) {
        setIsAuthenticated(true);
      }
    }
  };

  useEffect(() => {
    setAuthChecked(true);
    handleLoginRedirect();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        token,
        userPoolId,
        isAdmin,
        username,
        fullName,
        email,
        isAuthenticated,
        group,
        authChecked,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useConfig must be used within a ConfigProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
