import { LocationClient } from "@aws-sdk/client-location";
import {
  Autosuggest,
  FormField,
  Input,
  SpaceBetween,
} from "@cloudscape-design/components";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { Location } from "api-typescript-react-query-hooks";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useAuth } from "../../../app/components/AuthProvider";
import { useConfig } from "../../../app/components/ConfigProvider";
import { setError } from "../../../app/reducers/appSlice";
import { useAppDispatch } from "../../../app/store";
import {
  getLocationServicesClient,
  getPlaceCoordinates,
  searchPlaceByText,
} from "../../../utils/locationServices";
import { FacilityForm } from "../views/EditCreateFacility";

interface AddressInputProps {
  currentLocation?: Location;
}

export const AddressInput = (props: AddressInputProps) => {
  const { control, setValue, formState } = useFormContext<FacilityForm>();
  const [searchText, setSearchText] = useState<string>("");
  const [place, setPlace] = useState<OptionDefinition>();
  const [lsClient, setLsClient] = useState<LocationClient>();
  const [placeOptions, setPlaceOptions] = useState<OptionDefinition[]>([]);
  const [searchStatus, setSearchStatus] = useState<
    "finished" | "loading" | "error"
  >("finished");
  const dispatch = useAppDispatch();

  const config = useConfig();
  const region = config.data.region;
  const identityPoolId = config.data.identityPoolId;
  const locationServicesIndexName = config.data.locationServicesIndexName;

  const auth = useAuth();

  const formErrors = formState.errors;

  useEffect(() => {
    void (async () => {
      await getLocationServicesClient(
        region,
        auth.token,
        auth.username,
        identityPoolId,
        auth.userPoolId,
      )
        .then((client) => setLsClient(client))
        .catch((err) => dispatch(setError(err)));
    })();
  }, [identityPoolId]);

  useEffect(() => {
    void (async () => {
      setPlaceOptions([]);
      if (lsClient && searchText.length > 3) {
        setSearchStatus("loading");
        await searchPlaceByText(searchText, locationServicesIndexName, lsClient)
          .then((results) => {
            const options = results?.map((placeOption) => {
              return {
                label: placeOption.Text,
                value: placeOption.PlaceId,
              };
            });
            setPlaceOptions(options || []);
            setSearchStatus("finished");
          })
          .catch((err: Error) => {
            dispatch(setError(err.message));
            setSearchStatus("error");
          });
      }
    })();
  }, [searchText]);

  useEffect(() => {
    void (async () => {
      if (place) {
        await getPlaceCoordinates(
          place.value!,
          locationServicesIndexName,
          lsClient!,
        )
          .then((returnedPlace) => {
            const lon = returnedPlace?.Geometry?.Point?.at(0);
            const lat = returnedPlace?.Geometry?.Point?.at(1);
            if (!lat || !lon) {
              dispatch(setError("Could not get coordinates for this address"));
            } else {
              setValue("location.coordinates.longitude", lon);
              setValue("location.coordinates.latitude", lat);
            }
          })
          .catch((err) => dispatch(setError(err)));
      }
    })();
  }, [place]);

  return (
    <SpaceBetween size="m">
      <FormField
        errorText={formErrors.location?.address?.message}
        label="Address"
      >
        <Controller
          control={control}
          defaultValue={props.currentLocation?.address || ""}
          name="location.address"
          render={({ field }) => (
            <Autosuggest
              {...field}
              onChange={({ detail }) => {
                field.onChange(detail.value);
              }}
              onLoadItems={({ detail }) => {
                setSearchText(detail.filteringText);
              }}
              onSelect={({ detail }) => {
                setPlace(detail.selectedOption);
                field.onChange(detail.selectedOption!.label!);
              }}
              options={placeOptions}
              statusType={searchStatus}
              filteringType="none"
              placeholder="Enter an address to search"
              loadingText="Searching..."
              empty="No matches found"
              recoveryText="An error occurred search. Please reload the page and try again."
            />
          )}
        />
      </FormField>
      <SpaceBetween direction="horizontal" size="m">
        <FormField label="Longitude">
          <Controller
            control={control}
            name="location.coordinates.longitude"
            defaultValue={props.currentLocation?.coordinates.longitude}
            render={({ field }) => (
              // @ts-ignore
              <Input {...field} disabled />
            )}
          />
        </FormField>
        <FormField label="latitude">
          <Controller
            control={control}
            name="location.coordinates.latitude"
            defaultValue={props.currentLocation?.coordinates.latitude}
            render={({ field }) => (
              // @ts-ignore
              <Input {...field} disabled />
            )}
          />
        </FormField>
      </SpaceBetween>
    </SpaceBetween>
  );
};
