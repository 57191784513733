import {
  Button,
  Container,
  Header,
  Input,
  SpaceBetween,
} from "@cloudscape-design/components";
import { FacilityFields } from "api-typescript-react-query-hooks";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

interface StringArrayInputProps {
  facility: FacilityFields | undefined;
  fieldName: string;
  title: string;
}

export const StringArrayInput = (props: StringArrayInputProps) => {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: props.fieldName,
  });

  return (
    <Container header={<Header>{props.title}</Header>}>
      <SpaceBetween size="m">
        {fields.map((item, index) => (
          <SpaceBetween key={item.id} direction="horizontal" size="m">
            <Controller
              control={control}
              // @ts-ignore
              defaultValue={item.content || ""}
              name={`${props.fieldName}.${index}.content`}
              render={({ field }) => (
                // @ts-ignore
                <Input
                  {...field}
                  onChange={(e) => field.onChange(e.detail.value)}
                  inputMode="text"
                />
              )}
            />
            <Button
              formAction="none"
              variant="normal"
              onClick={() => remove(index)}
            >
              Remove
            </Button>
          </SpaceBetween>
        ))}
        <Button
          formAction="none"
          variant="normal"
          onClick={() => append({ content: "" })}
        >
          Add {props.title}
        </Button>
      </SpaceBetween>
    </Container>
  );
};
