/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimulationStatusDetail
 */
export interface SimulationStatusDetail {
    /**
     * 
     * @type {number}
     * @memberof SimulationStatusDetail
     */
    status?: number;
    /**
     * 
     * @type {string}
     * @memberof SimulationStatusDetail
     */
    message?: string;
    /**
     * 
     * @type {number}
     * @memberof SimulationStatusDetail
     */
    completedReplications?: number;
    /**
     * 
     * @type {number}
     * @memberof SimulationStatusDetail
     */
    totalReplications?: number;
    /**
     * 
     * @type {number}
     * @memberof SimulationStatusDetail
     */
    progressPercentage?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SimulationStatusDetail
     */
    errors?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SimulationStatusDetail
     */
    warnings?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SimulationStatusDetail
     */
    notifications?: Array<string>;
}


/**
 * Check if a given object implements the SimulationStatusDetail interface.
 */
export function instanceOfSimulationStatusDetail(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SimulationStatusDetailFromJSON(json: any): SimulationStatusDetail {
    return SimulationStatusDetailFromJSONTyped(json, false);
}

export function SimulationStatusDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimulationStatusDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'completedReplications': !exists(json, 'completedReplications') ? undefined : json['completedReplications'],
        'totalReplications': !exists(json, 'totalReplications') ? undefined : json['totalReplications'],
        'progressPercentage': !exists(json, 'progressPercentage') ? undefined : json['progressPercentage'],
        'errors': !exists(json, 'errors') ? undefined : json['errors'],
        'warnings': !exists(json, 'warnings') ? undefined : json['warnings'],
        'notifications': !exists(json, 'notifications') ? undefined : json['notifications'],
    };
}

export function SimulationStatusDetailToJSON(value?: SimulationStatusDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'message': value.message,
        'completedReplications': value.completedReplications,
        'totalReplications': value.totalReplications,
        'progressPercentage': value.progressPercentage,
        'errors': value.errors,
        'warnings': value.warnings,
        'notifications': value.notifications,
    };
}

