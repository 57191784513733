import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Simulation {
  facilityId: string;
  simulationId: string;
  status: "RUNNING" | "COMPLETED" | "FAILED";
}

export interface SimulationState {
  token: string | undefined;
  runningSims: Simulation[];
}

const initialState: SimulationState = {
  token: undefined,
  runningSims: [],
};

export const simulationSlice = createSlice({
  name: "simulation",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    addSim: (
      state,
      action: PayloadAction<{ facilityId: string; simulationId: string }>,
    ) => {
      state.runningSims.push({
        facilityId: action.payload.facilityId,
        simulationId: action.payload.simulationId,
        status: "RUNNING",
      });
    },
  },
});

export const { setToken, addSim } = simulationSlice.actions;

export default simulationSlice.reducer;
