import {
  Autosuggest,
  Box,
  Button,
  Container,
  FormField,
  Grid,
  Header,
  SpaceBetween,
  Textarea,
} from "@cloudscape-design/components";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AddDashboardRequest,
  AddDashboardResponseContent,
  AddTagRequest,
  AddTagResponseContent,
  AssociateDashboardTagRequest,
  DashboardFields,
  DashboardReportsType,
  DashboardType,
  DataSourceType,
  FacilitiesListOutput,
  GenerateDashboardEmbedUrlRequest,
  GenerateDashboardEmbedUrlResponseContent,
  useAddDashboard,
  useAddTag,
  useAssociateDashboardTag,
  useGenerateDashboardEmbedUrl,
  useListFacilities,
  useListTags,
} from "api-typescript-react-query-hooks";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { FormInput, FormSelect } from "./components/FormInput";
import { PageWrapper } from "../../../layout/PageWrapper";
import { shortUUID, strToTitleCase } from "../../../utils";
import { errorUnknown } from "../../../utils/errorlog";
import { Regions } from "../../../utils/regions";
import { EmbedDashboard } from "../components/EmbedDashboard";

interface DashboardFormFields {
  title: string;
  dashboardType: OptionDefinition;
  reportType: OptionDefinition;
  sourceType: OptionDefinition;
  facility?: OptionDefinition;
  quicksightDashboardId: string;
  awsAccount: string;
  awsRegion: OptionDefinition;
  namespace?: string;
  description?: string;
}

const optionSchema = (msg?: string) =>
  Yup.object({
    label: Yup.string().required(),
    value: Yup.string().required(msg),
  });
const dashboardValidationSchema: Yup.ObjectSchema<DashboardFormFields> =
  Yup.object().shape({
    title: Yup.string().required("Name is required"),
    description: Yup.string().optional(),
    dashboardType: optionSchema("Dashboard type is required").required(),
    reportType: optionSchema("Report type is required").required(),
    facility: Yup.object().optional(),
    sourceType: optionSchema("Source type is required").required(),
    quicksightDashboardId: Yup.string().required("Dashboard ID is required"),
    awsAccount: Yup.string()
      .length(12, "AWS Account ID must be 12 digits")
      .required("Valid AWS Account ID is required"),
    awsRegion: optionSchema("AWS Region is required").required(),
    namespace: Yup.string().optional(),
  });

type TagOption = { tagId?: string; value?: string };
interface Tag {
  id: string;
  name: TagOption;
  group?: TagOption;
  type: string;
}
const tagsValidationSchema = Yup.object().shape({
  name: Yup.string().required("Tag name is required"),
  group: Yup.string().optional(),
  existingName: Yup.object().optional(),
  existingGroup: Yup.object().optional(),
});

const CreateDashboardView = (): JSX.Element => {
  const [dashboardTags, setDashboardTags] = useState<Tag[]>([]);
  const [facilities, setFacilities] = useState<FacilitiesListOutput[]>([]);
  const [embedUrl, setEmbedUrl] = useState<string>("");
  const [isDashboardValid, setPreviewDashboard] = useState<boolean>(false);
  const [isFacilityRequired, setFacilityRequired] = useState<boolean>(false);

  //Mutations
  const dashboardMutation = useAddDashboard();
  const generateEmbedUrlMutation = useGenerateDashboardEmbedUrl();
  const createTagMutation = useAddTag();
  const associateTagMutation = useAssociateDashboardTag();

  //Prepare form validation
  const {
    handleSubmit,
    reset,
    control,
    trigger,
    formState: { errors, isValid },
    watch,
  } = useForm({
    resolver: yupResolver(dashboardValidationSchema),
  });
  const {
    handleSubmit: handleAddTag,
    reset: resetTagForm,
    setValue,
    control: tagControl,
    formState: { errors: tagErrors, isValid: isTagValid },
  } = useForm({
    resolver: yupResolver(tagsValidationSchema),
  });

  //Prepare form inputs
  const {
    data: facilitiesResult,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useListFacilities({});
  const { data: tagList } = useListTags();
  const nextPage = () => {
    fetchNextPage().catch((err) => {
      errorUnknown(err);
    });
  };
  useEffect(() => {
    if (!isFetching) {
      if (hasNextPage) nextPage();
      const newFacilities = facilitiesResult!.pages.flatMap((f) => {
        return f.facilitiesList;
      });
      setFacilities(newFacilities);
    }
  }, [isFetching]);

  //Dashboard Preview
  const awsRegion: OptionDefinition = watch("awsRegion");
  const region = awsRegion?.value;
  const awsAccountId = watch("awsAccount");
  const quicksightDashboardId = watch("quicksightDashboardId");
  const namespace = watch("namespace");
  useEffect(() => {
    if (awsAccountId && region && quicksightDashboardId) {
      setPreviewDashboard(true);
    } else {
      setPreviewDashboard(false);
    }
  }, [awsAccountId, region, quicksightDashboardId, namespace]);

  //Enable facility input if dashboard type is 'facility'
  const dashboardType: OptionDefinition = watch("dashboardType");
  useEffect(() => {
    if (dashboardType?.value === "FACILITY") {
      setFacilityRequired(true);
    } else {
      setFacilityRequired(false);
    }
  }, [dashboardType]);

  const validateFacilityId = () => {
    let facility: OptionDefinition = watch("facility");
    if (dashboardType?.value === "facility") {
      if (facility?.value) return true;
      else return false;
    }
    return true;
  };

  //Get dashboard embed url
  const generateEmbedUrl = () => {
    if (awsAccountId && region && quicksightDashboardId) {
      const previewDashboard = {
        dashboard: {
          awsAccountId,
          quicksightDashboardId: quicksightDashboardId,
          awsRegion: region,
          namespace,
        },
      };
      const request: GenerateDashboardEmbedUrlRequest = {
        generateDashboardEmbedUrlRequestContent: previewDashboard,
      };
      generateEmbedUrlMutation
        .mutateAsync(request)
        .then((response: GenerateDashboardEmbedUrlResponseContent) => {
          setEmbedUrl(response.embedUrl);
        })
        .catch((err) => console.error(err));
    }
  };

  // create dashboard
  const createDashboard = (data: DashboardFormFields) => {
    const details: DashboardFields = {
      title: data.title,
      dashboardType: data.dashboardType.value as DashboardType,
      dashboardReportsType: data.reportType.value as DashboardReportsType,
      dataSourceType: data.sourceType.value as DataSourceType,
      dataSourceObject: {
        quicksight: {
          awsAccountId: data.awsAccount,
          awsRegion: data.awsRegion.value!,
          quicksightDashboardId: data.quicksightDashboardId,
          namespace: data.namespace,
        },
      },
      description: data.description,
    };
    // add facility id if dashboard of type facility
    if (data.dashboardType.value === "facility" && data.facility?.value) {
      details.facilityId = data.facility?.value;
    }

    const request: AddDashboardRequest = {
      addDashboardRequestContent: {
        dashboard: details,
      },
    };

    dashboardMutation
      .mutateAsync(request)
      .then(async (response: AddDashboardResponseContent) => {
        //dashboard created
        const newDashboardId = response.dashboardId;

        //create new tags
        const newTags = dashboardTags.filter((tag) => tag.type === "new");
        const newTagIds: string[] = await Promise.all(
          newTags.map(async (tag) => {
            const tagId = await createNewTag(tag.name.value!, tag.group?.value);
            return tagId || "";
          }),
        );

        //existing tagIds
        const existingTagIds: string[] = dashboardTags
          .filter((tag) => tag.type === "existing")
          .map((tag) => tag.name.tagId!);

        //associate all tags to dashboard
        if (newDashboardId) {
          await Promise.all(
            [...newTagIds, ...existingTagIds].map((tag) =>
              associateTag(newDashboardId, tag),
            ),
          );
        }
      })
      .catch((err) => console.error(err));
  };

  const createNewTag = async (
    name: string,
    group?: string,
  ): Promise<string | undefined> => {
    try {
      const request: AddTagRequest = {
        addTagRequestContent: {
          tag: { name, group },
        },
      };
      const response: AddTagResponseContent =
        await createTagMutation.mutateAsync(request);
      return response.tagId;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  };

  const associateTag = async (
    dashboardId: string,
    tagId: string,
  ): Promise<void> => {
    const request: AssociateDashboardTagRequest = { dashboardId, tagId };
    try {
      await associateTagMutation.mutateAsync(request);
    } catch (err) {
      console.error(err);
    }
  };

  const addTag = (data: {
    name: string;
    group?: string;
    existingName?: object;
    existingGroup?: object;
  }) => {
    const { name, group, existingGroup, existingName } = data;
    const resolveType = () =>
      (!existingName && name) || (!existingGroup && group) ? "new" : "existing";
    const newTag: Tag = {
      id: shortUUID(),
      name: existingName || { value: name },
      group: existingGroup || { value: group },
      type: resolveType(),
    };
    setDashboardTags((prevTags) =>
      prevTags ? [...prevTags, newTag] : [newTag],
    );
    resetTagForm();
  };

  const removeTag = (index: string) => {
    setDashboardTags((prevTags) => prevTags.filter((t) => t.id !== index));
  };

  return (
    <PageWrapper
      title={"Create Dashboard"}
      description="Dashboards can either be at an individual Facility level or Network Level"
    >
      <SpaceBetween direction="vertical" size="m">
        <form id="dashboardForm" onSubmit={handleSubmit(createDashboard)}>
          <Container
            header={
              <Header
                variant="h2"
                description="Dashboard types are available for individual facilities or at a regional network level"
              >
                Add a Dashboard
              </Header>
            }
          >
            <SpaceBetween direction="vertical" size="s">
              <Controller
                name="title"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormInput
                    label="Dashboard name"
                    description="What is the focus of this dashboard?"
                    value={value}
                    onChange={onChange}
                    error={errors.title?.message}
                    placeholder="Dashboard Name"
                  />
                )}
              />
              <Controller
                name="description"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormField
                    label="Dashboard description"
                    description="Short description of the dashboard"
                    stretch
                  >
                    <Textarea
                      onChange={({ detail }) => onChange(detail.value)}
                      value={value || ""}
                      placeholder="Dashboard description"
                      rows={2}
                    />
                  </FormField>
                )}
              />
              <Controller
                name="dashboardType"
                control={control}
                render={({ field: { onChange } }) => (
                  <FormSelect
                    label="Dashboard type"
                    description="Facility or Network Level "
                    selectedOption={watch("dashboardType")}
                    onChange={onChange}
                    error={errors.dashboardType?.value?.message}
                    options={[
                      { label: "Facility Dashboard", value: "FACILITY" },
                      {
                        label: "Network Level Dashboard",
                        value: "NETWORK_LEVEL",
                      },
                    ]}
                    placeholder="Select Dashboard Type"
                  />
                )}
              />
              <Controller
                name="facility"
                control={control}
                render={({ field: { onChange } }) => (
                  <FormSelect
                    label="Facility"
                    description="Choose a specific facility for this dashboard"
                    selectedOption={watch("facility")}
                    onChange={onChange}
                    disabled={!isFacilityRequired}
                    error={errors.facility?.value?.message}
                    options={
                      facilities?.map((f) => ({
                        value: f.facilityId,
                        label: strToTitleCase(f.name),
                      })) || []
                    }
                    placeholder="Select Facility"
                  />
                )}
              />
              <Grid
                gridDefinition={[
                  { colspan: { default: 12, xs: 6 } },
                  { colspan: { default: 12, xs: 6 } },
                ]}
              >
                <Controller
                  name="reportType"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <FormSelect
                      label="Report type"
                      description="Choose a specific report type"
                      selectedOption={watch("reportType")}
                      onChange={onChange}
                      error={errors.reportType?.value?.message}
                      options={[
                        { label: "Operational", value: "OPERATIONAL" },
                        { label: "Financial", value: "FINANCIAL" },
                      ]}
                      placeholder="Select Report Type"
                    />
                  )}
                />
                <Controller
                  name="sourceType"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <FormSelect
                      label="Data source type"
                      description="Choose a source for this dashboard"
                      selectedOption={watch("sourceType")}
                      onChange={onChange}
                      error={errors.sourceType?.value?.message}
                      options={[{ label: "QuickSight", value: "QUICKSIGHT" }]}
                      placeholder="Select Source Type"
                    />
                  )}
                />
              </Grid>
              <Grid
                gridDefinition={[
                  { colspan: { default: 12, xs: 6 } },
                  { colspan: { default: 12, xs: 6 } },
                ]}
              >
                <Controller
                  name="awsAccount"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormInput
                      label="AWS Account ID"
                      description="Enter a 12 digit AWS account ID"
                      value={value}
                      onChange={onChange}
                      error={errors.awsAccount?.message}
                      placeholder="AWS Account ID"
                      onBlur={() => trigger("awsAccount")}
                    />
                  )}
                />
                <Controller
                  name="awsRegion"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <FormSelect
                      label="AWS Region"
                      description="Where is this datasource coming from"
                      selectedOption={watch("awsRegion")}
                      onChange={onChange}
                      error={errors.awsRegion?.value?.message}
                      placeholder="AWS Region"
                      options={Regions}
                    />
                  )}
                />
              </Grid>
              <Grid
                gridDefinition={[
                  { colspan: { default: 12, xs: 6 } },
                  { colspan: { default: 12, xs: 6 } },
                ]}
              >
                <Controller
                  name="quicksightDashboardId"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormInput
                      label="QuickSight dashboard ID"
                      description="The dashboard ID of the Quicksight dashboard"
                      value={value}
                      onChange={onChange}
                      error={errors.quicksightDashboardId?.message}
                      placeholder="Dashboard ID"
                    />
                  )}
                />
                <Controller
                  name="namespace"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormInput
                      label={
                        <Box variant="strong">
                          Namespace - <em>optional</em>
                        </Box>
                      }
                      description="Namespaces offer a way to group services for an application"
                      value={value || ""}
                      onChange={onChange}
                      error={errors.namespace?.message}
                      placeholder='Add dashboard namespace - default is "default"'
                    />
                  )}
                />
              </Grid>
              <SpaceBetween size="xs" alignItems="end">
                <Button
                  formAction="none"
                  variant="normal"
                  disabled={!isDashboardValid}
                  onClick={generateEmbedUrl}
                >
                  Preview
                </Button>
              </SpaceBetween>
              <Box>{embedUrl && <EmbedDashboard embed_url={embedUrl} />}</Box>
            </SpaceBetween>
          </Container>
        </form>
        <form id="tagForm" onSubmit={handleAddTag((data) => addTag(data))}>
          <Container
            header={
              <Header
                variant="h2"
                description="Manage the taxonomy of how to find this dashboard by search"
              >
                Dashboard Tags
              </Header>
            }
          >
            <GridWrapper
              gridDefinition={[
                { colspan: { default: 12, xs: 5 } },
                { colspan: { default: 12, xs: 5 } },
                { colspan: { default: 12, xs: 2 } },
              ]}
            >
              <Controller
                name="name"
                control={tagControl}
                render={({ field }) => (
                  <FormField label="Choose a tag or create one">
                    <Autosuggest
                      onSelect={({ detail }) => {
                        const { value, selectedOption } = detail;
                        if (selectedOption && value === selectedOption?.value) {
                          setValue("existingName", selectedOption);
                        } else setValue("existingName", undefined);
                      }}
                      onChange={({ detail }) => field.onChange(detail.value)}
                      enteredTextLabel={(value) => `Use: "${value}"`}
                      value={field.value || ""}
                      options={
                        tagList?.tags.flatMap((t) => ({
                          tagId: t.tagId,
                          value: t.name,
                        })) || []
                      }
                      ariaLabel="Enter tag name"
                      placeholder="Add a tag e.g production"
                      empty="No matches found"
                      errorText={tagErrors.name?.message}
                    />
                  </FormField>
                )}
              />
              <Controller
                name="group"
                control={tagControl}
                render={({ field }) => (
                  <FormField label="Add a group or create new">
                    <Autosuggest
                      onSelect={({ detail }) => {
                        const { value, selectedOption } = detail;
                        if (selectedOption && value === selectedOption?.value) {
                          setValue("existingGroup", selectedOption);
                        } else setValue("existingGroup", undefined);
                      }}
                      onChange={({ detail }) => field.onChange(detail.value)}
                      enteredTextLabel={(value) => `Use: "${value}"`}
                      value={field.value || ""}
                      options={
                        tagList?.tags.flatMap((t) => ({
                          tagId: t.tagId,
                          value: t.group,
                        })) || []
                      }
                      ariaLabel="Search group name or add new"
                      placeholder="Add tag group e.g manufacturing"
                      empty="No matches found"
                    />
                  </FormField>
                )}
              />
              <Button
                variant="normal"
                formAction="submit"
                disabled={!isTagValid}
              >
                Add
              </Button>
            </GridWrapper>
            <Box margin={{ vertical: "m" }}>
              {dashboardTags.map((t) => {
                const tag = t.group?.value
                  ? `${t.name.value}::${t.group.value}`
                  : t.name.value;
                return (
                  <Grid
                    key={t.id}
                    gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
                  >
                    <Box>{tag}</Box>
                    <Button variant="normal" onClick={() => removeTag(t.id)}>
                      Delete
                    </Button>
                  </Grid>
                );
              })}
            </Box>
          </Container>
        </form>
        <Box margin={{ top: "xs" }} float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button formAction="none" variant="link" onClick={() => reset()}>
              Cancel
            </Button>
            <Button
              formAction="submit"
              form="dashboardForm"
              disabled={!(isValid && validateFacilityId())}
              variant="primary"
            >
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      </SpaceBetween>
    </PageWrapper>
  );
};

const GridWrapper = styled(Grid)`
  align-items: end;
`;

export default CreateDashboardView;
