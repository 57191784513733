import { TopNavigation } from "@cloudscape-design/components";
import { useAuth } from "../app/components/AuthProvider";
import { useConfig } from "../app/components/ConfigProvider";
import Logo from "../design/Logo.svg";
import { SearchBar } from "../modules/Search/components/SearchBar";

export const Appbar = (): JSX.Element => {
  const authInfo = useAuth();
  const config = useConfig();
  const signOutUrl = config.data.authBaseUrl + "/logout";

  return (
    <TopNavigation
      identity={{
        href: "/",
        title: "Facility Hub",
        logo: { src: Logo, alt: "Facility Hub" },
      }}
      search={<SearchBar />}
      utilities={[
        {
          type: "menu-dropdown",
          text: authInfo.fullName,
          description: authInfo.email,
          iconName: "user-profile",
          items: [{ id: "signout", text: "Sign out", href: signOutUrl }],
        },
      ]}
    />
  );
};
