/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TagListItem
 */
export interface TagListItem {
    /**
     * 
     * @type {string}
     * @memberof TagListItem
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TagListItem
     */
    group?: string;
    /**
     * 
     * @type {string}
     * @memberof TagListItem
     */
    tagId: string;
}


/**
 * Check if a given object implements the TagListItem interface.
 */
export function instanceOfTagListItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "tagId" in value;

    return isInstance;
}

export function TagListItemFromJSON(json: any): TagListItem {
    return TagListItemFromJSONTyped(json, false);
}

export function TagListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'group': !exists(json, 'group') ? undefined : json['group'],
        'tagId': json['tagId'],
    };
}

export function TagListItemToJSON(value?: TagListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'group': value.group,
        'tagId': value.tagId,
    };
}

