/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SimulationInput } from './SimulationInput';
import {
    SimulationInputFromJSON,
    SimulationInputFromJSONTyped,
    SimulationInputToJSON,
} from './SimulationInput';

/**
 * 
 * @export
 * @interface NewSimulationRequestContent
 */
export interface NewSimulationRequestContent {
    /**
     * 
     * @type {SimulationInput}
     * @memberof NewSimulationRequestContent
     */
    simulation: SimulationInput;
}


/**
 * Check if a given object implements the NewSimulationRequestContent interface.
 */
export function instanceOfNewSimulationRequestContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "simulation" in value;

    return isInstance;
}

export function NewSimulationRequestContentFromJSON(json: any): NewSimulationRequestContent {
    return NewSimulationRequestContentFromJSONTyped(json, false);
}

export function NewSimulationRequestContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewSimulationRequestContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'simulation': SimulationInputFromJSON(json['simulation']),
    };
}

export function NewSimulationRequestContentToJSON(value?: NewSimulationRequestContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'simulation': SimulationInputToJSON(value.simulation),
    };
}

