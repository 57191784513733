import { Route, Routes } from "react-router-dom";
import { RouteGuard } from "../app/components/RouteGuard";
import DefaultLayout from "../layout/DefaultLayout";
import { MapDashboardFacility } from "../modules/Admin/views/MapDashboardFacility";
import { MapUserFacility } from "../modules/Admin/views/MapUserFacility";
import CreateDashboardView from "../modules/Dashboard/views/CreateDashboardView";
import { DashboardView } from "../modules/Dashboard/views/DashboardView";
import EditDashboardView from "../modules/Dashboard/views/EditDashboardView";
import { EditCreateFacility } from "../modules/Facility/views/EditCreateFacility";
import FacilityListView from "../modules/Facility/views/FacilityListView";
import FacilityView from "../modules/Facility/views/FacilityView";
import HomeView from "../modules/Home/views/HomeView";
import NotFoundView from "../modules/NotFound/views/NotFoundView";
import CreateTagView from "../modules/Tag/views/CreateTagView";
import TagListView from "../modules/Tag/views/TagListView";

export const NavigationRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<RouteGuard element={<DefaultLayout />} />}>
        <Route index element={<HomeView />} />
        <Route path="facilities" element={<FacilityListView />} />
        <Route path="facilities/:facilityId" element={<FacilityView />} />
        <Route path="dashboards/:dashboardId" element={<DashboardView />} />
        <Route
          path="dashboards/create"
          element={<RouteGuard element={<CreateDashboardView />} adminLock />}
        />
        <Route
          path="dashboards/:dashboardId/edit"
          element={<RouteGuard element={<EditDashboardView />} adminLock />}
        />
        <Route
          path="facilities/new"
          element={<RouteGuard element={<EditCreateFacility />} adminLock />}
        />
        <Route
          path="facilities/:facilityId/edit"
          element={<RouteGuard element={<EditCreateFacility />} adminLock />}
        />
        <Route
          path="admin/mapUserFacility"
          element={<RouteGuard element={<MapUserFacility />} adminLock />}
        />
        <Route
          path="admin/mapDashboardFacility"
          element={<RouteGuard element={<MapDashboardFacility />} adminLock />}
        />
        <Route path="admin/taxonomy" element={<TagListView />} />
        <Route path="admin/taxonomy/create" element={<CreateTagView />} />
        <Route path="*" element={<NotFoundView />} />
      </Route>
    </Routes>
  );
};
