/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FacilityStatus } from './FacilityStatus';
import {
    FacilityStatusFromJSON,
    FacilityStatusFromJSONTyped,
    FacilityStatusToJSON,
} from './FacilityStatus';

/**
 * 
 * @export
 * @interface ListFacilitiesStatusResponseContent
 */
export interface ListFacilitiesStatusResponseContent {
    /**
     * 
     * @type {Array<FacilityStatus>}
     * @memberof ListFacilitiesStatusResponseContent
     */
    statuses: Array<FacilityStatus>;
}


/**
 * Check if a given object implements the ListFacilitiesStatusResponseContent interface.
 */
export function instanceOfListFacilitiesStatusResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "statuses" in value;

    return isInstance;
}

export function ListFacilitiesStatusResponseContentFromJSON(json: any): ListFacilitiesStatusResponseContent {
    return ListFacilitiesStatusResponseContentFromJSONTyped(json, false);
}

export function ListFacilitiesStatusResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListFacilitiesStatusResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'statuses': ((json['statuses'] as Array<any>).map(FacilityStatusFromJSON)),
    };
}

export function ListFacilitiesStatusResponseContentToJSON(value?: ListFacilitiesStatusResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'statuses': ((value.statuses as Array<any>).map(FacilityStatusToJSON)),
    };
}

