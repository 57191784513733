import { SVGProps } from "react";
import { JSX } from "react/jsx-runtime";

export const FacilityIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>,
) => (
  <span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      focusable="false"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        stroke="#E9EBED"
        strokeMiterlimit={10}
        strokeWidth={1}
        d="M0 16 16 16V5L8 0 0 5ZM2 14 14 14V7L2 7ZM4 12 6 12V10H4ZM7 12H9V10H7ZM10 12H12V10H10Z"
      />
    </svg>
  </span>
);
