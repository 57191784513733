import {
  Box,
  Button,
  Header,
  Pagination,
  SpaceBetween,
} from "@cloudscape-design/components";
import Table from "@cloudscape-design/components/table";
import {
  TagListItem,
  useDeleteTag,
  useListTags,
} from "api-typescript-react-query-hooks";
import { useEffect, useState } from "react";
import { setError, setSuccess } from "../../../app/reducers/appSlice";
import { useAppDispatch } from "../../../app/store";
import { PageWrapper } from "../../../layout/PageWrapper";

const TagListView = (): JSX.Element => {
  const { data } = useListTags({});
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [selectedItems, setSelectedItems] = useState<TagListItem[]>([]);
  const tags = data?.tags.flatMap((tag) => tag) || [];
  const [currentPageTags, setCurrentPageTags] = useState<TagListItem[]>([]);

  const dispatch = useAppDispatch();

  const PAGES = 10;
  const deleteMutation = useDeleteTag();

  const paginateTags = () => {
    if (tags.length > 0) {
      const startIndex = (currentPageIndex - 1) * PAGES;
      const endIndex = currentPageIndex * PAGES;
      const _tags = tags.slice(startIndex, endIndex);
      setCurrentPageTags(_tags);
    }
  };

  useEffect(() => {
    paginateTags();
  }, [data, currentPageIndex]);

  const deleteTag = () => {
    const selectedTag = selectedItems.at(0)?.tagId;
    if (selectedTag) {
      deleteMutation
        .mutateAsync({ tagId: selectedTag })
        .then(() => dispatch(setSuccess("Tag successfully deleted")))
        .catch((err) => {
          dispatch(setError(`An error occurred deleting tag: ${err}`));
        });
    }
  };

  return (
    <PageWrapper
      title="Taxonomy"
      description="Manage Tags and Tag Groups associated with Facilities"
    >
      <SpaceBetween direction="vertical" size="l">
        <Table
          onSelectionChange={({ detail }) =>
            setSelectedItems(detail.selectedItems)
          }
          selectedItems={selectedItems}
          header={
            <Box margin={{ top: "xs" }}>
              <Header
                variant="h2"
                description={
                  <Box variant="span" fontSize="body-s">
                    Tags and Groups help find dashboards and filter facilities
                  </Box>
                }
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      disabled={!selectedItems.length}
                      onClick={deleteTag}
                    >
                      Delete Tag
                    </Button>
                    <Button
                      href="/admin/taxonomy/create"
                      iconName="add-plus"
                      variant="primary"
                    >
                      Add Tag
                    </Button>
                  </SpaceBetween>
                }
              >
                <strong>Manage Tags</strong>
              </Header>
            </Box>
          }
          columnDefinitions={[
            {
              id: "name",
              header: <strong>Tag</strong>,
              cell: (item: TagListItem) => item.name,
              sortingField: "name",
              isRowHeader: true,
            },
            {
              id: "group",
              header: <strong>Group</strong>,
              cell: (item: TagListItem) => item.group,
              sortingField: "group",
              isRowHeader: true,
            },
          ]}
          columnDisplay={[
            { id: "name", visible: true },
            { id: "group", visible: true },
          ]}
          wrapLines
          selectionType="single"
          trackBy="tagId"
          items={currentPageTags}
          loadingText="Loading tags"
          pagination={
            <Pagination
              currentPageIndex={currentPageIndex}
              onChange={({ detail }) =>
                setCurrentPageIndex(detail.currentPageIndex)
              }
              pagesCount={Math.round(tags.length / PAGES)}
            />
          }
          empty={
            <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
              <b>No tags</b>
            </Box>
          }
          footer={<Box margin={{ vertical: "xs" }}></Box>}
        />
      </SpaceBetween>
    </PageWrapper>
  );
};

export default TagListView;
