import {
  Icon,
  SideNavigation,
  SideNavigationProps,
} from "@cloudscape-design/components";
import {
  BookmarkObjectType,
  useListUserBookmarks,
} from "api-typescript-react-query-hooks";
import { useEffect, useState } from "react";
import { useAuth } from "../app/components/AuthProvider";
import { useAppDispatch, useAppSelector } from "../app/store";
import { DashboardIcon } from "../design/DashboardIcon";
import { FacilityIcon } from "../design/FacilityIcon";
import { set } from "../modules/Bookmarks/store/bookmarksSlice";
import { resourceUrlBuilder } from "../utils/resourceUrlBuilder";

const icon = (objectType: BookmarkObjectType) => {
  if (objectType == "FacilitySite") {
    return <Icon svg=<FacilityIcon /> />;
  } else {
    return <Icon svg=<DashboardIcon /> />;
  }
};

export const NavDrawer = (): JSX.Element => {
  const [activeHref, setActiveHref] = useState<string>("#/page1");
  const { data } = useListUserBookmarks({});
  const dispatch = useAppDispatch();
  const bookmarksState = useAppSelector((state) => state.bookmarks);
  const auth = useAuth();

  const items = (bookmarks: any[]): SideNavigationProps.Item[] => {
    const homeItem: SideNavigationProps.Item = {
      type: "link",
      text: "Home",
      href: "/",
    };

    const adminItems: SideNavigationProps.Item[] = [
      { type: "divider" },
      {
        type: "section",
        text: "Admin Actions",
        items: [
          {
            type: "link",
            text: "Create a Facility",
            href: "/facilities/new",
          },
          {
            type: "link",
            text: "Add a Dashboard",
            href: "/dashboards/create",
          },
          {
            type: "link",
            text: "Map User to Facility",
            href: "/admin/mapUserFacility",
          },
          {
            type: "link",
            text: "Map Dashboard to Facility",
            href: "/admin/mapDashboardFacility",
          },
          {
            type: "link",
            text: "List Tags",
            href: "/admin/taxonomy",
          },
          {
            type: "link",
            text: "Create Tag",
            href: "/admin/taxonomy/create",
          },
        ],
      },
    ];

    const bookmarksSection: SideNavigationProps.Item = {
      type: "section",
      text: "Bookmarks",
      items:
        bookmarks?.map((b) => {
          return {
            type: "link",
            href: resourceUrlBuilder(b.objectType, b.objectId),
            text: b.name,
            info: icon(b.objectType),
          };
        }) || [],
    };

    const routes: SideNavigationProps.Item[] = [homeItem];

    if (auth.isAdmin) {
      routes.push(...adminItems);
    }

    return [
      ...routes,
      { type: "divider" },
      {
        type: "link",
        href: "/facilities",
        text: "Facilities",
      },
      { type: "divider" },
      bookmarksSection,
    ];
  };

  useEffect(() => {
    if (data) {
      dispatch(set(data.bookmarks));
    }
  }, [data]);

  return (
    <SideNavigation
      activeHref={activeHref}
      header={{
        href: "#/",
        text: "Facility Hub",
      }}
      items={items(bookmarksState?.bookmarks)}
      onFollow={(event) => setActiveHref(event.detail.href)}
    />
  );
};
