import {
  GetPlaceCommand,
  LocationClient,
  SearchPlaceIndexForSuggestionsCommand,
} from "@aws-sdk/client-location";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";

export const getLocationServicesClient = async (
  region: string,
  token: string,
  username: string,
  identityPoolId: string,
  userPoolId: string,
): Promise<LocationClient> => {
  const credentialsProvider = fromCognitoIdentityPool({
    identityPoolId: identityPoolId,
    logins: {
      [userPoolId]: token,
    },
    clientConfig: { region },
    userIdentifier: username,
  });

  const client = new LocationClient({
    credentials: credentialsProvider,
    region: region,
  });

  return client;
};

export const searchPlaceByText = async (
  searchText: string,
  indexName: string,
  client: LocationClient,
) => {
  const input = {
    // SearchPlaceIndexForSuggestionsRequest
    IndexName: indexName,
    Text: searchText,
    MaxResults: 10,
  };

  const command = new SearchPlaceIndexForSuggestionsCommand(input);
  return client
    .send(command)
    .then((result) => {
      return result.Results;
    })
    .catch((e) => {
      throw new Error(
        `Unable to fetch address suggestions. Details: ${e.toString()}`,
      );
    });
};

export const getPlaceCoordinates = async (
  placeId: string,
  indexName: string,
  client: LocationClient,
) => {
  const input = {
    // GetPlaceRequest
    IndexName: indexName,
    PlaceId: placeId,
  };
  const command = new GetPlaceCommand(input);
  return client
    .send(command)
    .then((place) => place.Place)
    .catch((e) => {
      throw new Error(
        `Unable to retrieve place information for placeId ${placeId}. Details: ${e.toString()}`,
      );
    });
};
