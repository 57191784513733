import { FormField, Input, Select } from "@cloudscape-design/components";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { NonCancelableEventHandler } from "@cloudscape-design/components/internal/events";
import { ReactNode } from "react";

interface FormSelectProps {
  label?: string;
  description?: string;
  error?: string;
  placeholder?: string;
  onChange: (...event: any[]) => void;
  selectedOption: OptionDefinition | null;
  options: OptionDefinition[];
  disabled?: boolean;
}

interface FormInputProps {
  label?: ReactNode;
  description?: string;
  value: string;
  error?: string;
  placeholder?: string;
  stretch?: boolean;
  onChange: (...event: any[]) => void;
  onBlur?: NonCancelableEventHandler<null> | undefined;
}

export const FormInput = ({
  label,
  description,
  value,
  onChange,
  error,
  placeholder,
  stretch = true,
  onBlur,
}: FormInputProps) => {
  return (
    <FormField
      label={label}
      description={description}
      errorText={error}
      stretch={stretch}
    >
      <Input
        onChange={({ detail }) => onChange(detail.value)}
        value={value}
        placeholder={placeholder}
        onBlur={onBlur}
      />
    </FormField>
  );
};

export const FormSelect = ({
  label,
  description,
  selectedOption,
  onChange,
  options,
  error,
  placeholder,
  disabled,
}: FormSelectProps) => {
  return (
    <FormField
      label={label}
      description={description}
      errorText={error}
      stretch
    >
      <Select
        selectedOption={selectedOption}
        onChange={({ detail }) => onChange(detail.selectedOption)}
        options={options}
        placeholder={placeholder}
        disabled={disabled}
      />
    </FormField>
  );
};
