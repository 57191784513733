/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export type DataSourceType =
  'QUICKSIGHT' | 
  'GRAFANA'


export function DataSourceTypeFromJSON(json: any): DataSourceType {
    return DataSourceTypeFromJSONTyped(json, false);
}

export function DataSourceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSourceType {
    return json as DataSourceType;
}

export function DataSourceTypeToJSON(value?: DataSourceType | null): any {
    return value as any;
}

