interface KeyValue {
  [key: string]: string;
}

export async function uploadToS3(
  url: string,
  fields: KeyValue,
  file: File,
): Promise<void> {
  const formData = new FormData();
  Object.entries(fields).forEach(([field, value]) => {
    formData.append(field, value);
  });
  formData.append("file", file);

  const requestOptions = {
    method: "POST",
    body: formData,
  };

  return fetch(url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Unable to upload file. Response: ${response.text}`);
      }
      return;
    })
    .catch((err) => {
      throw err;
    });
}
