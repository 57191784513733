import { TokenGroup } from "@cloudscape-design/components";

export interface CompactUsersListProps {
  users: Array<string>;
  deleteCallback: CallableFunction;
}

export const CompactUsersList = (props: CompactUsersListProps) => {
  const items = props.users.map((user) => {
    return { label: user, dismissLabel: `Remove user ${user}` };
  });

  return (
    <TokenGroup
      onDismiss={({ detail: { itemIndex } }) => {
        props.deleteCallback(items[itemIndex].label);
      }}
      items={items}
    />
  );
};
