import {
  Box,
  Button,
  ColumnLayout,
  SpaceBetween,
  Spinner,
} from "@cloudscape-design/components";
import {
  DashboardOutput,
  useListNetworkLevelDashboards,
} from "api-typescript-react-query-hooks";
import { useEffect, useRef } from "react";
import { DashboardCard } from "./DashboardCard";
import { errorUnknown } from "../../../utils/errorlog";

export const NetworkDashboards = (): JSX.Element => {
  const observerTarget = useRef(null);

  const { data, hasNextPage, fetchNextPage, isFetching } =
    useListNetworkLevelDashboards({});
  const dashboards: DashboardOutput[] =
    data?.pages.flatMap((page) => page.networkLevelDashboardList) || [];

  const nextPage = () => {
    fetchNextPage().catch((err) => {
      errorUnknown(err);
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          nextPage();
        }
      },
      { threshold: 1 },
    );
    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }
    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget]);

  if (isFetching)
    return (
      <Box padding={{ horizontal: "xl" }}>
        <Spinner variant="disabled" />
        <Box variant="span" color="text-status-inactive" margin={"xs"}>
          Loading dashboards
        </Box>
      </Box>
    );
  if (!dashboards.length) {
    return (
      <Box padding={{ horizontal: "xl" }}>
        <Box variant="span" margin={"xs"}>
          No dashboards found
        </Box>
      </Box>
    );
  }

  return (
    <Box padding={{ horizontal: "xl" }}>
      <SpaceBetween size="xl">
        <ColumnLayout columns={2}>
          {dashboards.map((dashboard, key) => {
            return (
              <DashboardCard
                key={key}
                dashboardId={dashboard.dashboardId}
                title={dashboard.title}
                dashboardType={dashboard.dashboardType}
                dashboardReportsType={dashboard.dashboardReportsType}
                description={dashboard?.description}
                embedUrl={dashboard.embedUrl}
              />
            );
          })}
        </ColumnLayout>
        <Box textAlign="center">
          <div ref={observerTarget}>
            <Button
              onClick={() => {
                hasNextPage && !isFetching && nextPage();
              }}
              disabled={!hasNextPage}
              loading={isFetching}
              loadingText="Loading..."
            >
              {isFetching
                ? "Loading..."
                : hasNextPage
                  ? "View more"
                  : "All dashboards loaded"}
            </Button>
          </div>
        </Box>
      </SpaceBetween>
    </Box>
  );
};
