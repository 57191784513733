import { StatusIndicator } from "@cloudscape-design/components";
import {
  SimulationOutput,
  useGetSimulationStatus,
} from "api-typescript-react-query-hooks";

interface SimulationStatusIndicatorProps {
  facilityId: string;
  simulationId: string;
  currentSim: SimulationOutput;
}

export const SimulationStatusIndicator = ({
  facilityId,
  simulationId,
  currentSim,
}: SimulationStatusIndicatorProps): JSX.Element => {
  const { data: updatedStatus } = useGetSimulationStatus(
    {
      facilityId,
      simulationId,
    },
    { enabled: currentSim.status == "RUNNING" },
  );

  const renderStatus = updatedStatus?.simulation?.status || currentSim.status;

  const statusColumn = (status: string): JSX.Element => {
    if (status == "RUNNING") {
      return <StatusIndicator type="pending">Running</StatusIndicator>;
    } else if (status == "COMPLETE" || status == "SUCCESS") {
      return <StatusIndicator type="success">Completed</StatusIndicator>;
    } else if (status == "ERROR") {
      return <StatusIndicator type="error">Error</StatusIndicator>;
    } else {
      return <></>;
    }
  };

  return statusColumn(renderStatus);
};
