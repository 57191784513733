/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TagFields
 */
export interface TagFields {
    /**
     * 
     * @type {string}
     * @memberof TagFields
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TagFields
     */
    group?: string;
}


/**
 * Check if a given object implements the TagFields interface.
 */
export function instanceOfTagFields(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function TagFieldsFromJSON(json: any): TagFields {
    return TagFieldsFromJSONTyped(json, false);
}

export function TagFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagFields {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'group': !exists(json, 'group') ? undefined : json['group'],
    };
}

export function TagFieldsToJSON(value?: TagFields | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'group': value.group,
    };
}

