import { Button } from "@cloudscape-design/components";

const NotFoundView = (): JSX.Element => {
  return (
    <div>
      <p>Page Not Found</p>
      <Button href="/">Go Home</Button>
    </div>
  );
};

export default NotFoundView;
