/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export type SurfaceUnits =
  'SQUARE_METERS' | 
  'SQUARE_FEET'


export function SurfaceUnitsFromJSON(json: any): SurfaceUnits {
    return SurfaceUnitsFromJSONTyped(json, false);
}

export function SurfaceUnitsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurfaceUnits {
    return json as SurfaceUnits;
}

export function SurfaceUnitsToJSON(value?: SurfaceUnits | null): any {
    return value as any;
}

