/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Area } from './Area';
import {
    AreaFromJSON,
    AreaFromJSONTyped,
    AreaToJSON,
} from './Area';
import type { ContactPerson } from './ContactPerson';
import {
    ContactPersonFromJSON,
    ContactPersonFromJSONTyped,
    ContactPersonToJSON,
} from './ContactPerson';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './Location';

/**
 * 
 * @export
 * @interface FacilityFields
 */
export interface FacilityFields {
    /**
     * 
     * @type {string}
     * @memberof FacilityFields
     */
    name: string;
    /**
     * 
     * @type {Location}
     * @memberof FacilityFields
     */
    location: Location;
    /**
     * 
     * @type {string}
     * @memberof FacilityFields
     */
    description?: string;
    /**
     * 
     * @type {Area}
     * @memberof FacilityFields
     */
    area?: Area;
    /**
     * 
     * @type {Array<ContactPerson>}
     * @memberof FacilityFields
     */
    siteContacts?: Array<ContactPerson>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FacilityFields
     */
    transportationNearby?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FacilityFields
     */
    pointsOfInterestNearby?: Array<string>;
}


/**
 * Check if a given object implements the FacilityFields interface.
 */
export function instanceOfFacilityFields(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "location" in value;

    return isInstance;
}

export function FacilityFieldsFromJSON(json: any): FacilityFields {
    return FacilityFieldsFromJSONTyped(json, false);
}

export function FacilityFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FacilityFields {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'location': LocationFromJSON(json['location']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'area': !exists(json, 'area') ? undefined : AreaFromJSON(json['area']),
        'siteContacts': !exists(json, 'siteContacts') ? undefined : ((json['siteContacts'] as Array<any>).map(ContactPersonFromJSON)),
        'transportationNearby': !exists(json, 'transportationNearby') ? undefined : json['transportationNearby'],
        'pointsOfInterestNearby': !exists(json, 'pointsOfInterestNearby') ? undefined : json['pointsOfInterestNearby'],
    };
}

export function FacilityFieldsToJSON(value?: FacilityFields | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'location': LocationToJSON(value.location),
        'description': value.description,
        'area': AreaToJSON(value.area),
        'siteContacts': value.siteContacts === undefined ? undefined : ((value.siteContacts as Array<any>).map(ContactPersonToJSON)),
        'transportationNearby': value.transportationNearby,
        'pointsOfInterestNearby': value.pointsOfInterestNearby,
    };
}

