import { TokenGroup } from "@cloudscape-design/components";
import { DashboardOutput } from "api-typescript-react-query-hooks";

export interface CompactDashboardListProps {
  dashboards: Array<DashboardOutput>;
  deleteCallback: CallableFunction;
}

export const CompactDashboardList = (props: CompactDashboardListProps) => {
  const items = props.dashboards.map((dashboard) => {
    return {
      label: dashboard.title,
      description: dashboard.description || "",
      tags: [dashboard.dashboardType, dashboard.dashboardReportsType],
    };
  });

  return (
    <TokenGroup
      items={items}
      onDismiss={({ detail: { itemIndex } }) => {
        props.deleteCallback(itemIndex);
      }}
    />
  );
};
