/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SearchResultResource } from './SearchResultResource';
import {
    SearchResultResourceFromJSON,
    SearchResultResourceFromJSONTyped,
    SearchResultResourceToJSON,
} from './SearchResultResource';

/**
 * 
 * @export
 * @interface SearchResourcesResponseContent
 */
export interface SearchResourcesResponseContent {
    /**
     * 
     * @type {string}
     * @memberof SearchResourcesResponseContent
     */
    nextToken?: string;
    /**
     * 
     * @type {Array<SearchResultResource>}
     * @memberof SearchResourcesResponseContent
     */
    page: Array<SearchResultResource>;
}


/**
 * Check if a given object implements the SearchResourcesResponseContent interface.
 */
export function instanceOfSearchResourcesResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "page" in value;

    return isInstance;
}

export function SearchResourcesResponseContentFromJSON(json: any): SearchResourcesResponseContent {
    return SearchResourcesResponseContentFromJSONTyped(json, false);
}

export function SearchResourcesResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchResourcesResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nextToken': !exists(json, 'nextToken') ? undefined : json['nextToken'],
        'page': ((json['page'] as Array<any>).map(SearchResultResourceFromJSON)),
    };
}

export function SearchResourcesResponseContentToJSON(value?: SearchResourcesResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nextToken': value.nextToken,
        'page': ((value.page as Array<any>).map(SearchResultResourceToJSON)),
    };
}

