/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AlertSeverityTypeEnum } from './AlertSeverityTypeEnum';
import {
    AlertSeverityTypeEnumFromJSON,
    AlertSeverityTypeEnumFromJSONTyped,
    AlertSeverityTypeEnumToJSON,
} from './AlertSeverityTypeEnum';
import type { AlertSourceType } from './AlertSourceType';
import {
    AlertSourceTypeFromJSON,
    AlertSourceTypeFromJSONTyped,
    AlertSourceTypeToJSON,
} from './AlertSourceType';
import type { DashboardReportsType } from './DashboardReportsType';
import {
    DashboardReportsTypeFromJSON,
    DashboardReportsTypeFromJSONTyped,
    DashboardReportsTypeToJSON,
} from './DashboardReportsType';

/**
 * 
 * @export
 * @interface AlertObject
 */
export interface AlertObject {
    /**
     * 
     * @type {string}
     * @memberof AlertObject
     */
    alertId: string;
    /**
     * 
     * @type {number}
     * @memberof AlertObject
     */
    triggeredAt: number;
    /**
     * 
     * @type {AlertSeverityTypeEnum}
     * @memberof AlertObject
     */
    severity: AlertSeverityTypeEnum;
    /**
     * 
     * @type {AlertSourceType}
     * @memberof AlertObject
     */
    sourceType: AlertSourceType;
    /**
     * 
     * @type {DashboardReportsType}
     * @memberof AlertObject
     */
    reportsType: DashboardReportsType;
    /**
     * 
     * @type {string}
     * @memberof AlertObject
     */
    alertType: string;
    /**
     * 
     * @type {string}
     * @memberof AlertObject
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertObject
     */
    dashboardId?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertObject
     */
    facilityId?: string;
    /**
     * 
     * @type {number}
     * @memberof AlertObject
     */
    valueTrigger?: number;
    /**
     * 
     * @type {number}
     * @memberof AlertObject
     */
    valueThreshold?: number;
}


/**
 * Check if a given object implements the AlertObject interface.
 */
export function instanceOfAlertObject(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "alertId" in value;
    isInstance = isInstance && "triggeredAt" in value;
    isInstance = isInstance && "severity" in value;
    isInstance = isInstance && "sourceType" in value;
    isInstance = isInstance && "reportsType" in value;
    isInstance = isInstance && "alertType" in value;

    return isInstance;
}

export function AlertObjectFromJSON(json: any): AlertObject {
    return AlertObjectFromJSONTyped(json, false);
}

export function AlertObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alertId': json['alertId'],
        'triggeredAt': json['triggeredAt'],
        'severity': AlertSeverityTypeEnumFromJSON(json['severity']),
        'sourceType': AlertSourceTypeFromJSON(json['sourceType']),
        'reportsType': DashboardReportsTypeFromJSON(json['reportsType']),
        'alertType': json['alertType'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'dashboardId': !exists(json, 'dashboardId') ? undefined : json['dashboardId'],
        'facilityId': !exists(json, 'facilityId') ? undefined : json['facilityId'],
        'valueTrigger': !exists(json, 'valueTrigger') ? undefined : json['valueTrigger'],
        'valueThreshold': !exists(json, 'valueThreshold') ? undefined : json['valueThreshold'],
    };
}

export function AlertObjectToJSON(value?: AlertObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alertId': value.alertId,
        'triggeredAt': value.triggeredAt,
        'severity': AlertSeverityTypeEnumToJSON(value.severity),
        'sourceType': AlertSourceTypeToJSON(value.sourceType),
        'reportsType': DashboardReportsTypeToJSON(value.reportsType),
        'alertType': value.alertType,
        'description': value.description,
        'dashboardId': value.dashboardId,
        'facilityId': value.facilityId,
        'valueTrigger': value.valueTrigger,
        'valueThreshold': value.valueThreshold,
    };
}

