import {
  Autosuggest,
  Badge,
  Box,
  Button,
  Container,
  FormField,
  Grid,
  Header,
  Input,
  SpaceBetween,
} from "@cloudscape-design/components";
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AddTagRequest,
  useAddTag,
  useListTags,
} from "api-typescript-react-query-hooks";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { setError, setSuccess } from "../../../app/reducers/appSlice";
import { useAppDispatch } from "../../../app/store";
import { PageWrapper } from "../../../layout/PageWrapper";

interface Tag {
  name: string;
  group?: string;
}

const tagsValidationSchema = Yup.object().shape({
  name: Yup.string().required("Tag name is required"),
  group: Yup.string().optional().default(""),
});

const CreateTagView = (): JSX.Element => {
  const { data: tagList } = useListTags({});
  const [tags, setTags] = useState<Tag[]>([]);
  const dispatch = useAppDispatch();

  const createTagMutation = useAddTag();

  const {
    handleSubmit,
    reset,
    control,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(tagsValidationSchema),
  });

  const createTags = async () => {
    const newTags = tags;

    await Promise.all(
      newTags.map(async (tag) => {
        const request: AddTagRequest = {
          addTagRequestContent: {
            tag: {
              name: tag.name,
              group: tag.group?.length == 0 ? undefined : tag.group,
            },
          },
        };
        return createTagMutation
          .mutateAsync(request)
          .then()
          .catch((err) => {
            throw err;
          });
      }),
    )
      .then(() => {
        dispatch(setSuccess("Tags successfully created."));
        resetInput();
      })
      .catch((err) => {
        dispatch(setError("An error occurred"));
        console.error(err);
      });
  };

  const tagExists = (name: string, group?: string): boolean => {
    return tagList?.tags.find((t) => t.name == name && t.group == group)
      ? true
      : false || tags.find((t) => t.name == name && t.group == group)
        ? true
        : false;
  };

  const addTag = (data: { name: string; group?: string }) => {
    const { name, group } = data;
    if (tagExists(name, group)) {
      dispatch(setError(`Tag ${name} with group ${group} already exists`));
      return;
    }
    const newTag: Tag = {
      name,
      group,
    };
    setTags([...tags, newTag]);
  };

  const removeTag = (tag: Tag) => {
    setTags(tags.filter((t) => t.name != tag.name || t.group != tag.group));
  };

  const resetInput = () => {
    reset();
    setTags([]);
  };

  const existingRemoteGroups =
    [
      ...new Set(
        tagList?.tags.flatMap((t) => t.group).filter((g) => g) as string[],
      ),
    ] || [];
  const existingRemoteNames =
    (tagList?.tags.flatMap((t) => t.name).filter((n) => n) as string[]) || [];
  const existingLocalNames =
    (tags.flatMap((t) => t.name).filter((n) => n) as string[]) || [];

  const groupOptions = existingRemoteGroups.map((g) => {
    return {
      label: g,
      value: g,
    };
  });

  const groupExistsLabel = (group?: string) => {
    if (!group) {
      return null;
    }

    return existingRemoteGroups.includes(group) ? (
      <Badge color="grey">Group exists</Badge>
    ) : (
      <Badge color="blue">New Group</Badge>
    );
  };

  const nameExistsLabel = (name: string) => {
    return existingRemoteNames.includes(name) ||
      existingLocalNames.filter((n) => n == name).length > 1 ? (
      <Badge color="red">Name exists in another group</Badge>
    ) : null;
  };

  return (
    <PageWrapper
      title="Taxonomy"
      description="Manage Tags and Tag Groups associated with Facilities"
    >
      <Container
        header={
          <Box margin={{ top: "xs" }}>
            <Header
              variant="h2"
              description={
                <Box variant="span" fontSize="body-s">
                  Add a tag and optionally assign it to a group
                </Box>
              }
            >
              <strong>New Tag</strong>
            </Header>
          </Box>
        }
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button onClick={resetInput}>Cancel</Button>
              <Button
                variant="primary"
                disabled={!tags.length}
                onClick={createTags}
              >
                Save
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <form id="tagForm" onSubmit={handleSubmit((data) => addTag(data))}>
          <GridWrapper
            gridDefinition={[
              { colspan: { default: 12, xs: 5 } },
              { colspan: { default: 12, xs: 5 } },
              { colspan: { default: 12, xs: 2 } },
            ]}
          >
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <FormField label="Enter a new tag name">
                  <Input
                    {...field}
                    onChange={({ detail }) => field.onChange(detail.value)}
                    ariaLabel="Enter tag name"
                    placeholder="Add a tag e.g EMEA"
                  />
                </FormField>
              )}
            />
            <Controller
              name="group"
              control={control}
              render={({ field }) => (
                <FormField label="Add a group or create new">
                  <Autosuggest
                    {...field}
                    onSelect={({ detail }) => {
                      field.onChange(detail.value);
                    }}
                    value={field.value || ""}
                    onChange={({ detail }) => field.onChange(detail.value)}
                    options={groupOptions}
                    ariaLabel="Search group name or add new"
                    placeholder="Add tag group e.g geo"
                    empty="No matches found"
                  />
                </FormField>
              )}
            />
            <Button variant="normal" formAction="submit" disabled={!isValid}>
              Add
            </Button>
          </GridWrapper>

          <Box margin={{ vertical: "m" }}>
            {tags.map((t) => {
              const tagText = t.group ? `${t.group}::${t.name}` : t.name;
              return (
                <Grid
                  key={tagText}
                  gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
                >
                  <SpaceBetween direction="horizontal" size="xxs">
                    <Badge color="green">{tagText}</Badge>
                    {groupExistsLabel(t.group)}
                    {nameExistsLabel(t.name)}
                  </SpaceBetween>
                  <Button variant="normal" onClick={() => removeTag(t)}>
                    Delete
                  </Button>
                </Grid>
              );
            })}
          </Box>
        </form>
      </Container>
    </PageWrapper>
  );
};

const GridWrapper = styled(Grid)`
  align-items: end;
`;

export default CreateTagView;
