import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface AppState {
  message: string | undefined;
  messageSeverity: "success" | "error" | "warning" | "info";
}

// Initial state of the app
const initialState: AppState = {
  message: undefined,
  messageSeverity: "info",
};

const errorSlice = createSlice({
  name: "error",
  initialState,
  // Dummy reducers, used for setup
  reducers: {
    setError: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
      state.messageSeverity = "error";
    },
    setWarning: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
      state.messageSeverity = "warning";
    },
    setInfo: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
      state.messageSeverity = "info";
    },
    setSuccess: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
      state.messageSeverity = "success";
    },
  },
});

// Export action creators generated for each reducer
export const { setError, setWarning, setInfo, setSuccess } = errorSlice.actions;

export default errorSlice.reducer;
