/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SurfaceUnits } from './SurfaceUnits';
import {
    SurfaceUnitsFromJSON,
    SurfaceUnitsFromJSONTyped,
    SurfaceUnitsToJSON,
} from './SurfaceUnits';

/**
 * 
 * @export
 * @interface Area
 */
export interface Area {
    /**
     * 
     * @type {SurfaceUnits}
     * @memberof Area
     */
    unit: SurfaceUnits;
    /**
     * 
     * @type {number}
     * @memberof Area
     */
    value: number;
}


/**
 * Check if a given object implements the Area interface.
 */
export function instanceOfArea(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "unit" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function AreaFromJSON(json: any): Area {
    return AreaFromJSONTyped(json, false);
}

export function AreaFromJSONTyped(json: any, ignoreDiscriminator: boolean): Area {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'unit': SurfaceUnitsFromJSON(json['unit']),
        'value': json['value'],
    };
}

export function AreaToJSON(value?: Area | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'unit': SurfaceUnitsToJSON(value.unit),
        'value': value.value,
    };
}

