/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { QuicksightDashboardObject } from './QuicksightDashboardObject';
import {
    QuicksightDashboardObjectFromJSON,
    QuicksightDashboardObjectFromJSONTyped,
    QuicksightDashboardObjectToJSON,
} from './QuicksightDashboardObject';

/**
 * 
 * @export
 * @interface GenerateDashboardEmbedUrlRequestContent
 */
export interface GenerateDashboardEmbedUrlRequestContent {
    /**
     * 
     * @type {QuicksightDashboardObject}
     * @memberof GenerateDashboardEmbedUrlRequestContent
     */
    dashboard: QuicksightDashboardObject;
}


/**
 * Check if a given object implements the GenerateDashboardEmbedUrlRequestContent interface.
 */
export function instanceOfGenerateDashboardEmbedUrlRequestContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dashboard" in value;

    return isInstance;
}

export function GenerateDashboardEmbedUrlRequestContentFromJSON(json: any): GenerateDashboardEmbedUrlRequestContent {
    return GenerateDashboardEmbedUrlRequestContentFromJSONTyped(json, false);
}

export function GenerateDashboardEmbedUrlRequestContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateDashboardEmbedUrlRequestContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dashboard': QuicksightDashboardObjectFromJSON(json['dashboard']),
    };
}

export function GenerateDashboardEmbedUrlRequestContentToJSON(value?: GenerateDashboardEmbedUrlRequestContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dashboard': QuicksightDashboardObjectToJSON(value.dashboard),
    };
}

