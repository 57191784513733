export const strToTitleCase = (str: string) => {
  if (!str) {
    return "";
  }
  return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
};

export const epochTimestampToDate = (timestamp: number) => {
  const date = new Date(timestamp);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const meridiem = parseInt(hours) >= 12 ? "PM" : "AM";
  const formattedHours = parseInt(hours) % 12 || 12;

  const formattedDate = `${day}/${month}/${year} ${formattedHours}:${minutes}:${seconds} ${meridiem}`;
  return formattedDate;
};

export const shortUUID = (): string => {
  return "xxxxxx".replace(/x/g, () => {
    const random = Math.floor(Math.random() * 16);
    return random.toString(16);
  });
};
