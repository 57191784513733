import {
  Alert,
  Badge,
  Box,
  ColumnLayout,
  SpaceBetween,
} from "@cloudscape-design/components";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled from "@emotion/styled";
import {
  Area,
  ContactPerson,
  FacilityFieldsComplete,
  useGetFacility,
  useGetFacilityTags,
  useListTags,
} from "api-typescript-react-query-hooks";
import { Carousel } from "react-responsive-carousel";
import { strToTitleCase } from "../../../utils";

export const FacilityDetails = ({
  facility,
}: {
  facility: FacilityFieldsComplete;
}): JSX.Element => {
  const { data, isFetching, error } = useGetFacility({
    facilityId: facility.facilityId,
  });
  const facilityDetails = data?.facility;

  if (error || (!isFetching && !facilityDetails))
    return (
      <Box padding={{ horizontal: "xl" }}>
        <Alert>Error occured fetching facility details</Alert>
      </Box>
    );

  const { data: tags } = useListTags();
  const { data: facilityTags } = useGetFacilityTags({
    facilityId: facility.facilityId,
  });

  const tagList = facilityTags?.tags.map((tag) => {
    const displayTag = tags?.tags.find((t) => {
      t.tagId == tag.tagId;
    });
    if (displayTag) {
      return <Badge>{`${displayTag?.group}:${displayTag?.name}`}</Badge>;
    } else {
      return <></>;
    }
  });

  return (
    <Box padding={{ horizontal: "xl" }} margin={{ bottom: "l" }}>
      <SpaceBetween size="xl">
        <ColumnLayout columns={2}>
          {facilityDetails?.pictureUriList && (
            <Carousel
              autoPlay
              infiniteLoop
              showArrows
              showStatus={false}
              showThumbs={false}
            >
              {facilityDetails.pictureUriList?.map((pictureUri, index) => (
                <Image
                  key={index}
                  style={{ backgroundImage: `url(${pictureUri})` }}
                />
              ))}
            </Carousel>
          )}
          <SpaceBetween size="xs">
            {tagList}
            {facilityDetails?.description && (
              <Detail
                label={"Description:"}
                value={facilityDetails.description}
              />
            )}
            {facilityDetails?.area && (
              <Detail
                inline
                label={"Building size:"}
                area={facilityDetails.area}
              />
            )}
            {facilityDetails?.transportationNearby && (
              <Detail
                inline
                label={"Nearby transportation:"}
                children={facilityDetails.transportationNearby}
              />
            )}
            {facilityDetails?.pointsOfInterestNearby && (
              <Detail
                inline
                label={"Area points of interest:"}
                children={facilityDetails.pointsOfInterestNearby}
              />
            )}
            {facilityDetails?.location && (
              <Detail
                label={"Address:"}
                value={facilityDetails.location.address}
              />
            )}
          </SpaceBetween>
        </ColumnLayout>
        {facilityDetails?.siteContacts && (
          <Contacts
            label="Site contacts:"
            contacts={facilityDetails.siteContacts}
          />
        )}
      </SpaceBetween>
    </Box>
  );
};

const Contacts = ({
  label,
  contacts,
}: {
  label: string;
  contacts: ContactPerson[];
}) => {
  const column = Math.min(contacts.length, 3);
  return (
    <div>
      <Box margin={{ bottom: "m" }} variant="awsui-key-label">
        {label}
      </Box>
      <ColumnLayout columns={column}>
        {contacts.map((contact, _ind) => (
          <SpaceBetween size="xxs" key={_ind}>
            {contact.name && (
              <Box variant="strong">{strToTitleCase(contact.name)}</Box>
            )}
            {contact.role && <Box>{strToTitleCase(contact.role)}</Box>}
            {contact.email && <Box>{contact.email.toLowerCase()}</Box>}
            {contact.phone && <Box>{contact.phone}</Box>}
          </SpaceBetween>
        ))}
      </ColumnLayout>
    </div>
  );
};

const Detail = ({
  label,
  value,
  children,
  area,
  inline,
}: {
  label: string;
  value?: string;
  children?: string[];
  area?: Area;
  inline?: boolean;
}) => {
  let none;
  if (area) {
    const unit = area.unit === "SQUARE_METERS" ? "m" : "ft";
    return (
      <div>
        <Box
          variant="awsui-key-label"
          float={inline ? "left" : none}
          margin={inline ? { right: "xs" } : none}
        >
          {label}
        </Box>
        <Box variant="span">
          {`${area.value.toLocaleString()} ${unit}`}
          <sup>2</sup>
        </Box>
      </div>
    );
  }

  return (
    <div>
      <Box
        variant="awsui-key-label"
        float={inline ? "left" : none}
        margin={inline ? { right: "xs" } : none}
      >
        {label}
      </Box>
      {value ? (
        <div>{value.at(0)?.toUpperCase() + value.slice(1, value.length)}</div>
      ) : (
        <div>{children?.map((child) => strToTitleCase(child)).join(", ")}</div>
      )}
    </div>
  );
};

const Image = styled.div`
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;
