import {
  Badge,
  Box,
  ColumnLayout,
  Header,
  Link,
  SpaceBetween,
  StatusIndicator,
} from "@cloudscape-design/components";
import {
  useGetFacilityTags,
  useListTags,
} from "api-typescript-react-query-hooks";
import React from "react";
import { Popup } from "react-leaflet";

export interface FacilityPopupProps {
  id: string;
  name: string;
  description: string;
  status: string;
  address?: string;
}

interface ValueWithLabelProps {
  label: string;
  children: React.ReactNode;
}

const ValueWithLabel = (props: ValueWithLabelProps) => (
  <div>
    <Box variant="awsui-key-label">{props.label}</Box>
    <div>{props.children}</div>
  </div>
);

const statusMapper = (status: string) => {
  switch (status) {
    case "green":
      return "success";
    case "red":
      return "error";
    case "yellow":
      return "warning";
    case "grey":
      return "pending";
  }
  return "pending";
};

const statusText = (status: string) => {
  switch (status) {
    case "green":
      return "Green";
    case "red":
      return "Red";
    case "yellow":
      return "Yellow";
    case "grey":
      return "Unknown";
  }
  return "Unknown";
};

const FacilityPopup = (props: FacilityPopupProps) => {
  const { data: tags } = useListTags();
  const { data: facilityTags } = useGetFacilityTags({
    facilityId: props.id,
  });

  const tagList = facilityTags?.tags.map((tag) => {
    console.log(`tag: ${tag}`);
    console.log(`tags: ${tags}`);
    const displayTag = tags?.tags.find((t) => {
      t.tagId == tag.tagId;
    });
    if (displayTag) {
      return `${displayTag?.group}:${displayTag?.name}`;
    } else {
      return undefined;
    }
  });

  return (
    <Popup minWidth={200} maxWidth={600}>
      <SpaceBetween size="l">
        <Header variant="h3">
          <SpaceBetween size="s">
            <Link fontSize="heading-m" href={`facilities/${props.id}`}>
              {props.name}
            </Link>
            <>{props.description}</>
            {tagList?.map((tag) => <Badge>{tag}</Badge>)}
          </SpaceBetween>
        </Header>
        <ColumnLayout columns={2} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel label="status">
              <StatusIndicator type={statusMapper(props.status)}>
                {statusText(props.status)}
              </StatusIndicator>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label="address">
              {props.address || "unknown"}
            </ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
      </SpaceBetween>
    </Popup>
  );
};

export default FacilityPopup;
