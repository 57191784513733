import {
  Box,
  Button,
  Header,
  SpaceBetween,
  ButtonDropdown,
} from "@cloudscape-design/components";
import Table from "@cloudscape-design/components/table";
//import { useQueryClient } from "@tanstack/react-query";
import {
  FacilityFieldsComplete,
  SimulationOutput,
  useListSimulations,
} from "api-typescript-react-query-hooks";
import { useEffect, useRef, useState } from "react";
import { NewSimulationModal } from "./NewSimulationModal";
import { SimulationDetailsModal } from "./SimulationDetailsModal";
import { SimulationStatusIndicator } from "./SimulationStatusIndicator";
import { epochTimestampToDate } from "../../../utils";
import { errorUnknown } from "../../../utils/errorlog";

export const FacilitySimulations = ({
  facility,
}: {
  facility: FacilityFieldsComplete;
}): JSX.Element => {
  const { data, fetchNextPage, hasNextPage, isFetching } = useListSimulations({
    facilityId: facility.facilityId,
  });

  //const queryClient = useQueryClient();

  const [modalVisible, setModalVisible] = useState(false);
  const [newSimModalVisible, setNewSimModalVisible] = useState(false);
  const [modalId, setModalId] = useState<SimulationOutput | undefined>(
    undefined,
  );
  //const [simRefreshIds, setSimRefreshIds] = useState<boolean>(true);
  //const [refreshStatus, setRefreshStatus] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<SimulationOutput[]>([]);

  //const timerIdRef = useRef<number | null>(null);

  // This is to always have a catch of exceptions
  // However, it doesn't appear to be called even if
  // the calls fail (e.g. connection refused, 404)
  // but the `error` parameter is updated
  const nextPage = () => {
    fetchNextPage().catch((err) => {
      errorUnknown(err);
    });
  };

  const simulations =
    data?.pages.flatMap((page) => {
      return page.facilitySimulationList;
    }) || [];

  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          nextPage();
        }
      },
      { threshold: 1 },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget]);

  /*
  useEffect(() => {
    const pollingCallback = async () => {
      console.log(`Polling status... ${simulations}`);

      const refreshedStatus = await Promise.allSettled(
        simulations
          .filter((sim) => sim.status == "RUNNING")
          .flatMap((sim) => {
            console.log(`Using it for simulation: ${sim}`);
            queryClient.fetchQuery({ queryKey: ["getSimulationStatus"],
            const { data: status } = useGetSimulationStatus({
              facilityId: facility.facilityId,
              simulationId: sim.simulationId,
            });
            console.log(`data: ${status}`);
            // TODO Also use status detail
            return Promise.resolve(status?.simulation?.status);
          }),
      ).then((results) => results.map((r) => r));

      console.log(`Refreshed status: ${refreshedStatus}`);

      if (refreshedStatus.length > 0) {
        setSimRefreshIds(true);
        // By invalidating the query, it will refetch the status
        // that was updated in the backend
        // TODO This is pretty bad, find a better solution
        // which is probably running the whole thing in the front-end
        // once they enable CORS
        await queryClient.invalidateQueries({ queryKey: ["listSimulations"] });
      } else {
        setSimRefreshIds(false);
      }

    };

    const startPolling = () => {
      // pollingCallback(); // To immediately start fetching data
      // Polling every 30 seconds
      timerIdRef.current = window.setInterval(pollingCallback, 5000);
    };

    const stopPolling = () => {
      console.log("Stopping polling");
      window.clearInterval(timerIdRef.current!);
    };

    if (simRefreshIds) {
      startPolling();
    } else {
      stopPolling();
    }
  }, [simulations]);
*/
  return (
    <>
      <Table
        onSelectionChange={({ detail }) =>
          setSelectedItems(detail.selectedItems)
        }
        selectedItems={selectedItems}
        columnDefinitions={[
          {
            id: "name",
            header: "Name",
            cell: (item) => (
              <Button
                variant="inline-link"
                onClick={() => {
                  setModalId(item);
                  setModalVisible(true);
                }}
              >
                {item.name}
              </Button>
            ),
            sortingField: "name",
            isRowHeader: true,
          },
          {
            id: "model",
            header: "Model",
            cell: (item) => item.modelName,
            isRowHeader: true,
          },
          {
            id: "startDate",
            header: "Date",
            cell: (item) => epochTimestampToDate(item.startDate),
            isRowHeader: true,
          },
          {
            id: "status",
            header: "Status",
            cell: (item) => (
              <SimulationStatusIndicator
                facilityId={facility.facilityId}
                simulationId={item.simulationId}
                currentSim={item}
              />
            ),
            isRowHeader: true,
          },
        ]}
        wrapLines
        trackBy="simulationId"
        items={simulations}
        loadingText="Loading resources"
        empty={
          <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
            <b>No resources</b>
          </Box>
        }
        footer={
          <Box textAlign="center">
            <div ref={observerTarget}>
              <Button
                onClick={() => {
                  hasNextPage && !isFetching && nextPage();
                }}
                disabled={!hasNextPage}
                loading={isFetching}
                loadingText="Loading..."
              >
                {isFetching
                  ? "Loading..."
                  : hasNextPage
                    ? "View more"
                    : "All simulations loaded"}
              </Button>
            </div>
          </Box>
        }
        selectionType="multi"
        header={
          <Header
            counter={
              selectedItems.length
                ? "(" + selectedItems.length + "/10)"
                : "(10)"
            }
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <ButtonDropdown
                  items={[
                    {
                      text: "See Details",
                      id: "details",
                      disabled: selectedItems.length != 1,
                    },
                    {
                      text: "Download results",
                      id: "download",
                      disabled: false,
                    },
                    {
                      text: "Compare",
                      id: "compare",
                      disabled: selectedItems.length < 2,
                    },
                  ]}
                  onItemClick={({ detail }) => {
                    if (detail.id == "details") {
                      setModalId(selectedItems.values().next().value);
                      setModalVisible(true);
                    }
                  }}
                >
                  Actions
                </ButtonDropdown>
                <Button
                  variant="primary"
                  onClick={() => {
                    setNewSimModalVisible(true);
                  }}
                >
                  Start new simulation
                </Button>
              </SpaceBetween>
            }
          >
            Simulations Archive
          </Header>
        }
      />
      <SimulationDetailsModal
        visible={modalVisible}
        setVisible={setModalVisible}
        simulation={modalId!}
      />
      <NewSimulationModal
        visible={newSimModalVisible}
        setVisible={setNewSimModalVisible}
        facility={facility}
      />
    </>
  );
};
