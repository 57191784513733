/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SimulationOutput } from './SimulationOutput';
import {
    SimulationOutputFromJSON,
    SimulationOutputFromJSONTyped,
    SimulationOutputToJSON,
} from './SimulationOutput';

/**
 * 
 * @export
 * @interface GetSimulationStatusResponseContent
 */
export interface GetSimulationStatusResponseContent {
    /**
     * 
     * @type {SimulationOutput}
     * @memberof GetSimulationStatusResponseContent
     */
    simulation?: SimulationOutput;
}


/**
 * Check if a given object implements the GetSimulationStatusResponseContent interface.
 */
export function instanceOfGetSimulationStatusResponseContent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetSimulationStatusResponseContentFromJSON(json: any): GetSimulationStatusResponseContent {
    return GetSimulationStatusResponseContentFromJSONTyped(json, false);
}

export function GetSimulationStatusResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSimulationStatusResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'simulation': !exists(json, 'simulation') ? undefined : SimulationOutputFromJSON(json['simulation']),
    };
}

export function GetSimulationStatusResponseContentToJSON(value?: GetSimulationStatusResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'simulation': SimulationOutputToJSON(value.simulation),
    };
}

