export const Regions = [
  {
    value: "us-east-1",
    labelTag: "us-east-1",
    label: "US East (N. Virginia)",
  },
  {
    value: "us-east-2",
    labelTag: "us-east-2",
    label: "US East (Ohio)",
  },
  {
    value: "us-west-1",
    labelTag: "us-west-1",
    label: "US West (N. California)",
  },
  {
    value: "us-west-2",
    labelTag: "us-west-2",
    label: "US West (Oregon)",
  },
  {
    value: "us-gov-west-1",
    labelTag: "us-gov-west-1",
    label: "AWS GovCloud (US-West)",
  },
  {
    value: "us-gov-east-1",
    labelTag: "us-gov-east-1",
    label: "AWS GovCloud (US-East)",
  },
  {
    value: "ca-central-1",
    labelTag: "ca-central-1",
    label: "Canada (Central)",
  },
  {
    value: "eu-west-1",
    labelTag: "eu-west-1",
    label: "EU (Ireland)",
  },
  {
    value: "eu-west-2",
    labelTag: "eu-west-2",
    label: "EU (London)",
  },
  {
    value: "eu-west-3",
    labelTag: "eu-west-3",
    label: "EU (Paris)",
  },
  {
    value: "eu-north-1",
    labelTag: "eu-north-1",
    label: "EU (Stockholm)",
  },
  {
    value: "eu-south-1",
    labelTag: "eu-south-1",
    label: "EU (Milan)",
  },
  {
    value: "eu-central-1",
    labelTag: "eu-central-1",
    label: "EU (Frankfurt)",
  },
  {
    value: "af-south-1",
    labelTag: "af-south-1",
    label: "Africa (Cape Town)",
  },
  {
    value: "ap-northeast-3",
    labelTag: "ap-northeast-3",
    label: "Asia Pacific (Osaka-Local)",
  },
  {
    value: "ap-northeast-1",
    labelTag: "ap-northeast-1",
    label: "Asia Pacific (Tokyo)",
  },
  {
    value: "ap-northeast-2",
    labelTag: "ap-northeast-2",
    label: "Asia Pacific (Seoul)",
  },
  {
    value: "ap-southeast-1",
    labelTag: "ap-southeast-1",
    label: "Asia Pacific (Singapore)",
  },
  {
    value: "ap-southeast-2",
    labelTag: "ap-southeast-2",
    label: "Asia Pacific (Sydney)",
  },
  {
    value: "ap-southeast-3",
    labelTag: "ap-southeast-3",
    label: "Asia Pacific (Jakarta)",
  },
  {
    value: "ap-east-1",
    labelTag: "ap-east-1",
    label: "Asia Pacific (Hong Kong)",
  },
  {
    value: "ap-south-1",
    labelTag: "ap-south-1",
    label: "Asia Pacific (Mumbai)",
  },
  {
    value: "sa-east-1",
    labelTag: "sa-east-1",
    label: "South America (São Paulo)",
  },
  {
    value: "me-south-1",
    labelTag: "me-south-1",
    label: "Middle East (Bahrain)",
  },
  {
    value: "cn-north-1",
    labelTag: "cn-north-1",
    label: "China (Beijing)",
  },
  {
    value: "cn-northwest-1",
    labelTag: "cn-northwest-1",
    label: "China (Ningxia)",
  },
];
