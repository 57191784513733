import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface NewPicturesState {
  files: File[];
}

const initialState: NewPicturesState = {
  files: [],
};

export const newPicturesSlice = createSlice({
  name: "newPictures",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<File[]>) => {
      state.files = action.payload;
    },
  },
});

export const { set } = newPicturesSlice.actions;

export default newPicturesSlice.reducer;
