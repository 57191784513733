import {
  Alert,
  ContentLayout,
  Header,
  Icon,
  Link,
  SpaceBetween,
} from "@cloudscape-design/components";
import {
  AddUserBookmarkRequest,
  AddUserBookmarkRequestContent,
  BookmarkObject,
  BookmarkObjectType,
  DeleteUserBookmarkRequest,
  useAddUserBookmark,
  useDeleteUserBookmark,
} from "api-typescript-react-query-hooks";
import { ReactNode } from "react";
import { setError } from "../app/reducers/appSlice";
import { useAppDispatch, useAppSelector } from "../app/store";
import { Bookmark } from "../design/Bookmark";
import { add, remove } from "../modules/Bookmarks/store/bookmarksSlice";

type Props = {
  children: ReactNode;
  title: string;
  description?: string;
  objectId?: string;
  objectType?: BookmarkObjectType;
  bookmarkName?: string;
};

export const PageWrapper = ({
  children,
  title,
  description,
  objectId,
  objectType,
  bookmarkName,
}: Props): JSX.Element => {
  const { bookmarks } = useAppSelector((state) => state.bookmarks);
  const { message, messageSeverity } = useAppSelector((state) => state.error);
  const dispatch = useAppDispatch();

  const addBookmarkMutation = useAddUserBookmark();
  const deleteBookmarkMutation = useDeleteUserBookmark();

  const isBookmarked = bookmarks.find(
    (item: BookmarkObject) =>
      item.objectId == objectId && item.objectType == objectType,
  );

  const addBookmark = () => {
    if (!objectId || !objectType || !bookmarkName) {
      return;
    }

    const newBookmark: AddUserBookmarkRequestContent = {
      bookmark: {
        objectType,
        objectId,
        name: bookmarkName,
      },
    };

    const request: AddUserBookmarkRequest = {
      addUserBookmarkRequestContent: newBookmark,
    };

    addBookmarkMutation
      .mutateAsync(request)
      .then(() => dispatch(add(newBookmark.bookmark)))
      .catch((err) =>
        dispatch(
          setError(`Unable to add bookmark. Please try again. Error: ${err}`),
        ),
      );
  };

  const deleteBookmark = () => {
    if (!objectId || !objectType || !bookmarkName) {
      return;
    }

    const bookmark: BookmarkObject = {
      objectType,
      objectId,
      name: bookmarkName,
    };

    const request: DeleteUserBookmarkRequest = {
      objectId: objectId,
      objectType: objectType,
    };

    deleteBookmarkMutation
      .mutateAsync(request)
      .then(() => dispatch(remove(bookmark)))
      .catch((err) =>
        dispatch(
          setError(
            `Unable to remove bookmark. Please try again. Error: ${err}`,
          ),
        ),
      );
  };

  const updateBookmark = () => {
    if (!isBookmarked) {
      addBookmark();
    } else {
      deleteBookmark();
    }
  };

  return (
    <ContentLayout
      header={
        <SpaceBetween direction="vertical" size="l">
          <Header variant="h1" description={description}>
            {title}
            {objectId && objectType && bookmarkName ? (
              <Link href="" color="inverted" onFollow={() => updateBookmark()}>
                <Icon
                  svg={<Bookmark fill={isBookmarked ? "white" : "none"} />}
                />
              </Link>
            ) : (
              <></>
            )}
          </Header>
          {!!message && <Alert type={messageSeverity}>{message}</Alert>}
        </SpaceBetween>
      }
    >
      {children}
    </ContentLayout>
  );
};
