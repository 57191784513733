/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SimulationOutput } from './SimulationOutput';
import {
    SimulationOutputFromJSON,
    SimulationOutputFromJSONTyped,
    SimulationOutputToJSON,
} from './SimulationOutput';

/**
 * 
 * @export
 * @interface ListSimulationsResponseContent
 */
export interface ListSimulationsResponseContent {
    /**
     * 
     * @type {string}
     * @memberof ListSimulationsResponseContent
     */
    pageToken?: string;
    /**
     * 
     * @type {Array<SimulationOutput>}
     * @memberof ListSimulationsResponseContent
     */
    facilitySimulationList: Array<SimulationOutput>;
}


/**
 * Check if a given object implements the ListSimulationsResponseContent interface.
 */
export function instanceOfListSimulationsResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "facilitySimulationList" in value;

    return isInstance;
}

export function ListSimulationsResponseContentFromJSON(json: any): ListSimulationsResponseContent {
    return ListSimulationsResponseContentFromJSONTyped(json, false);
}

export function ListSimulationsResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListSimulationsResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageToken': !exists(json, 'pageToken') ? undefined : json['pageToken'],
        'facilitySimulationList': ((json['facilitySimulationList'] as Array<any>).map(SimulationOutputFromJSON)),
    };
}

export function ListSimulationsResponseContentToJSON(value?: ListSimulationsResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageToken': value.pageToken,
        'facilitySimulationList': ((value.facilitySimulationList as Array<any>).map(SimulationOutputToJSON)),
    };
}

