/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FacilitiesListOutput } from './FacilitiesListOutput';
import {
    FacilitiesListOutputFromJSON,
    FacilitiesListOutputFromJSONTyped,
    FacilitiesListOutputToJSON,
} from './FacilitiesListOutput';

/**
 * 
 * @export
 * @interface ListFacilitiesResponseContent
 */
export interface ListFacilitiesResponseContent {
    /**
     * Token to continue paginating for additional channel templates
     * 
     * If more channel templates exist beyond those returned in the current response, this
     * token can be used in a subsequent request to retrieve the next set of channel templates.
     * If no further templates are available, this value may be omitted.
     * @type {string}
     * @memberof ListFacilitiesResponseContent
     */
    pageToken?: string;
    /**
     * 
     * @type {Array<FacilitiesListOutput>}
     * @memberof ListFacilitiesResponseContent
     */
    facilitiesList: Array<FacilitiesListOutput>;
}


/**
 * Check if a given object implements the ListFacilitiesResponseContent interface.
 */
export function instanceOfListFacilitiesResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "facilitiesList" in value;

    return isInstance;
}

export function ListFacilitiesResponseContentFromJSON(json: any): ListFacilitiesResponseContent {
    return ListFacilitiesResponseContentFromJSONTyped(json, false);
}

export function ListFacilitiesResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListFacilitiesResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageToken': !exists(json, 'pageToken') ? undefined : json['pageToken'],
        'facilitiesList': ((json['facilitiesList'] as Array<any>).map(FacilitiesListOutputFromJSON)),
    };
}

export function ListFacilitiesResponseContentToJSON(value?: ListFacilitiesResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageToken': value.pageToken,
        'facilitiesList': ((value.facilitiesList as Array<any>).map(FacilitiesListOutputToJSON)),
    };
}

