/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FacilityFields } from './FacilityFields';
import {
    FacilityFieldsFromJSON,
    FacilityFieldsFromJSONTyped,
    FacilityFieldsToJSON,
} from './FacilityFields';

/**
 * 
 * @export
 * @interface AddFacilityRequestContent
 */
export interface AddFacilityRequestContent {
    /**
     * 
     * @type {FacilityFields}
     * @memberof AddFacilityRequestContent
     */
    facility?: FacilityFields;
}


/**
 * Check if a given object implements the AddFacilityRequestContent interface.
 */
export function instanceOfAddFacilityRequestContent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AddFacilityRequestContentFromJSON(json: any): AddFacilityRequestContent {
    return AddFacilityRequestContentFromJSONTyped(json, false);
}

export function AddFacilityRequestContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddFacilityRequestContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'facility': !exists(json, 'facility') ? undefined : FacilityFieldsFromJSON(json['facility']),
    };
}

export function AddFacilityRequestContentToJSON(value?: AddFacilityRequestContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'facility': FacilityFieldsToJSON(value.facility),
    };
}

