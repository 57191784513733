import {
  Autosuggest,
  Button,
  Container,
  Header,
  Select,
  SpaceBetween,
  Spinner,
} from "@cloudscape-design/components";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useQueryClient } from "@tanstack/react-query";
import {
  DashboardOutput,
  FacilitiesListOutput,
  MapFacilityToDashboardRequest,
  useListFacilities,
  useListFacilityDashboards,
  useListNetworkLevelDashboards,
  useMapFacilityToDashboard,
} from "api-typescript-react-query-hooks";
import { useEffect, useState } from "react";
import { setError, setSuccess } from "../../../app/reducers/appSlice";
import { useAppDispatch } from "../../../app/store";
import { PageWrapper } from "../../../layout/PageWrapper";
import { errorUnknown } from "../../../utils/errorlog";
import { CompactDashboardList } from "../components/CompactDashboardList";

export const MapDashboardFacility = () => {
  const [facilities, setFacilities] = useState<FacilitiesListOutput[]>([]);
  const [dashboards, setDashboards] = useState<DashboardOutput[]>([]);
  const [selectedFacility, setSelectedFacility] =
    useState<OptionDefinition | null>(null);
  const [selectedDashboard, setSelectedDashboard] = useState<
    OptionDefinition | undefined
  >();
  const dispatch = useAppDispatch();

  const {
    data: facilitiesResult,
    isFetching: facilitiesIsFetching,
    hasNextPage: facilitiesHasNextPage,
    fetchNextPage: facilitiesFetchNextPage,
  } = useListFacilities({});

  const {
    data: dashboardsResult,
    isFetching: dashboardsIsFetching,
    hasNextPage: dashboardsHasNextPage,
    fetchNextPage: dashboardsFetchNextPage,
  } = useListNetworkLevelDashboards({});

  const { data: facilityDashboardsResult } = useListFacilityDashboards(
    { facilityId: selectedFacility?.value! },
    { enabled: !!selectedFacility },
  );

  const queryClient = useQueryClient();

  const mapDashboardMutation = useMapFacilityToDashboard({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["listFacilityDashboards"],
      });
    },
  });

  const facilitiesNextPage = () => {
    facilitiesFetchNextPage().catch((err) => {
      errorUnknown(err);
    });
  };

  const dashboardsNextPage = () => {
    dashboardsFetchNextPage().catch((err) => {
      errorUnknown(err);
    });
  };

  useEffect(() => {
    if (!facilitiesIsFetching && facilitiesHasNextPage) {
      facilitiesNextPage();
      const newFacilities = facilitiesResult!.pages.flatMap((f) => {
        return f.facilitiesList;
      });
      setFacilities(newFacilities);
    }
  }, [facilitiesResult]);

  useEffect(() => {
    if (!dashboardsIsFetching && dashboardsHasNextPage) {
      dashboardsNextPage();
      const newDashboards = dashboardsResult!.pages.flatMap((d) => {
        return d.networkLevelDashboardList;
      });
      setDashboards(newDashboards);
    }
  }, [dashboardsResult]);

  const facilitiesOptions = facilities.map((facility) => {
    return { label: facility.name, value: facility.facilityId };
  });

  const dashboardsOptions = dashboards.map((dashboard) => {
    return {
      value: dashboard.dashboardId,
      label: dashboard.title,
      tags: [
        dashboard.description || "",
        dashboard.dashboardType,
        dashboard.dashboardReportsType,
      ],
    };
  });

  const assignedDashboards = facilityDashboardsResult?.pages.flatMap((page) => {
    return page.facilityDashboardList;
  });

  const mapButtonEnabled = (): boolean => {
    return !!selectedDashboard && !!selectedFacility;
  };

  const addDashboard = () => {
    const mapDashboardFacilityRequest: MapFacilityToDashboardRequest = {
      facilityId: selectedFacility?.value!,
      dashboardId: selectedDashboard?.value!,
    };

    mapDashboardMutation
      .mutateAsync(mapDashboardFacilityRequest)
      .then(() => setSuccess("Dashboard successfully mapped to facility"))
      .catch(() =>
        dispatch(
          setError(
            "Unable to map user from facility. Please try again or contact your administrator.",
          ),
        ),
      );
  };

  const unassignDashboard = (index: number) => {
    dispatch(
      setError(
        `Sorry, the functionality to remove dashboards is not yet implemented (deleting element with index ${index})`,
      ),
    );
  };

  if (
    facilitiesIsFetching ||
    facilitiesHasNextPage ||
    dashboardsIsFetching ||
    dashboardsHasNextPage
  )
    return <Spinner />;

  return (
    <PageWrapper
      title="Administrator: Map users to facilities"
      description="Manage which users have access to which facilities"
    >
      <SpaceBetween direction="vertical" size="l">
        <Container header={<Header>Select a facility</Header>}>
          <Select
            selectedOption={selectedFacility}
            onChange={({ detail }) =>
              setSelectedFacility(detail.selectedOption)
            }
            options={facilitiesOptions}
          />
        </Container>
        <Container
          header={<Header>Select users that can access this facility</Header>}
        >
          <SpaceBetween direction="vertical" size="l">
            <Autosuggest
              onSelect={({ detail }) =>
                setSelectedDashboard(detail.selectedOption)
              }
              value={selectedDashboard?.label || ""}
              options={dashboardsOptions}
              placeholder="Enter a dashboard name"
              loadingText="Search dashboards..."
              empty="No matches found"
            />
            <Button
              variant="primary"
              disabled={!mapButtonEnabled()}
              onClick={() => addDashboard()}
            >
              Map Dashboard to this Facility
            </Button>
          </SpaceBetween>
        </Container>
        <Container
          header={
            <Header>Current dashboards associated to this facility</Header>
          }
        >
          {selectedFacility
            ? assignedDashboards && (
                <CompactDashboardList
                  dashboards={assignedDashboards}
                  deleteCallback={unassignDashboard}
                />
              )
            : "Select a Facility to see its assigned users"}
        </Container>
      </SpaceBetween>
    </PageWrapper>
  );
};
