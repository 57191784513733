/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DashboardReportsType } from './DashboardReportsType';
import {
    DashboardReportsTypeFromJSON,
    DashboardReportsTypeFromJSONTyped,
    DashboardReportsTypeToJSON,
} from './DashboardReportsType';
import type { DashboardType } from './DashboardType';
import {
    DashboardTypeFromJSON,
    DashboardTypeFromJSONTyped,
    DashboardTypeToJSON,
} from './DashboardType';
import type { DataSourceObject } from './DataSourceObject';
import {
    DataSourceObjectFromJSON,
    DataSourceObjectFromJSONTyped,
    DataSourceObjectToJSON,
} from './DataSourceObject';
import type { DataSourceType } from './DataSourceType';
import {
    DataSourceTypeFromJSON,
    DataSourceTypeFromJSONTyped,
    DataSourceTypeToJSON,
} from './DataSourceType';

/**
 * 
 * @export
 * @interface DashboardFields
 */
export interface DashboardFields {
    /**
     * 
     * @type {string}
     * @memberof DashboardFields
     */
    title: string;
    /**
     * 
     * @type {DashboardType}
     * @memberof DashboardFields
     */
    dashboardType: DashboardType;
    /**
     * 
     * @type {DashboardReportsType}
     * @memberof DashboardFields
     */
    dashboardReportsType: DashboardReportsType;
    /**
     * 
     * @type {DataSourceType}
     * @memberof DashboardFields
     */
    dataSourceType: DataSourceType;
    /**
     * 
     * @type {DataSourceObject}
     * @memberof DashboardFields
     */
    dataSourceObject: DataSourceObject;
    /**
     * 
     * @type {string}
     * @memberof DashboardFields
     */
    facilityId?: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardFields
     */
    description?: string;
}


/**
 * Check if a given object implements the DashboardFields interface.
 */
export function instanceOfDashboardFields(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "dashboardType" in value;
    isInstance = isInstance && "dashboardReportsType" in value;
    isInstance = isInstance && "dataSourceType" in value;
    isInstance = isInstance && "dataSourceObject" in value;

    return isInstance;
}

export function DashboardFieldsFromJSON(json: any): DashboardFields {
    return DashboardFieldsFromJSONTyped(json, false);
}

export function DashboardFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardFields {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'dashboardType': DashboardTypeFromJSON(json['dashboardType']),
        'dashboardReportsType': DashboardReportsTypeFromJSON(json['dashboardReportsType']),
        'dataSourceType': DataSourceTypeFromJSON(json['dataSourceType']),
        'dataSourceObject': DataSourceObjectFromJSON(json['dataSourceObject']),
        'facilityId': !exists(json, 'facilityId') ? undefined : json['facilityId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function DashboardFieldsToJSON(value?: DashboardFields | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'dashboardType': DashboardTypeToJSON(value.dashboardType),
        'dashboardReportsType': DashboardReportsTypeToJSON(value.dashboardReportsType),
        'dataSourceType': DataSourceTypeToJSON(value.dataSourceType),
        'dataSourceObject': DataSourceObjectToJSON(value.dataSourceObject),
        'facilityId': value.facilityId,
        'description': value.description,
    };
}

