// Import models
import type {
  AddDashboardRequestContent,
  AddDashboardResponseContent,
  AddFacilityRequestContent,
  AddFacilityResponseContent,
  AddTagRequestContent,
  AddTagResponseContent,
  AddUserBookmarkRequestContent,
  AddUserFacilityRequestContent,
  AlertSourceType,
  BadRequestErrorResponseContent,
  BookmarkObjectType,
  CreateSchematicRequestContent,
  CreateSchematicResponseContent,
  DashboardReportsType,
  FacilityStatusEnum,
  GenerateDashboardEmbedUrlRequestContent,
  GenerateDashboardEmbedUrlResponseContent,
  GetDashboardResponseContent,
  GetDashboardTagsResponseContent,
  GetFacilityResponseContent,
  GetFacilityStatusResponseContent,
  GetFacilityTagsResponseContent,
  GetSimulationCredentialsResponseContent,
  GetSimulationResponseContent,
  GetSimulationResultsResponseContent,
  GetSimulationStatusResponseContent,
  InternalFailureErrorResponseContent,
  ListAlertsResponseContent,
  ListFacilitiesResponseContent,
  ListFacilitiesStatusResponseContent,
  ListFacilityDashboardsResponseContent,
  ListFacilityUsersResponseContent,
  ListNetworkLevelDashboardsResponseContent,
  ListSchematicsResponseContent,
  ListSimulationModelsResponseContent,
  ListSimulationsResponseContent,
  ListTagsResponseContent,
  ListUserBookmarksResponseContent,
  ListUserFacilitiesResponseContent,
  NewSimulationRequestContent,
  NewSimulationResponseContent,
  NotAuthorizedErrorResponseContent,
  NotFoundErrorResponseContent,
  SearchResourcesResponseContent,
  SearchUsersResponseContent,
  UpdateDashboardRequestContent,
  UpdateFacilityRequestContent,
  UpdateSchematicRequestContent,
  UploadPictureResponseContent,
  
} from '../models';

// Import request parameter interfaces
import {
    AddDashboardRequest,
    AddFacilityRequest,
    AddTagRequest,
    AddUserBookmarkRequest,
    AddUserFacilityRequest,
    AssociateDashboardTagRequest,
    AssociateFacilityTagRequest,
    CreateSchematicRequest,
    DeleteDashboardRequest,
    DeleteFacilityRequest,
    DeletePictureRequest,
    DeleteSchematicRequest,
    DeleteTagRequest,
    DeleteUserBookmarkRequest,
    DeleteUserFacilityRequest,
    DisassociateDashboardTagRequest,
    DisassociateFacilityTagRequest,
    GenerateDashboardEmbedUrlRequest,
    GetDashboardRequest,
    GetDashboardTagsRequest,
    GetFacilityRequest,
    GetFacilityStatusRequest,
    GetFacilityTagsRequest,
    GetSimulationRequest,
    
    GetSimulationResultsRequest,
    GetSimulationStatusRequest,
    ListAlertsRequest,
    ListFacilitiesRequest,
    
    ListFacilityDashboardsRequest,
    ListFacilityUsersRequest,
    ListNetworkLevelDashboardsRequest,
    ListSchematicsRequest,
    ListSimulationModelsRequest,
    ListSimulationsRequest,
    
    
    ListUserFacilitiesRequest,
    MapFacilityToDashboardRequest,
    NewSimulationRequest,
    SearchResourcesRequest,
    SearchUsersRequest,
    UpdateDashboardRequest,
    UpdateFacilityRequest,
    UpdateSchematicRequest,
    UploadPictureRequest,
    
    
} from '..';

import { ResponseError } from '../runtime';
import { DefaultApi } from './DefaultApi';
import { DefaultApiDefaultContext } from "./DefaultApiClientProvider";

import {
    useQuery,
    UseQueryResult,
    UseQueryOptions,
    useInfiniteQuery,
    UseInfiniteQueryResult,
    UseInfiniteQueryOptions,
    useMutation,
    UseMutationOptions,
    UseMutationResult
} from "@tanstack/react-query";
import { createContext, useContext } from "react";

/**
 * Context for the API client used by the hooks.
 */
export const DefaultApiClientContext = createContext<DefaultApi | undefined>(undefined);

const NO_API_ERROR = new Error(`DefaultApi client missing. Please ensure you have instantiated the DefaultApiClientProvider with a client instance.`);


/**
 * useMutation hook for the AddDashboard operation
 */
export const useAddDashboard = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<AddDashboardResponseContent, TError, AddDashboardRequest>, 'mutationFn'>
): UseMutationResult<AddDashboardResponseContent, TError, AddDashboardRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: AddDashboardRequest) => api.addDashboard(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the AddFacility operation
 */
export const useAddFacility = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<AddFacilityResponseContent, TError, AddFacilityRequest>, 'mutationFn'>
): UseMutationResult<AddFacilityResponseContent, TError, AddFacilityRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: AddFacilityRequest) => api.addFacility(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the AddTag operation
 */
export const useAddTag = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<AddTagResponseContent, TError, AddTagRequest>, 'mutationFn'>
): UseMutationResult<AddTagResponseContent, TError, AddTagRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: AddTagRequest) => api.addTag(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the AddUserBookmark operation
 */
export const useAddUserBookmark = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, AddUserBookmarkRequest>, 'mutationFn'>
): UseMutationResult<void, TError, AddUserBookmarkRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: AddUserBookmarkRequest) => api.addUserBookmark(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the AddUserFacility operation
 */
export const useAddUserFacility = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, AddUserFacilityRequest>, 'mutationFn'>
): UseMutationResult<void, TError, AddUserFacilityRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: AddUserFacilityRequest) => api.addUserFacility(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the AssociateDashboardTag operation
 */
export const useAssociateDashboardTag = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, AssociateDashboardTagRequest>, 'mutationFn'>
): UseMutationResult<void, TError, AssociateDashboardTagRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: AssociateDashboardTagRequest) => api.associateDashboardTag(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the AssociateFacilityTag operation
 */
export const useAssociateFacilityTag = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, AssociateFacilityTagRequest>, 'mutationFn'>
): UseMutationResult<void, TError, AssociateFacilityTagRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: AssociateFacilityTagRequest) => api.associateFacilityTag(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the CreateSchematic operation
 */
export const useCreateSchematic = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<CreateSchematicResponseContent, TError, CreateSchematicRequest>, 'mutationFn'>
): UseMutationResult<CreateSchematicResponseContent, TError, CreateSchematicRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: CreateSchematicRequest) => api.createSchematic(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the DeleteDashboard operation
 */
export const useDeleteDashboard = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, DeleteDashboardRequest>, 'mutationFn'>
): UseMutationResult<void, TError, DeleteDashboardRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: DeleteDashboardRequest) => api.deleteDashboard(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the DeleteFacility operation
 */
export const useDeleteFacility = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, DeleteFacilityRequest>, 'mutationFn'>
): UseMutationResult<void, TError, DeleteFacilityRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: DeleteFacilityRequest) => api.deleteFacility(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the DeletePicture operation
 */
export const useDeletePicture = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, DeletePictureRequest>, 'mutationFn'>
): UseMutationResult<void, TError, DeletePictureRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: DeletePictureRequest) => api.deletePicture(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the DeleteSchematic operation
 */
export const useDeleteSchematic = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, DeleteSchematicRequest>, 'mutationFn'>
): UseMutationResult<void, TError, DeleteSchematicRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: DeleteSchematicRequest) => api.deleteSchematic(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the DeleteTag operation
 */
export const useDeleteTag = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, DeleteTagRequest>, 'mutationFn'>
): UseMutationResult<void, TError, DeleteTagRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: DeleteTagRequest) => api.deleteTag(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the DeleteUserBookmark operation
 */
export const useDeleteUserBookmark = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, DeleteUserBookmarkRequest>, 'mutationFn'>
): UseMutationResult<void, TError, DeleteUserBookmarkRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: DeleteUserBookmarkRequest) => api.deleteUserBookmark(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the DeleteUserFacility operation
 */
export const useDeleteUserFacility = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, DeleteUserFacilityRequest>, 'mutationFn'>
): UseMutationResult<void, TError, DeleteUserFacilityRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: DeleteUserFacilityRequest) => api.deleteUserFacility(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the DisassociateDashboardTag operation
 */
export const useDisassociateDashboardTag = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, DisassociateDashboardTagRequest>, 'mutationFn'>
): UseMutationResult<void, TError, DisassociateDashboardTagRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: DisassociateDashboardTagRequest) => api.disassociateDashboardTag(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the DisassociateFacilityTag operation
 */
export const useDisassociateFacilityTag = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, DisassociateFacilityTagRequest>, 'mutationFn'>
): UseMutationResult<void, TError, DisassociateFacilityTagRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: DisassociateFacilityTagRequest) => api.disassociateFacilityTag(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the GenerateDashboardEmbedUrl operation
 */
export const useGenerateDashboardEmbedUrl = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<GenerateDashboardEmbedUrlResponseContent, TError, GenerateDashboardEmbedUrlRequest>, 'mutationFn'>
): UseMutationResult<GenerateDashboardEmbedUrlResponseContent, TError, GenerateDashboardEmbedUrlRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: GenerateDashboardEmbedUrlRequest) => api.generateDashboardEmbedUrl(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useQuery hook for the GetDashboard operation
 */
export const useGetDashboard = <TError = ResponseError>(
    params: GetDashboardRequest,
    options?: Omit<UseQueryOptions<GetDashboardResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetDashboardResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getDashboard", params], () => api.getDashboard(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the GetDashboardTags operation
 */
export const useGetDashboardTags = <TError = ResponseError>(
    params: GetDashboardTagsRequest,
    options?: Omit<UseQueryOptions<GetDashboardTagsResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetDashboardTagsResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getDashboardTags", params], () => api.getDashboardTags(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the GetFacility operation
 */
export const useGetFacility = <TError = ResponseError>(
    params: GetFacilityRequest,
    options?: Omit<UseQueryOptions<GetFacilityResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetFacilityResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getFacility", params], () => api.getFacility(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the GetFacilityStatus operation
 */
export const useGetFacilityStatus = <TError = ResponseError>(
    params: GetFacilityStatusRequest,
    options?: Omit<UseQueryOptions<GetFacilityStatusResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetFacilityStatusResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getFacilityStatus", params], () => api.getFacilityStatus(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the GetFacilityTags operation
 */
export const useGetFacilityTags = <TError = ResponseError>(
    params: GetFacilityTagsRequest,
    options?: Omit<UseQueryOptions<GetFacilityTagsResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetFacilityTagsResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getFacilityTags", params], () => api.getFacilityTags(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the GetSimulation operation
 */
export const useGetSimulation = <TError = ResponseError>(
    params: GetSimulationRequest,
    options?: Omit<UseQueryOptions<GetSimulationResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetSimulationResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getSimulation", params], () => api.getSimulation(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the GetSimulationCredentials operation
 */
export const useGetSimulationCredentials = <TError = ResponseError>(

    options?: Omit<UseQueryOptions<GetSimulationCredentialsResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetSimulationCredentialsResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getSimulationCredentials"], () => api.getSimulationCredentials(), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the GetSimulationResults operation
 */
export const useGetSimulationResults = <TError = ResponseError>(
    params: GetSimulationResultsRequest,
    options?: Omit<UseQueryOptions<GetSimulationResultsResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetSimulationResultsResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getSimulationResults", params], () => api.getSimulationResults(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the GetSimulationStatus operation
 */
export const useGetSimulationStatus = <TError = ResponseError>(
    params: GetSimulationStatusRequest,
    options?: Omit<UseQueryOptions<GetSimulationStatusResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetSimulationStatusResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getSimulationStatus", params], () => api.getSimulationStatus(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useInfiniteQuery hook for the ListAlerts operation
 */
export const useListAlerts = <TError = ResponseError>(
    params: ListAlertsRequest,
    options?: Omit<UseInfiniteQueryOptions<ListAlertsResponseContent, TError>, 'queryKey' | 'queryFn' | 'getNextPageParam'>
): UseInfiniteQueryResult<ListAlertsResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useInfiniteQuery(["listAlerts", params], ({ pageParam }) => api.listAlerts({ ...params, token: pageParam }), {
        getNextPageParam: (response) => response.token,
        context: DefaultApiDefaultContext,
        ...options as any,
    });
};

/**
 * useInfiniteQuery hook for the ListFacilities operation
 */
export const useListFacilities = <TError = ResponseError>(
    params: ListFacilitiesRequest,
    options?: Omit<UseInfiniteQueryOptions<ListFacilitiesResponseContent, TError>, 'queryKey' | 'queryFn' | 'getNextPageParam'>
): UseInfiniteQueryResult<ListFacilitiesResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useInfiniteQuery(["listFacilities", params], ({ pageParam }) => api.listFacilities({ ...params, pageToken: pageParam }), {
        getNextPageParam: (response) => response.pageToken,
        context: DefaultApiDefaultContext,
        ...options as any,
    });
};

/**
 * useQuery hook for the ListFacilitiesStatus operation
 */
export const useListFacilitiesStatus = <TError = ResponseError>(

    options?: Omit<UseQueryOptions<ListFacilitiesStatusResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<ListFacilitiesStatusResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["listFacilitiesStatus"], () => api.listFacilitiesStatus(), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useInfiniteQuery hook for the ListFacilityDashboards operation
 */
export const useListFacilityDashboards = <TError = ResponseError>(
    params: ListFacilityDashboardsRequest,
    options?: Omit<UseInfiniteQueryOptions<ListFacilityDashboardsResponseContent, TError>, 'queryKey' | 'queryFn' | 'getNextPageParam'>
): UseInfiniteQueryResult<ListFacilityDashboardsResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useInfiniteQuery(["listFacilityDashboards", params], ({ pageParam }) => api.listFacilityDashboards({ ...params, pageToken: pageParam }), {
        getNextPageParam: (response) => response.pageToken,
        context: DefaultApiDefaultContext,
        ...options as any,
    });
};

/**
 * useQuery hook for the ListFacilityUsers operation
 */
export const useListFacilityUsers = <TError = ResponseError>(
    params: ListFacilityUsersRequest,
    options?: Omit<UseQueryOptions<ListFacilityUsersResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<ListFacilityUsersResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["listFacilityUsers", params], () => api.listFacilityUsers(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useInfiniteQuery hook for the ListNetworkLevelDashboards operation
 */
export const useListNetworkLevelDashboards = <TError = ResponseError>(
    params: ListNetworkLevelDashboardsRequest,
    options?: Omit<UseInfiniteQueryOptions<ListNetworkLevelDashboardsResponseContent, TError>, 'queryKey' | 'queryFn' | 'getNextPageParam'>
): UseInfiniteQueryResult<ListNetworkLevelDashboardsResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useInfiniteQuery(["listNetworkLevelDashboards", params], ({ pageParam }) => api.listNetworkLevelDashboards({ ...params, pageToken: pageParam }), {
        getNextPageParam: (response) => response.pageToken,
        context: DefaultApiDefaultContext,
        ...options as any,
    });
};

/**
 * useInfiniteQuery hook for the ListSchematics operation
 */
export const useListSchematics = <TError = ResponseError>(
    params: ListSchematicsRequest,
    options?: Omit<UseInfiniteQueryOptions<ListSchematicsResponseContent, TError>, 'queryKey' | 'queryFn' | 'getNextPageParam'>
): UseInfiniteQueryResult<ListSchematicsResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useInfiniteQuery(["listSchematics", params], ({ pageParam }) => api.listSchematics({ ...params, token: pageParam }), {
        getNextPageParam: (response) => response.token,
        context: DefaultApiDefaultContext,
        ...options as any,
    });
};

/**
 * useInfiniteQuery hook for the ListSimulationModels operation
 */
export const useListSimulationModels = <TError = ResponseError>(
    params: ListSimulationModelsRequest,
    options?: Omit<UseInfiniteQueryOptions<ListSimulationModelsResponseContent, TError>, 'queryKey' | 'queryFn' | 'getNextPageParam'>
): UseInfiniteQueryResult<ListSimulationModelsResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useInfiniteQuery(["listSimulationModels", params], ({ pageParam }) => api.listSimulationModels({ ...params, pageToken: pageParam }), {
        getNextPageParam: (response) => response.pageToken,
        context: DefaultApiDefaultContext,
        ...options as any,
    });
};

/**
 * useInfiniteQuery hook for the ListSimulations operation
 */
export const useListSimulations = <TError = ResponseError>(
    params: ListSimulationsRequest,
    options?: Omit<UseInfiniteQueryOptions<ListSimulationsResponseContent, TError>, 'queryKey' | 'queryFn' | 'getNextPageParam'>
): UseInfiniteQueryResult<ListSimulationsResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useInfiniteQuery(["listSimulations", params], ({ pageParam }) => api.listSimulations({ ...params, pageToken: pageParam }), {
        getNextPageParam: (response) => response.pageToken,
        context: DefaultApiDefaultContext,
        ...options as any,
    });
};

/**
 * useQuery hook for the ListTags operation
 */
export const useListTags = <TError = ResponseError>(

    options?: Omit<UseQueryOptions<ListTagsResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<ListTagsResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["listTags"], () => api.listTags(), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the ListUserBookmarks operation
 */
export const useListUserBookmarks = <TError = ResponseError>(

    options?: Omit<UseQueryOptions<ListUserBookmarksResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<ListUserBookmarksResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["listUserBookmarks"], () => api.listUserBookmarks(), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the ListUserFacilities operation
 */
export const useListUserFacilities = <TError = ResponseError>(
    params: ListUserFacilitiesRequest,
    options?: Omit<UseQueryOptions<ListUserFacilitiesResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<ListUserFacilitiesResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["listUserFacilities", params], () => api.listUserFacilities(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useMutation hook for the MapFacilityToDashboard operation
 */
export const useMapFacilityToDashboard = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, MapFacilityToDashboardRequest>, 'mutationFn'>
): UseMutationResult<void, TError, MapFacilityToDashboardRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: MapFacilityToDashboardRequest) => api.mapFacilityToDashboard(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the NewSimulation operation
 */
export const useNewSimulation = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<NewSimulationResponseContent, TError, NewSimulationRequest>, 'mutationFn'>
): UseMutationResult<NewSimulationResponseContent, TError, NewSimulationRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: NewSimulationRequest) => api.newSimulation(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useInfiniteQuery hook for the SearchResources operation
 */
export const useSearchResources = <TError = ResponseError>(
    params: SearchResourcesRequest,
    options?: Omit<UseInfiniteQueryOptions<SearchResourcesResponseContent, TError>, 'queryKey' | 'queryFn' | 'getNextPageParam'>
): UseInfiniteQueryResult<SearchResourcesResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useInfiniteQuery(["searchResources", params], ({ pageParam }) => api.searchResources({ ...params, pageToken: pageParam }), {
        getNextPageParam: (response) => response.nextToken,
        context: DefaultApiDefaultContext,
        ...options as any,
    });
};

/**
 * useInfiniteQuery hook for the SearchUsers operation
 */
export const useSearchUsers = <TError = ResponseError>(
    params: SearchUsersRequest,
    options?: Omit<UseInfiniteQueryOptions<SearchUsersResponseContent, TError>, 'queryKey' | 'queryFn' | 'getNextPageParam'>
): UseInfiniteQueryResult<SearchUsersResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useInfiniteQuery(["searchUsers", params], ({ pageParam }) => api.searchUsers({ ...params, pageToken: pageParam }), {
        getNextPageParam: (response) => response.nextToken,
        context: DefaultApiDefaultContext,
        ...options as any,
    });
};

/**
 * useMutation hook for the UpdateDashboard operation
 */
export const useUpdateDashboard = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, UpdateDashboardRequest>, 'mutationFn'>
): UseMutationResult<void, TError, UpdateDashboardRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: UpdateDashboardRequest) => api.updateDashboard(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the UpdateFacility operation
 */
export const useUpdateFacility = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, UpdateFacilityRequest>, 'mutationFn'>
): UseMutationResult<void, TError, UpdateFacilityRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: UpdateFacilityRequest) => api.updateFacility(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the UpdateSchematic operation
 */
export const useUpdateSchematic = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, UpdateSchematicRequest>, 'mutationFn'>
): UseMutationResult<void, TError, UpdateSchematicRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: UpdateSchematicRequest) => api.updateSchematic(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the UploadPicture operation
 */
export const useUploadPicture = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<UploadPictureResponseContent, TError, UploadPictureRequest>, 'mutationFn'>
): UseMutationResult<UploadPictureResponseContent, TError, UploadPictureRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: UploadPictureRequest) => api.uploadPicture(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

