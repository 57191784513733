/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FacilityStatus } from './FacilityStatus';
import {
    FacilityStatusFromJSON,
    FacilityStatusFromJSONTyped,
    FacilityStatusToJSON,
} from './FacilityStatus';

/**
 * 
 * @export
 * @interface GetFacilityStatusResponseContent
 */
export interface GetFacilityStatusResponseContent {
    /**
     * 
     * @type {FacilityStatus}
     * @memberof GetFacilityStatusResponseContent
     */
    status: FacilityStatus;
}


/**
 * Check if a given object implements the GetFacilityStatusResponseContent interface.
 */
export function instanceOfGetFacilityStatusResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function GetFacilityStatusResponseContentFromJSON(json: any): GetFacilityStatusResponseContent {
    return GetFacilityStatusResponseContentFromJSONTyped(json, false);
}

export function GetFacilityStatusResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFacilityStatusResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': FacilityStatusFromJSON(json['status']),
    };
}

export function GetFacilityStatusResponseContentToJSON(value?: GetFacilityStatusResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': FacilityStatusToJSON(value.status),
    };
}

