import {
  Box,
  Button,
  ExpandableSection,
  Header,
  LineChart,
  Modal,
  SpaceBetween,
  Spinner,
  Table,
} from "@cloudscape-design/components";
import {
  SimulationOutput,
  useGetSimulationResults,
} from "api-typescript-react-query-hooks";

interface SimulationDetailsModalProps {
  visible: boolean;
  setVisible: (state: boolean) => void;
  simulation?: SimulationOutput;
}

interface Point {
  X: number;
  Y: number;
}

export const SimulationDetailsModal = ({
  visible,
  setVisible,
  simulation,
}: SimulationDetailsModalProps): JSX.Element => {
  if (!simulation) {
    return <></>;
  }

  const { facilityId, simulationId, modelId, modelName, name } = simulation;
  const { data: results, isLoading } = useGetSimulationResults({
    facilityId,
    simulationId,
    modelId,
  });

  const timeseries = results?.result?.timeseries.map(
    (ts: { entity: string; data: Point[] }) => {
      const transformedData = ts.data.map((point: Point) => {
        return {
          x: point.X,
          y: point.Y,
        };
      });
      return {
        title: ts.entity,
        type: "line",
        data: transformedData,
      };
    },
  );

  return (
    <Modal
      onDismiss={() => setVisible(false)}
      visible={visible}
      size="max"
      footer={
        <Box float="right">
          <Button variant="primary" onClick={() => setVisible(false)}>
            Close
          </Button>
        </Box>
      }
      header={
        <Header variant="h3">{`Simulation ${name} with model ${modelName}`}</Header>
      }
    >
      {isLoading && (
        <Box padding={{ horizontal: "xl" }}>
          <Spinner variant="disabled" />
          <Box variant="span" color="text-status-inactive" margin={"xs"}>
            Loading results...
          </Box>
        </Box>
      )}
      {!isLoading && (
        <>
          <ExpandableSection
            headerText={`Summary results for ${results?.result.summary.length} entities`}
          >
            {// @ts-ignore
            results?.result.summary.map((entity: Object) => {
              // @ts-ignore
              const id = entity.Id;
              // @ts-ignore
              const { Id: _, ...values } = entity;
              return (
                <ExpandableSection headerText={id}>
                  <Table
                    columnDefinitions={[
                      {
                        id: "variable",
                        header: "Variable name",
                        // @ts-ignore
                        cell: (item) => item[0],
                        sortingField: "name",
                        isRowHeader: true,
                      },
                      {
                        id: "value",
                        header: "Value",
                        // @ts-ignore
                        cell: (item) => item[1],
                        sortingField: "alt",
                      },
                    ]}
                    // @ts-ignore
                    items={Object.entries(values)}
                    enableKeyboardNavigation
                    loadingText="Loading resources"
                    sortingDisabled
                    variant="embedded"
                    empty={
                      <Box
                        margin={{ vertical: "xs" }}
                        textAlign="center"
                        color="inherit"
                      >
                        <SpaceBetween size="m">
                          <b>No summary results</b>
                        </SpaceBetween>
                      </Box>
                    }
                    header={<Header> Simulation Details </Header>}
                  />
                </ExpandableSection>
              );
            })}
          </ExpandableSection>
          <ExpandableSection
            headerText={`Timeseries results for ${results?.result.timeseries.length} entities`}
          >
            <LineChart
              series={timeseries}
              height={300}
              xTitle="Minutes since start"
            />
          </ExpandableSection>
        </>
      )}
    </Modal>
  );
};
