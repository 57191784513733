/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookmarkObject } from './BookmarkObject';
import {
    BookmarkObjectFromJSON,
    BookmarkObjectFromJSONTyped,
    BookmarkObjectToJSON,
} from './BookmarkObject';

/**
 * 
 * @export
 * @interface AddUserBookmarkRequestContent
 */
export interface AddUserBookmarkRequestContent {
    /**
     * 
     * @type {BookmarkObject}
     * @memberof AddUserBookmarkRequestContent
     */
    bookmark: BookmarkObject;
}


/**
 * Check if a given object implements the AddUserBookmarkRequestContent interface.
 */
export function instanceOfAddUserBookmarkRequestContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "bookmark" in value;

    return isInstance;
}

export function AddUserBookmarkRequestContentFromJSON(json: any): AddUserBookmarkRequestContent {
    return AddUserBookmarkRequestContentFromJSONTyped(json, false);
}

export function AddUserBookmarkRequestContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddUserBookmarkRequestContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bookmark': BookmarkObjectFromJSON(json['bookmark']),
    };
}

export function AddUserBookmarkRequestContentToJSON(value?: AddUserBookmarkRequestContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bookmark': BookmarkObjectToJSON(value.bookmark),
    };
}

