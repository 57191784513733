/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SimulationModelOutput } from './SimulationModelOutput';
import {
    SimulationModelOutputFromJSON,
    SimulationModelOutputFromJSONTyped,
    SimulationModelOutputToJSON,
} from './SimulationModelOutput';

/**
 * 
 * @export
 * @interface ListSimulationModelsResponseContent
 */
export interface ListSimulationModelsResponseContent {
    /**
     * 
     * @type {string}
     * @memberof ListSimulationModelsResponseContent
     */
    pageToken?: string;
    /**
     * 
     * @type {Array<SimulationModelOutput>}
     * @memberof ListSimulationModelsResponseContent
     */
    simulationModelsList: Array<SimulationModelOutput>;
}


/**
 * Check if a given object implements the ListSimulationModelsResponseContent interface.
 */
export function instanceOfListSimulationModelsResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "simulationModelsList" in value;

    return isInstance;
}

export function ListSimulationModelsResponseContentFromJSON(json: any): ListSimulationModelsResponseContent {
    return ListSimulationModelsResponseContentFromJSONTyped(json, false);
}

export function ListSimulationModelsResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListSimulationModelsResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageToken': !exists(json, 'pageToken') ? undefined : json['pageToken'],
        'simulationModelsList': ((json['simulationModelsList'] as Array<any>).map(SimulationModelOutputFromJSON)),
    };
}

export function ListSimulationModelsResponseContentToJSON(value?: ListSimulationModelsResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageToken': value.pageToken,
        'simulationModelsList': ((value.simulationModelsList as Array<any>).map(SimulationModelOutputToJSON)),
    };
}

