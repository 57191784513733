import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  DefaultApi,
  DefaultApiClientProvider,
  Configuration,
} from "api-typescript-react-query-hooks";
import { useMemo } from "react";
import { useAuth } from "./app/components/AuthProvider";
import { useConfig } from "./app/components/ConfigProvider";

import { NavigationRoutes } from "./routes/NavigationRoutes";

export const useApiClient = (apiUrl: string, token: string) =>
  useMemo(
    () =>
      new DefaultApi(
        new Configuration({
          basePath: apiUrl,
          fetchApi: window.fetch.bind(window),
          headers: {
            Authorization: `${token}`,
          },
        }),
      ),
    [apiUrl, token],
  );

function App() {
  const config = useConfig();
  const authInfo = useAuth();

  const api = useApiClient(config.data.apiUrl || "", authInfo.token);

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <DefaultApiClientProvider apiClient={api} client={queryClient}>
        <NavigationRoutes />
      </DefaultApiClientProvider>
    </QueryClientProvider>
  );
}

export default App;
