/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimulationInput
 */
export interface SimulationInput {
    /**
     * 
     * @type {string}
     * @memberof SimulationInput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SimulationInput
     */
    modelId: string;
    /**
     * 
     * @type {string}
     * @memberof SimulationInput
     */
    modelName: string;
    /**
     * 
     * @type {any}
     * @memberof SimulationInput
     */
    scenario: any | null;
}


/**
 * Check if a given object implements the SimulationInput interface.
 */
export function instanceOfSimulationInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "modelId" in value;
    isInstance = isInstance && "modelName" in value;
    isInstance = isInstance && "scenario" in value;

    return isInstance;
}

export function SimulationInputFromJSON(json: any): SimulationInput {
    return SimulationInputFromJSONTyped(json, false);
}

export function SimulationInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimulationInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'modelId': json['modelId'],
        'modelName': json['modelName'],
        'scenario': json['scenario'],
    };
}

export function SimulationInputToJSON(value?: SimulationInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'modelId': value.modelId,
        'modelName': value.modelName,
        'scenario': value.scenario,
    };
}

