/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookmarkObjectType } from './BookmarkObjectType';
import {
    BookmarkObjectTypeFromJSON,
    BookmarkObjectTypeFromJSONTyped,
    BookmarkObjectTypeToJSON,
} from './BookmarkObjectType';

/**
 * 
 * @export
 * @interface BookmarkObject
 */
export interface BookmarkObject {
    /**
     * 
     * @type {string}
     * @memberof BookmarkObject
     */
    objectId: string;
    /**
     * 
     * @type {BookmarkObjectType}
     * @memberof BookmarkObject
     */
    objectType: BookmarkObjectType;
    /**
     * 
     * @type {string}
     * @memberof BookmarkObject
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof BookmarkObject
     */
    createdAt?: number;
}


/**
 * Check if a given object implements the BookmarkObject interface.
 */
export function instanceOfBookmarkObject(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "objectId" in value;
    isInstance = isInstance && "objectType" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function BookmarkObjectFromJSON(json: any): BookmarkObject {
    return BookmarkObjectFromJSONTyped(json, false);
}

export function BookmarkObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookmarkObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectId': json['objectId'],
        'objectType': BookmarkObjectTypeFromJSON(json['objectType']),
        'name': json['name'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
    };
}

export function BookmarkObjectToJSON(value?: BookmarkObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objectId': value.objectId,
        'objectType': BookmarkObjectTypeToJSON(value.objectType),
        'name': value.name,
        'createdAt': value.createdAt,
    };
}

