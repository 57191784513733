/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './Location';

/**
 * 
 * @export
 * @interface FacilitiesListOutput
 */
export interface FacilitiesListOutput {
    /**
     * 
     * @type {string}
     * @memberof FacilitiesListOutput
     */
    facilityId: string;
    /**
     * 
     * @type {string}
     * @memberof FacilitiesListOutput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FacilitiesListOutput
     */
    description: string;
    /**
     * 
     * @type {Location}
     * @memberof FacilitiesListOutput
     */
    location: Location;
}


/**
 * Check if a given object implements the FacilitiesListOutput interface.
 */
export function instanceOfFacilitiesListOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "facilityId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "location" in value;

    return isInstance;
}

export function FacilitiesListOutputFromJSON(json: any): FacilitiesListOutput {
    return FacilitiesListOutputFromJSONTyped(json, false);
}

export function FacilitiesListOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): FacilitiesListOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'facilityId': json['facilityId'],
        'name': json['name'],
        'description': json['description'],
        'location': LocationFromJSON(json['location']),
    };
}

export function FacilitiesListOutputToJSON(value?: FacilitiesListOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'facilityId': value.facilityId,
        'name': value.name,
        'description': value.description,
        'location': LocationToJSON(value.location),
    };
}

