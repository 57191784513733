import {
  Button,
  ColumnLayout,
  Container,
  FileUpload,
  Form,
  FormField,
  Header,
  Input,
  Select,
  SpaceBetween,
  Spinner,
} from "@cloudscape-design/components";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AddFacilityRequest,
  AddFacilityResponseContent,
  FacilityFields,
  FacilityFieldsComplete,
  SurfaceUnits,
  UpdateFacilityRequest,
  UploadPictureRequest,
  UploadPictureResponseContent,
  useAddFacility,
  useGetFacility,
  useUpdateFacility,
  useUploadPicture,
} from "api-typescript-react-query-hooks";
import { useEffect, useState } from "react";
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
  FormProvider,
} from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { setError, setSuccess } from "../../../app/reducers/appSlice";
import { useAppDispatch } from "../../../app/store";
import { PageWrapper } from "../../../layout/PageWrapper";
import { keyFromUrl } from "../../../utils/keyFromUrl";
import { uploadToS3 } from "../../../utils/uploadToS3";
import { AddressInput } from "../components/AddressInput";
import { PictureThumbnailGallery } from "../components/PictureThumbnailGallery";
import { StringArrayInput } from "../components/StringArrayInput";

export type FacilityForm = Omit<
  FacilityFields,
  "transportationNearby" | "pointsOfInterestNearby"
> & {
  transportationNearby?: Array<{ content: string }>;
  pointsOfInterestNearby?: Array<{ content: string }>;
};

const validationSchema: Yup.ObjectSchema<FacilityForm> = Yup.object().shape({
  name: Yup.string().required("A name is required"),
  description: Yup.string().max(255),
  location: Yup.object()
    .shape({
      address: Yup.string().required("An address is required"),
      coordinates: Yup.object()
        .shape({
          latitude: Yup.number().required().min(-90).max(90),
          longitude: Yup.number().required().min(-180).max(180),
        })
        .test(
          "not-zero",
          "Coordinates are 0,0, so address is invalid",
          (value) => value.latitude != 0 && value.longitude != 0,
        ),
    })
    .required(),
  area: Yup.object().shape({
    value: Yup.number().required("An area for the facility is required"),
    unit: Yup.string().oneOf(["SQUARE_FEET", "SQUARE_METERS"]).required(),
  }),
  siteContacts: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
      role: Yup.string(),
      email: Yup.string(),
      phone: Yup.string(),
    }),
  ),
  transportationNearby: Yup.array().of(
    Yup.object().shape({ content: Yup.string().required() }),
  ),
  pointsOfInterestNearby: Yup.array().of(
    Yup.object().shape({ content: Yup.string().required() }),
  ),
  pictureUriList: Yup.array().of(Yup.string().required()).optional(),
});

const defaultValues: FacilityFieldsComplete = {
  name: "",
  location: {
    address: "",
    coordinates: {
      longitude: 0,
      latitude: 0,
    },
  },
  createdAt: 0,
  updatedAt: 0,
  facilityId: "",
};

export const EditCreateFacility = () => {
  const { facilityId } = useParams();
  const facility = facilityId
    ? useGetFacility({ facilityId }).data?.facility
    : defaultValues;

  const facilityForm: FacilityForm = {
    ...facility!,
    transportationNearby: facility?.transportationNearby?.map((p) => {
      return { content: p };
    }),
    pointsOfInterestNearby: facility?.pointsOfInterestNearby?.map((p) => {
      return { content: p };
    }),
  };

  const [updating, setUpdating] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [newPictures, setNewPictures] = useState<File[]>([]);

  const [areaUnit, setAreaUnit] = useState<OptionDefinition | undefined>();

  const updateFacilityMutation = useUpdateFacility();
  const newFacilityMutation = useAddFacility();
  const addPictureMutation = useUploadPicture();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    criteriaMode: "all",
    defaultValues: facilityForm,
    mode: "all",
  });

  useEffect(() => {
    methods.reset(facilityForm);
  }, [facility]);

  const {
    fields: siteContactsFields,
    append: siteContactsAppend,
    remove: siteContactsRemove,
  } = useFieldArray({
    control: methods.control,
    name: "siteContacts",
  });

  const formErrors = methods.formState.errors;

  useEffect(() => {
    console.log(formErrors);
  }, [formErrors]);

  const uploadPictures = (id: string) => {
    return Promise.all(
      newPictures.map(async (file: File): Promise<string> => {
        const request: UploadPictureRequest = {
          facilityId: id,
        };

        return addPictureMutation
          .mutateAsync(request)
          .then(
            async (response: UploadPictureResponseContent): Promise<string> => {
              const url: string = response.url;
              const key: string = keyFromUrl(url);
              const fields: { [key: string]: string } = response.fields;

              return uploadToS3(url, fields, file)
                .then(() => {
                  return key;
                })
                .catch((err) => {
                  throw err;
                });
            },
          )
          .catch((err) => {
            dispatch(
              setError(
                `Unable to create URL to upload new picture. Error: ${err}`,
              ),
            );
            throw err;
          });
      }),
    );
  };

  const redirectToFacility = (id: string) => {
    navigate(`/facilities/${id}`);
  };

  const updateFacility = (
    updateFacilityId: string,
    facilityUpdatedFields: FacilityFields,
  ) => {
    const request: UpdateFacilityRequest = {
      facilityId: updateFacilityId,
      updateFacilityRequestContent: { facility: facilityUpdatedFields },
    };
    updateFacilityMutation
      .mutateAsync(request)
      .then(() => {
        uploadPictures(updateFacilityId)
          .then(() => {
            dispatch(setSuccess("Facility data successfully updated"));
          })
          .catch((err) => {
            dispatch(
              setError(
                `Error while uploading pictures. Please try again. Error: ${err}`,
              ),
            );
          })
          .finally(() => redirectToFacility(updateFacilityId));
      })
      .catch((err) =>
        dispatch(
          setError(
            `Unable to update facility. Please try again. Error: ${err}`,
          ),
        ),
      )
      .finally(() => setUpdating(false));
  };

  const onSubmit: SubmitHandler<FacilityForm> = async (data) => {
    setUpdating(true);
    const facilityUpdate: FacilityFields = {
      name: data.name,
      description: data.description,
      location: data.location,
      area: data.area,
      pointsOfInterestNearby: data.pointsOfInterestNearby?.map(
        (p) => p.content,
      ),
      transportationNearby: data.transportationNearby?.map((p) => p.content),
      siteContacts: data.siteContacts,
    };
    if (facilityId) {
      // Update facility
      updateFacility(facilityId, facilityUpdate);
    } else {
      // New facility
      const request: AddFacilityRequest = {
        addFacilityRequestContent: { facility: facilityUpdate },
      };

      newFacilityMutation
        .mutateAsync(request)
        .then(async (response: AddFacilityResponseContent) => {
          dispatch(setSuccess("New Facility successfully created"));
          const newId = response.facilityId;
          uploadPictures(newId)
            .catch((err) => {
              dispatch(
                setError(
                  `Error while uploading pictures. Please try again. Error: ${err}`,
                ),
              );
            })
            .finally(() => redirectToFacility(newId));
        })
        .catch((err) =>
          // TODO This error handling needs to be improved - as it is, if the pic upload fails,
          // it still says that the Facility creation failed, but that's not true.
          dispatch(
            setError(
              `Error while creating facility. Please try again. Error: ${err}`,
            ),
          ),
        )
        .finally(() => setUpdating(false));
    }
  };

  const selectedArea = (unit: string | undefined): OptionDefinition => {
    if (unit == "SQUARE_METERS") {
      return { label: "Square meters", value: unit };
    } else return { label: "Square feet", value: "SQUARE_FEET" };
  };

  if (facilityId && !facility) return <Spinner />;

  const control = methods.control;

  return (
    <PageWrapper
      title={facilityId ? "Edit Facility" : "Create New Facility"}
      description="Enter the facility details, facility profile, taxonomy, and BIM files"
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Form
            actions={
              <Button formAction="submit" variant="primary" loading={updating}>
                {facilityId ? "Update Facility" : "Create Facility"}
              </Button>
            }
          >
            <SpaceBetween direction="vertical" size="l">
              <Container header={<Header>Facility Information</Header>}>
                <SpaceBetween direction="vertical" size="l">
                  <FormField
                    errorText={formErrors.name?.message}
                    label="Facility name"
                  >
                    <Controller
                      control={control}
                      name="name"
                      defaultValue={facility?.name}
                      render={({ field }) => (
                        <Input
                          {...field}
                          ariaRequired
                          inputMode="text"
                          onChange={(e) => field.onChange(e.detail.value)}
                        />
                      )}
                    />
                  </FormField>
                  <FormField label="Description">
                    <Controller
                      control={control}
                      name="description"
                      defaultValue={facility?.description}
                      render={({ field }) => (
                        // @ts-ignore
                        <Input
                          ariaRequired
                          {...field}
                          inputMode="text"
                          onChange={(e) => field.onChange(e.detail.value)}
                        />
                      )}
                    />
                  </FormField>
                  <AddressInput currentLocation={facility?.location} />
                  <SpaceBetween direction="horizontal" size="l">
                    <FormField
                      errorText={formErrors.area?.value?.message}
                      label="Area"
                    >
                      <Controller
                        control={control}
                        name="area.value"
                        defaultValue={facility?.area?.value}
                        render={({ field }) => (
                          // @ts-ignore
                          <Input
                            {...field}
                            onChange={(e) => field.onChange(e.detail.value)}
                            inputMode="numeric"
                          />
                        )}
                      />
                    </FormField>
                    <FormField label="Area units">
                      <Controller
                        control={control}
                        name="area.unit"
                        defaultValue={facility?.area?.unit || "SQUARE_FEET"}
                        render={({ field }) => (
                          <Select
                            {...field}
                            onChange={(e) => {
                              field.value = e.detail.selectedOption
                                .value as SurfaceUnits;
                              setAreaUnit(e.detail.selectedOption);
                            }}
                            selectedOption={
                              areaUnit || selectedArea(facility?.area?.unit)
                            }
                            options={[
                              { label: "Square feet", value: "SQUARE_FEET" },
                              {
                                label: "Square meters",
                                value: "SQUARE_METERS",
                              },
                            ]}
                          />
                        )}
                      />
                    </FormField>
                  </SpaceBetween>
                </SpaceBetween>
              </Container>
              <Container header={<Header>Facility Images</Header>}>
                <SpaceBetween direction="vertical" size="xl">
                  <PictureThumbnailGallery facility={facility} />
                  <FormField
                    label="New picture upload"
                    description="Select picture files to add"
                  >
                    <FileUpload
                      onChange={({ detail }) => setNewPictures(detail.value)}
                      value={newPictures}
                      accept={"png,jpg,jpeg"}
                      i18nStrings={{
                        uploadButtonText: (e) =>
                          e ? "Choose files" : "Choose file",
                        dropzoneText: (e) =>
                          e ? "Drop files to upload" : "Drop file to upload",
                        removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                        limitShowFewer: "Show fewer files",
                        limitShowMore: "Show more files",
                        errorIconAriaLabel: "Error",
                      }}
                      multiple
                      showFileSize
                      showFileThumbnail
                    />
                  </FormField>
                </SpaceBetween>
              </Container>
              <StringArrayInput
                facility={facility}
                fieldName="pointsOfInterestNearby"
                title="Point Of Interest"
              />
              <Container header={<Header>Site Contacts</Header>}>
                <SpaceBetween direction="vertical" size="l">
                  {siteContactsFields.map((item, index) => (
                    <Container
                      header={<Header>Contact {`${index + 1}`}</Header>}
                      key={item.id}
                    >
                      <SpaceBetween direction="vertical" size="l">
                        <ColumnLayout columns={2}>
                          <SpaceBetween direction="vertical" size="m">
                            <FormField label="Name">
                              <Controller
                                control={control}
                                defaultValue={item.name}
                                name={`siteContacts.${index}.name`}
                                render={({ field }) => (
                                  // @ts-ignore
                                  <Input
                                    {...field}
                                    onChange={(e) =>
                                      field.onChange(e.detail.value)
                                    }
                                    inputMode="text"
                                  />
                                )}
                              />
                            </FormField>
                            <FormField label="Role">
                              <Controller
                                control={control}
                                defaultValue={item.role}
                                name={`siteContacts.${index}.role`}
                                render={({ field }) => (
                                  // @ts-ignore
                                  <Input
                                    {...field}
                                    onChange={(e) =>
                                      field.onChange(e.detail.value)
                                    }
                                    inputMode="text"
                                  />
                                )}
                              />
                            </FormField>
                          </SpaceBetween>
                          <SpaceBetween direction="vertical" size="m">
                            <FormField label="Email">
                              <Controller
                                control={control}
                                defaultValue={item.email}
                                name={`siteContacts.${index}.email`}
                                render={({ field }) => (
                                  // @ts-ignore
                                  <Input
                                    {...field}
                                    onChange={(e) =>
                                      field.onChange(e.detail.value)
                                    }
                                    inputMode="text"
                                  />
                                )}
                              />
                            </FormField>
                            <FormField label="Phone Number">
                              <Controller
                                control={control}
                                defaultValue={item.phone}
                                name={`siteContacts.${index}.phone`}
                                render={({ field }) => (
                                  // @ts-ignore
                                  <Input
                                    {...field}
                                    onChange={(e) =>
                                      field.onChange(e.detail.value)
                                    }
                                    inputMode="text"
                                  />
                                )}
                              />
                            </FormField>
                          </SpaceBetween>
                        </ColumnLayout>
                        <Button
                          formAction="none"
                          variant="normal"
                          onClick={() => siteContactsRemove(index)}
                        >
                          Remove
                        </Button>
                      </SpaceBetween>
                    </Container>
                  ))}
                  <Button
                    formAction="none"
                    variant="normal"
                    onClick={() =>
                      siteContactsAppend({
                        name: "",
                        role: "",
                        email: "",
                        phone: "",
                      })
                    }
                  >
                    Add Site Contact
                  </Button>
                </SpaceBetween>
              </Container>
            </SpaceBetween>
          </Form>
        </form>
      </FormProvider>
    </PageWrapper>
  );
};
