import { Spinner } from "@cloudscape-design/components";
import {
  BookmarkObjectType,
  useGetDashboard,
} from "api-typescript-react-query-hooks";
import { useParams } from "react-router-dom";
import { PageWrapper } from "../../../layout/PageWrapper";
import NotFoundView from "../../NotFound/views/NotFoundView";
import { EmbedDashboard } from "../components/EmbedDashboard";

export const DashboardView = () => {
  const { dashboardId } = useParams();

  if (!dashboardId) return <NotFoundView />;

  const dashboardDetails = useGetDashboard({ dashboardId }).data?.dashboard;

  const bookmarkProps: {
    objectId?: string;
    objectType: BookmarkObjectType;
    bookmarkName?: string;
  } = {
    objectId: dashboardDetails?.dashboardId,
    objectType: "NetworkDashboard",
    bookmarkName: dashboardDetails?.title,
  };

  if (!dashboardDetails) {
    return <Spinner />;
  } else {
    return (
      <PageWrapper
        title={dashboardDetails?.title}
        description={dashboardDetails?.description}
        {...bookmarkProps}
      >
        <EmbedDashboard embed_url={dashboardDetails.embedUrl} />
      </PageWrapper>
    );
  }
};
