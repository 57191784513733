/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListUserFacilitiesResponseContent
 */
export interface ListUserFacilitiesResponseContent {
    /**
     * 
     * @type {Array<string>}
     * @memberof ListUserFacilitiesResponseContent
     */
    facilities: Array<string>;
}


/**
 * Check if a given object implements the ListUserFacilitiesResponseContent interface.
 */
export function instanceOfListUserFacilitiesResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "facilities" in value;

    return isInstance;
}

export function ListUserFacilitiesResponseContentFromJSON(json: any): ListUserFacilitiesResponseContent {
    return ListUserFacilitiesResponseContentFromJSONTyped(json, false);
}

export function ListUserFacilitiesResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListUserFacilitiesResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'facilities': json['facilities'],
    };
}

export function ListUserFacilitiesResponseContentToJSON(value?: ListUserFacilitiesResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'facilities': value.facilities,
    };
}

