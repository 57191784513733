import { Spinner } from "@cloudscape-design/components";
import {
  FacilitiesListOutput,
  useListFacilities,
  useListFacilitiesStatus,
} from "api-typescript-react-query-hooks";
import { latLngBounds } from "leaflet";
import { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  LayerGroup,
  CircleMarker,
  useMap,
} from "react-leaflet";
import FacilityPopup from "./FacilityPopup";
import { errorUnknown } from "../../../utils/errorlog";

const FacilityMap = () => {
  const {
    data: facilitiesResult,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useListFacilities({});
  const { data: statusResult } = useListFacilitiesStatus({});
  const [facilities, setFacilities] = useState<FacilitiesListOutput[]>([]);

  const nextPage = () => {
    fetchNextPage().catch((err) => {
      errorUnknown(err);
    });
  };

  const colorRgb = (status: string) => {
    if (status == "green") {
      return "#00FF00";
    } else if (status == "red") {
      return "#FF0000";
    } else if (status == "yellow") {
      return "#FFFF00";
    } else {
      return "#808080";
    }
  };

  useEffect(() => {
    if (!isFetching && hasNextPage) {
      nextPage();
    }
    const newFacilities = facilitiesResult?.pages.flatMap((f) => {
      return f.facilitiesList;
    });
    setFacilities(newFacilities || []);
  }, [facilitiesResult]);
  const status = statusResult?.statuses;

  const renderMarkers = () => {
    return facilities?.map(({ facilityId, name, description, location }) => {
      const { latitude, longitude } = location.coordinates;
      const facilityStatus =
        status?.find((f) => f.facilityId == facilityId)?.status || "grey";

      return (
        <CircleMarker
          center={[latitude, longitude]}
          radius={6}
          key={facilityId}
          fillColor={colorRgb(facilityStatus)}
          fillOpacity={0.8}
          color="black"
        >
          <FacilityPopup
            id={facilityId}
            name={name}
            description={description}
            status={facilityStatus}
            address={location.address}
            key={facilityId}
          />
        </CircleMarker>
      );
    });
  };

  const ChangeBounds = () => {
    const map = useMap();
    const bounds = latLngBounds([]);
    if (!facilities) {
      return null;
    }
    facilities?.forEach((f) => {
      bounds.extend([
        f.location.coordinates.latitude,
        f.location.coordinates.longitude,
      ]);
    });
    map.fitBounds(bounds);
    return null;
  };

  if (!facilities || facilities.length == 0 || hasNextPage) return <Spinner />;

  return (
    <MapContainer
      center={[0, 0]}
      zoom={10}
      style={{ height: "500px", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <ChangeBounds />
      <LayerGroup>{renderMarkers()}</LayerGroup>
    </MapContainer>
  );
};

export default FacilityMap;
