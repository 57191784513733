/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DashboardOutput } from './DashboardOutput';
import {
    DashboardOutputFromJSON,
    DashboardOutputFromJSONTyped,
    DashboardOutputToJSON,
} from './DashboardOutput';

/**
 * 
 * @export
 * @interface ListNetworkLevelDashboardsResponseContent
 */
export interface ListNetworkLevelDashboardsResponseContent {
    /**
     * 
     * @type {string}
     * @memberof ListNetworkLevelDashboardsResponseContent
     */
    pageToken?: string;
    /**
     * 
     * @type {Array<DashboardOutput>}
     * @memberof ListNetworkLevelDashboardsResponseContent
     */
    networkLevelDashboardList: Array<DashboardOutput>;
}


/**
 * Check if a given object implements the ListNetworkLevelDashboardsResponseContent interface.
 */
export function instanceOfListNetworkLevelDashboardsResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "networkLevelDashboardList" in value;

    return isInstance;
}

export function ListNetworkLevelDashboardsResponseContentFromJSON(json: any): ListNetworkLevelDashboardsResponseContent {
    return ListNetworkLevelDashboardsResponseContentFromJSONTyped(json, false);
}

export function ListNetworkLevelDashboardsResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListNetworkLevelDashboardsResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageToken': !exists(json, 'pageToken') ? undefined : json['pageToken'],
        'networkLevelDashboardList': ((json['networkLevelDashboardList'] as Array<any>).map(DashboardOutputFromJSON)),
    };
}

export function ListNetworkLevelDashboardsResponseContentToJSON(value?: ListNetworkLevelDashboardsResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageToken': value.pageToken,
        'networkLevelDashboardList': ((value.networkLevelDashboardList as Array<any>).map(DashboardOutputToJSON)),
    };
}

