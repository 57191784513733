/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FacilityFields } from './FacilityFields';
import {
    FacilityFieldsFromJSON,
    FacilityFieldsFromJSONTyped,
    FacilityFieldsToJSON,
} from './FacilityFields';

/**
 * 
 * @export
 * @interface UpdateFacilityRequestContent
 */
export interface UpdateFacilityRequestContent {
    /**
     * 
     * @type {FacilityFields}
     * @memberof UpdateFacilityRequestContent
     */
    facility: FacilityFields;
}


/**
 * Check if a given object implements the UpdateFacilityRequestContent interface.
 */
export function instanceOfUpdateFacilityRequestContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "facility" in value;

    return isInstance;
}

export function UpdateFacilityRequestContentFromJSON(json: any): UpdateFacilityRequestContent {
    return UpdateFacilityRequestContentFromJSONTyped(json, false);
}

export function UpdateFacilityRequestContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateFacilityRequestContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'facility': FacilityFieldsFromJSON(json['facility']),
    };
}

export function UpdateFacilityRequestContentToJSON(value?: UpdateFacilityRequestContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'facility': FacilityFieldsToJSON(value.facility),
    };
}

