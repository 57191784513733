import { Store, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import appReducer from "./reducers/appSlice";
import bookmarksReducer from "../modules/Bookmarks/store/bookmarksSlice";
import newPicturesReducer from "../modules/Facility/store/newPicturesSlice";
import simulationReducer from "../modules/Simulation/store/simulationSlice";

const store: Store = configureStore({
  reducer: {
    error: appReducer,
    bookmarks: bookmarksReducer,
    newPictures: newPicturesReducer,
    simulation: simulationReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
