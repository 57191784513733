import {
  AttributeEditor,
  Box,
  Button,
  Container,
  //ExpandableSection,
  Header,
  Input,
  Modal,
  Select,
  SpaceBetween,
  //Table,
} from "@cloudscape-design/components";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useQueryClient } from "@tanstack/react-query";
import {
  FacilityFieldsComplete,
  NewSimulationRequest,
  useListSimulationModels,
  useNewSimulation,
} from "api-typescript-react-query-hooks";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setError, setSuccess } from "../../../app/reducers/appSlice";

interface NewSimulationModalProps {
  visible: boolean;
  setVisible: (state: boolean) => void;
  facility: FacilityFieldsComplete;
}

export const NewSimulationModal = ({
  visible,
  setVisible,
  facility,
}: NewSimulationModalProps): JSX.Element => {
  const { data: models } = useListSimulationModels({});
  const [selectedModel, setSelectedModel] = useState<OptionDefinition | null>(
    null,
  );
  const [scenario, setScenario] = useState<Object[] | null>(null);
  const [simName, setSimName] = useState<string>("");
  const [processing, setProcessing] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const newSimulationMutation = useNewSimulation({
    onSuccess: async () =>
      queryClient.invalidateQueries({ queryKey: ["listSimulations"] }),
  });
  const dispatch = useDispatch();

  // TODO Enable this
  /*
  const nextPage = () => {
    fetchNextPage().catch((err) => {
      errorUnknown(err);
    });
  };
  */

  const modelOptions = models?.pages.flatMap((page) => {
    return page.simulationModelsList.map((model) => {
      return {
        label: model.modelName,
        value: model.modelId,
      };
    });
  });

  const modelSelection = models?.pages
    .flatMap((page) => page.simulationModelsList)
    .find((model) => model.modelId == selectedModel?.value);

  useEffect(() => {
    setScenario(modelSelection?.scenarioTemplate.Changes);
  }, [modelSelection]);

  const submitSimulation = () => {
    setProcessing(true);

    const scenarioRequest = modelSelection?.scenarioTemplate;
    scenarioRequest.Changes = scenario;
    const request: NewSimulationRequest = {
      facilityId: facility.facilityId,
      newSimulationRequestContent: {
        simulation: {
          name: simName,
          modelId: modelSelection!.modelId,
          modelName: modelSelection!.modelName,
          scenario: scenarioRequest,
        },
      },
    };

    console.log(request);

    newSimulationMutation
      .mutateAsync(request)
      .then(() => {
        dispatch(setSuccess("Simulation successfully started"));
      })
      .catch((err) => {
        dispatch(setError(`Error while starting simulation: ${err}`));
      })
      .finally(() => {
        setProcessing(false);
        setVisible(false);
      });
  };

  return (
    <Modal
      onDismiss={() => setVisible(false)}
      visible={visible}
      size="max"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="l">
            <Button variant="normal" onClick={() => setVisible(false)}>
              Cancel
            </Button>
            <Button
              variant="primary"
              loading={processing}
              onClick={() => submitSimulation()}
            >
              Start Simulation
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={
        <Header variant="h3">{`New simulation for facility ${facility.name}`}</Header>
      }
    >
      <SpaceBetween direction="vertical" size="xl">
        <Container
          header={
            <Header variant="h2" description="Model Selection">
              Simulation name
            </Header>
          }
        >
          <Input
            value={simName}
            placeholder="Enter a name for this simulation"
            onChange={({ detail }) => {
              setSimName(detail.value);
            }}
          />
        </Container>
        <Container
          header={
            <Header variant="h2" description="Model Selection">
              Select a model
            </Header>
          }
        >
          <Select
            selectedOption={selectedModel}
            onChange={({ detail }) => setSelectedModel(detail.selectedOption)}
            options={modelOptions}
          />
        </Container>
        <Container
          header={
            <Header variant="h2" description="Parameters input">
              Input parameters
            </Header>
          }
        >
          {scenario && (
            <AttributeEditor
              disableAddButton
              addButtonText="Add new item"
              // @ts-ignore
              items={scenario}
              isItemRemovable={() => false}
              definition={[
                {
                  label: "Parameter",
                  // @ts-ignore
                  control: (item) => item.AttributeName,
                },
                {
                  label: "Value",
                  control: (item, itemIndex) => (
                    <Input
                      // @ts-ignore
                      value={item.Value}
                      placeholder="Enter value"
                      onChange={({ detail }) => {
                        setScenario((changes) => {
                          const updatedChanges = [...changes!];
                          updatedChanges[itemIndex] = {
                            ...updatedChanges[itemIndex],
                            Value: detail.value,
                          };
                          return updatedChanges;
                        });
                      }}
                    />
                  ),
                },
              ]}
            />
          )}
        </Container>
      </SpaceBetween>
    </Modal>
  );
};
