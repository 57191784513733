import {
  Alert,
  Box,
  Button,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import styled from "@emotion/styled";
import {
  DeletePictureRequest,
  FacilityFieldsComplete,
  useDeletePicture,
} from "api-typescript-react-query-hooks";
import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { keyFromUrl } from "../../../utils/keyFromUrl";

interface PictureThumbnailGalleryProps {
  facility?: FacilityFieldsComplete;
}

export const PictureThumbnailGallery = (
  props: PictureThumbnailGalleryProps,
) => {
  const [selectedImage, setSelectedImage] = useState(0);
  const [startDelete, setDelete] = useState(false);
  const [modalError, setModalError] = useState<string>();

  const deletePictureMutations = useDeletePicture();

  const pictureUri = props.facility?.pictureUriList?.at(selectedImage);

  const deletePicture = () => {
    const key = keyFromUrl(pictureUri!);
    const request: DeletePictureRequest = {
      facilityId: props.facility!.facilityId,
      key: key!,
    };
    deletePictureMutations
      .mutateAsync(request)
      .then(() => {
        props.facility?.pictureUriList?.splice(selectedImage, 1);
        setDelete(false);
      })
      .catch(() => {
        setModalError(
          `
      Unable to delete picture.
      timestamp: ${Date.now()}
      facility: ${props.facility?.facilityId}
      key: ${key}
      `,
        );
      });
  };

  const DeletePictureModal = () => {
    return (
      <Modal
        onDismiss={() => setDelete(false)}
        visible={startDelete}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={() => {
                  setDelete(false);
                  setModalError(undefined);
                }}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={() => deletePicture()}>
                Delete
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Delete Picture"
      >
        <SpaceBetween direction="vertical" size="l">
          {modalError && (
            <Alert
              statusIconAriaLabel="Error"
              type="error"
              header="Error deleting picture"
            >
              The following problem occurred while deleting the picture. Please
              try again or contact your administrator with this information:
              {`${modalError}`}
            </Alert>
          )}
          <p>
            Are you sure you want to delete this picture (picture{" "}
            {selectedImage + 1})?
          </p>
          <Image style={{ backgroundImage: `url(${pictureUri})` }} />
        </SpaceBetween>
      </Modal>
    );
  };

  if (
    props.facility?.pictureUriList &&
    props.facility?.pictureUriList.length > 0
  ) {
    return (
      <>
        <DeletePictureModal />
        <SpaceBetween direction="vertical" size="s">
          <Box padding={{ horizontal: "xl" }} display="inline-block">
            <Carousel
              width="400px"
              showArrows
              showIndicators
              autoPlay={false}
              showThumbs={false}
              onChange={(index) => setSelectedImage(index)}
            >
              {props.facility.pictureUriList.map((uri, index) => (
                <Image key={index} style={{ backgroundImage: `url(${uri})` }} />
              ))}
            </Carousel>
          </Box>
          <Button
            variant="normal"
            formAction="none"
            onClick={() => setDelete(true)}
          >
            Delete this picture
          </Button>
        </SpaceBetween>
      </>
    );
  } else {
    return null;
  }
};

const Image = styled.div`
  height: 400px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;
