/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TagListItem } from './TagListItem';
import {
    TagListItemFromJSON,
    TagListItemFromJSONTyped,
    TagListItemToJSON,
} from './TagListItem';

/**
 * 
 * @export
 * @interface GetFacilityTagsResponseContent
 */
export interface GetFacilityTagsResponseContent {
    /**
     * 
     * @type {Array<TagListItem>}
     * @memberof GetFacilityTagsResponseContent
     */
    tags: Array<TagListItem>;
}


/**
 * Check if a given object implements the GetFacilityTagsResponseContent interface.
 */
export function instanceOfGetFacilityTagsResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tags" in value;

    return isInstance;
}

export function GetFacilityTagsResponseContentFromJSON(json: any): GetFacilityTagsResponseContent {
    return GetFacilityTagsResponseContentFromJSONTyped(json, false);
}

export function GetFacilityTagsResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFacilityTagsResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tags': ((json['tags'] as Array<any>).map(TagListItemFromJSON)),
    };
}

export function GetFacilityTagsResponseContentToJSON(value?: GetFacilityTagsResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tags': ((value.tags as Array<any>).map(TagListItemToJSON)),
    };
}

