import {
  Box,
  Button,
  Container,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { FacilityFieldsComplete } from "api-typescript-react-query-hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { DeleteFacilityModal } from "../components/DeleteFacilityModal";

interface FacilityAdminProps {
  facility: FacilityFieldsComplete;
}

export const FacilityAdmin = ({ facility }: FacilityAdminProps) => {
  const navigate = useNavigate();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  return (
    <Box padding={{ horizontal: "xl", bottom: "l" }}>
      <SpaceBetween direction="horizontal" size="l">
        <Container header={<Header variant="h2">Edit Facility</Header>}>
          <Box>
            <Button
              onClick={() =>
                navigate(`/facilities/${facility.facilityId}/edit`)
              }
            >
              Edit this facility
            </Button>
          </Box>
        </Container>
        <Container header={<Header variant="h2">Delete Facility</Header>}>
          <Box>
            <Button onClick={() => setDeleteModalVisible(true)}>
              Delete this facility
            </Button>
          </Box>
        </Container>
      </SpaceBetween>
      <DeleteFacilityModal
        visible={deleteModalVisible}
        setVisible={setDeleteModalVisible}
        facility={facility}
      />
    </Box>
  );
};
