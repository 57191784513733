import {
  Autosuggest,
  Box,
  Button,
  Container,
  FormField,
  Grid,
  Header,
  SpaceBetween,
  Spinner,
  Textarea,
} from "@cloudscape-design/components";
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AddTagRequest,
  AddTagResponseContent,
  AssociateDashboardTagRequest,
  DashboardOutput,
  DashboardReportsType,
  DisassociateDashboardTagRequest,
  UpdateDashboardRequest,
  UpdateDashboardRequestContent,
  useAddTag,
  useAssociateDashboardTag,
  useDisassociateDashboardTag,
  useGetDashboard,
  useGetDashboardTags,
  useListTags,
  useUpdateDashboard,
} from "api-typescript-react-query-hooks";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { FormInput, FormSelect } from "./components/FormInput";
import { PageWrapper } from "../../../layout/PageWrapper";
import { shortUUID, strToTitleCase } from "../../../utils";
import { EmbedDashboard } from "../components/EmbedDashboard";

interface DashboardFormFields {
  title: string;
  reportType: {
    label: string;
    value: string;
  };
  sourceType: {
    label: string;
    value: string;
  };
  description?: string;
}

const dashboardValidationSchema: Yup.ObjectSchema<DashboardFormFields> =
  Yup.object().shape({
    title: Yup.string().required("Name is required"),
    description: Yup.string().optional(),
    reportType: Yup.object({
      label: Yup.string().required(),
      value: Yup.string().required(),
    }).required("Report type is required"),
    sourceType: Yup.object({
      label: Yup.string().required(),
      value: Yup.string().required(),
    }).required("Source type is required"),
  });

type TagOption = { tagId?: string; value?: string };
interface Tag {
  id: string;
  name: TagOption;
  group?: TagOption;
  type: string;
}
const tagsValidationSchema = Yup.object().shape({
  name: Yup.string().required("Tag name is required"),
  group: Yup.string().optional(),
  existingName: Yup.object().optional(),
  existingGroup: Yup.object().optional(),
});

const EditDashboardView = (): JSX.Element => {
  const { dashboardId } = useParams();
  const prevDashboard = dashboardId
    ? useGetDashboard({ dashboardId }).data?.dashboard
    : null;
  const [dashboardTags, setDashboardTags] = useState<Tag[]>([]);
  const [removeTags, setRemovedTags] = useState<string[]>([]);
  const { data: tagList } = useListTags();

  //Mutations
  const updateDashboardMutation = useUpdateDashboard();
  const createTagMutation = useAddTag();
  const associateTagMutation = useAssociateDashboardTag();
  const removeTagMutation = useDisassociateDashboardTag();
  const prevDashboardTags = dashboardId
    ? useGetDashboardTags({ dashboardId }).data?.tags
    : null;

  //Prepare form validation
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
    watch,
  } = useForm({
    resolver: yupResolver(dashboardValidationSchema),
  });
  const {
    handleSubmit: handleAddTag,
    reset: resetTagForm,
    setValue,
    control: tagControl,
    formState: { errors: tagErrors, isValid: isTagValid },
  } = useForm({
    resolver: yupResolver(tagsValidationSchema),
  });

  useEffect(() => {
    if (prevDashboardTags) {
      const newDashboardTags = prevDashboardTags.map((tag) => ({
        id: shortUUID(),
        name: { value: tag.name, tagId: tag.tagId },
        group: { value: tag.group, tagId: tag.tagId },
        type: "existing",
      }));
      setDashboardTags(newDashboardTags);
    }
  }, [prevDashboardTags, dashboardId]);

  // update dashboard
  const updateDashboard = (data: DashboardFormFields) => {
    if (dashboardId) {
      const updatedFields: UpdateDashboardRequestContent = {
        dashboard: {
          title: data.title,
          dashboardReportsType: data.reportType.value as DashboardReportsType,
          description: data.description,
        },
      };
      const request: UpdateDashboardRequest = {
        dashboardId,
        updateDashboardRequestContent: updatedFields,
      };
      updateDashboardMutation
        .mutateAsync(request)
        .then(async () => {
          //create the new tags
          const newTags = dashboardTags.filter((tag) => tag.type === "new");
          const newTagIds: string[] = await Promise.all(
            newTags.map(async (tag) => {
              const tagId = await createNewTag(
                tag.name.value!,
                tag.group?.value,
              );
              return tagId || "";
            }),
          );

          //associate & dissociate tags to dashboard
          if (dashboardId) {
            await Promise.all([
              newTagIds.map((tag) => associateTag(dashboardId, tag)),
              removeTags.map((tag) => dissociateTag(dashboardId, tag)),
            ]);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const createNewTag = async (
    name: string,
    group?: string,
  ): Promise<string | undefined> => {
    try {
      const request: AddTagRequest = {
        addTagRequestContent: {
          tag: { name, group },
        },
      };
      const response: AddTagResponseContent =
        await createTagMutation.mutateAsync(request);
      return response.tagId;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  };

  const associateTag = async (
    _dashboardId: string,
    tagId: string,
  ): Promise<void> => {
    const request: AssociateDashboardTagRequest = {
      dashboardId: _dashboardId,
      tagId,
    };
    try {
      await associateTagMutation.mutateAsync(request);
    } catch (err) {
      console.error(err);
    }
  };

  const dissociateTag = async (
    _dashboardId: string,
    tagId: string,
  ): Promise<void> => {
    const request: DisassociateDashboardTagRequest = {
      dashboardId: _dashboardId,
      tagId,
    };
    try {
      await removeTagMutation.mutateAsync(request);
    } catch (err) {
      console.error(err);
    }
  };

  const addTag = (data: {
    name: string;
    group?: string;
    existingName?: object;
    existingGroup?: object;
  }) => {
    const { name, group, existingGroup, existingName } = data;
    const resolveType = () =>
      (!existingName && name) || (!existingGroup && group) ? "new" : "existing";
    const newTag: Tag = {
      id: shortUUID(),
      name: existingName || { value: name },
      group: existingGroup || { value: group },
      type: resolveType(),
    };
    setDashboardTags((prevTags) =>
      prevTags ? [...prevTags, newTag] : [newTag],
    );
    resetTagForm();
  };

  const removeTag = (index: string, type: string, tagId?: string) => {
    setDashboardTags((prevTags) => prevTags.filter((t) => t.id !== index));
    //for dissociation
    if (tagId && type === "existing") {
      setRemovedTags((tags) => (tags ? [...tags, tagId] : [tagId]));
    }
  };

  const buildDashboardValues = (dashboard: DashboardOutput) => {
    return {
      title: dashboard.title,
      description: dashboard.description,
      reportType: {
        value: dashboard.dashboardReportsType,
        label: strToTitleCase(dashboard.dashboardReportsType),
      },
      sourceType: { label: "QuickSight", value: "QUICKSIGHT" },
      embedUrl: dashboard.embedUrl,
    };
  };

  if (!prevDashboard) return <Spinner />;
  // Initial values
  const dashboard = buildDashboardValues(prevDashboard);

  return (
    <PageWrapper
      title={"Edit Dashboard"}
      description="Edit Dashboard information"
    >
      <SpaceBetween direction="vertical" size="m">
        <form
          id="dashboardForm"
          onSubmit={handleSubmit((data) => updateDashboard(data))}
        >
          <Container header={<Header variant="h2">Edit Dashboard</Header>}>
            <SpaceBetween direction="vertical" size="s">
              <Controller
                name="title"
                control={control}
                defaultValue={dashboard.title}
                render={({ field: { onChange, value } }) => (
                  <FormInput
                    label="Dashboard name"
                    description="What is the focus of this dashboard?"
                    value={value}
                    onChange={onChange}
                    error={errors.title?.message}
                    placeholder="Dashboard Name"
                  />
                )}
              />
              <Controller
                name="description"
                control={control}
                defaultValue={dashboard.description}
                render={({ field: { onChange, value } }) => (
                  <FormField
                    label="Dashboard description"
                    description="Short description of the dashboard"
                    stretch
                  >
                    <Textarea
                      onChange={({ detail }) => onChange(detail.value)}
                      value={value || ""}
                      placeholder="Dashboard description"
                      rows={2}
                    />
                  </FormField>
                )}
              />
              <Grid
                gridDefinition={[
                  { colspan: { default: 12, xs: 6 } },
                  { colspan: { default: 12, xs: 6 } },
                ]}
              >
                <Controller
                  name="reportType"
                  control={control}
                  defaultValue={dashboard.reportType}
                  render={({ field: { onChange } }) => (
                    <FormSelect
                      label="Report type"
                      description="Choose a specific report type"
                      selectedOption={watch("reportType")}
                      onChange={onChange}
                      error={errors.reportType?.message}
                      options={[
                        { label: "Operational", value: "OPERATIONAL" },
                        { label: "Financial", value: "FINANCIAL" },
                      ]}
                      placeholder="Select Report Type"
                    />
                  )}
                />
                <Controller
                  name="sourceType"
                  control={control}
                  defaultValue={dashboard.sourceType}
                  render={({ field: { onChange } }) => (
                    <FormSelect
                      label="Data source type"
                      description="Choose a source for this dashboard"
                      selectedOption={watch("sourceType")}
                      onChange={onChange}
                      error={errors.sourceType?.message}
                      options={[{ label: "QuickSight", value: "QUICKSIGHT" }]}
                      placeholder="Select Source Type"
                    />
                  )}
                />
              </Grid>
              <Box margin={{ vertical: "xs" }}>
                {dashboard.embedUrl && (
                  <EmbedDashboard embed_url={dashboard.embedUrl} />
                )}
              </Box>
            </SpaceBetween>
          </Container>
        </form>
        <form id="tagForm" onSubmit={handleAddTag((data) => addTag(data))}>
          <Container
            header={
              <Header
                variant="h2"
                description="Manage the taxonomy of how to find this dashboard by search"
              >
                Dashboard Tags
              </Header>
            }
          >
            <GridWrapper
              gridDefinition={[
                { colspan: { default: 12, xs: 5 } },
                { colspan: { default: 12, xs: 5 } },
                { colspan: { default: 12, xs: 2 } },
              ]}
            >
              <Controller
                name="name"
                control={tagControl}
                render={({ field }) => (
                  <FormField label="Choose a tag or create one">
                    <Autosuggest
                      onSelect={({ detail }) => {
                        const { value, selectedOption } = detail;
                        if (selectedOption && value === selectedOption?.value) {
                          setValue("existingName", selectedOption);
                        } else setValue("existingName", undefined);
                      }}
                      onChange={({ detail }) => field.onChange(detail.value)}
                      enteredTextLabel={(value) => `Use: "${value}"`}
                      value={field.value || ""}
                      options={
                        tagList?.tags.flatMap((t) => ({
                          tagId: t.tagId,
                          value: t.name,
                        })) || []
                      }
                      ariaLabel="Enter tag name"
                      placeholder="Add a tag e.g production"
                      empty="No matches found"
                      errorText={tagErrors.name?.message}
                    />
                  </FormField>
                )}
              />
              <Controller
                name="group"
                control={tagControl}
                render={({ field }) => (
                  <FormField label="Add a group or create new">
                    <Autosuggest
                      onSelect={({ detail }) => {
                        const { value, selectedOption } = detail;
                        if (selectedOption && value === selectedOption?.value) {
                          setValue("existingGroup", selectedOption);
                        } else setValue("existingGroup", undefined);
                      }}
                      onChange={({ detail }) => field.onChange(detail.value)}
                      enteredTextLabel={(value) => `Use: "${value}"`}
                      value={field.value || ""}
                      options={
                        tagList?.tags.flatMap((t) => ({
                          tagId: t.tagId,
                          value: t.group,
                        })) || []
                      }
                      ariaLabel="Search group name or add new"
                      placeholder="Add tag group e.g manufacturing"
                      empty="No matches found"
                    />
                  </FormField>
                )}
              />
              <Button
                variant="normal"
                formAction="submit"
                disabled={!isTagValid}
              >
                Add
              </Button>
            </GridWrapper>
            <Box margin={{ vertical: "m" }}>
              {dashboardTags.map((t) => {
                const tag = t.group?.value
                  ? `${t.name.value}::${t.group.value}`
                  : t.name.value;
                return (
                  <Grid
                    key={t.id}
                    gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
                  >
                    <Box>{tag}</Box>
                    <Button
                      variant="normal"
                      onClick={() => removeTag(t.id, t.type, t.name.tagId)}
                    >
                      Delete
                    </Button>
                  </Grid>
                );
              })}
            </Box>
          </Container>
        </form>
        <Box margin={{ top: "xs" }} float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button formAction="none" variant="link" onClick={() => reset()}>
              Cancel
            </Button>
            <Button
              formAction="submit"
              form="dashboardForm"
              disabled={!isValid}
              variant="primary"
            >
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      </SpaceBetween>
    </PageWrapper>
  );
};

const GridWrapper = styled(Grid)`
  align-items: end;
`;

export default EditDashboardView;
