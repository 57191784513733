import "@cloudscape-design/global-styles/index.css";
import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AuthProvider } from "./app/components/AuthProvider";
import { ConfigProvider } from "./app/components/ConfigProvider";
import store from "./app/store";
import "./index.css";
import "leaflet/dist/leaflet.css";
import "leaflet/dist/leaflet";

const rootElement = document.getElementById("root");

ReactDOMClient.createRoot(rootElement!).render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider configUrl="/runtime-env.json">
        <AuthProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AuthProvider>
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
);
