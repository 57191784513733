import {
  Box,
  Button,
  Header,
  Link,
  Modal,
  Spinner,
} from "@cloudscape-design/components";
import { EmbedDashboard } from "./EmbedDashboard";

interface DashboardModalProps {
  visible: boolean;
  setVisible: (state: boolean) => void;
  title: string;
  embedUrl?: string;
  isLoading?: boolean;
}

export const DashboardModal = ({
  visible,
  setVisible,
  title,
  embedUrl,
  isLoading,
}: DashboardModalProps): JSX.Element => {
  return (
    <Modal
      onDismiss={() => setVisible(false)}
      visible={visible}
      size="max"
      footer={
        <Box float="right">
          <Button variant="primary" onClick={() => setVisible(false)}>
            Close
          </Button>
        </Box>
      }
      header={
        <Header variant="h3">
          <Link external href={embedUrl}>
            {title}
          </Link>
        </Header>
      }
    >
      {isLoading && <Spinner />}
      {!isLoading && embedUrl && <EmbedDashboard embed_url={embedUrl} />}
    </Modal>
  );
};
