import { Button, Header, Modal } from "@cloudscape-design/components";
import {
  DeleteFacilityRequest,
  FacilityFieldsComplete,
  useDeleteFacility,
} from "api-typescript-react-query-hooks";
import { useNavigate } from "react-router-dom";
import { setError, setInfo } from "../../../app/reducers/appSlice";
import { useAppDispatch } from "../../../app/store";

interface DeleteFacilityModalProps {
  visible: boolean;
  setVisible: (state: boolean) => void;
  facility: FacilityFieldsComplete;
}

export const DeleteFacilityModal = ({
  visible,
  setVisible,
  facility,
}: DeleteFacilityModalProps) => {
  const deleteMutation = useDeleteFacility();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const deleteFacility = () => {
    const request: DeleteFacilityRequest = {
      facilityId: facility.facilityId,
    };

    deleteMutation
      .mutateAsync(request)
      .then(() => {
        dispatch(setInfo("Facility successfully deleted"));
        setTimeout(() => {
          navigate("/facilities");
        }, 2000);
      })
      .catch((err) =>
        dispatch(setError(`Unable to delete facility. Error: ${err}`)),
      );
  };

  return (
    <Modal
      onDismiss={() => setVisible(false)}
      visible={visible}
      size="max"
      footer={
        <Button variant="primary" onClick={() => setVisible(false)}>
          Cancel
        </Button>
      }
      header={
        <Header variant="h3">
          Are you sure you want to delete facility {`${facility.name}`}
        </Header>
      }
    >
      <Button variant="primary" onClick={() => deleteFacility()}>
        Delete Facility
      </Button>
    </Modal>
  );
};
