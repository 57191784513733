import {
  Autosuggest,
  Button,
  Container,
  Header,
  Select,
  SpaceBetween,
  Spinner,
} from "@cloudscape-design/components";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useQueryClient } from "@tanstack/react-query";
import {
  AddUserFacilityRequest,
  DeleteUserFacilityRequest,
  FacilitiesListOutput,
  useAddUserFacility,
  useDeleteUserFacility,
  useListFacilities,
  useListFacilityUsers,
  useSearchUsers,
} from "api-typescript-react-query-hooks";
import { useEffect, useState } from "react";
import { setError, setSuccess } from "../../../app/reducers/appSlice";
import { useAppDispatch } from "../../../app/store";
import { PageWrapper } from "../../../layout/PageWrapper";
import { errorUnknown } from "../../../utils/errorlog";
import { CompactUsersList } from "../components/CompactUsersList";

export const MapUserFacility = () => {
  const [facilities, setFacilities] = useState<FacilitiesListOutput[]>([]);
  const [selectedFacility, setSelectedFacility] =
    useState<OptionDefinition | null>(null);
  const [selectedUser, setSelectedUser] = useState<string>();
  const [searchUsersText, setSearchUsersText] = useState<string>("");
  const dispatch = useAppDispatch();

  const {
    data: facilitiesResult,
    isFetching: facilitiesIsFetching,
    hasNextPage: facilitiesHasNextPage,
    fetchNextPage: facilitiesFetchNextPage,
  } = useListFacilities({});

  const {
    data: usersResult,
    isFetching: usersIsFetching,
    hasNextPage: usersHasNextPage,
    fetchNextPage: usersFetchNextPage,
    error: usersError,
  } = useSearchUsers(
    { text: searchUsersText },
    { enabled: searchUsersText?.length > 3 },
  );

  const { data: facilityUsersResult } = useListFacilityUsers(
    { facilityId: selectedFacility?.value! },
    { enabled: !!selectedFacility },
  );

  const queryClient = useQueryClient();

  const facilityDeleteUsersMutation = useDeleteUserFacility({
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["listFacilityUsers"] });
    },
  });

  const facilityAddUserMutation = useAddUserFacility();

  const nextPage = () => {
    facilitiesFetchNextPage().catch((err) => {
      errorUnknown(err);
    });
  };

  useEffect(() => {
    if (facilitiesIsFetching) {
      return;
    } else if (facilitiesHasNextPage) {
      nextPage();
    } else {
      const newFacilities = facilitiesResult!.pages.flatMap((f) => {
        return f.facilitiesList;
      });
      setFacilities(newFacilities);
    }
  }, [facilitiesResult]);

  const facilityUsers = facilityUsersResult?.users || [];

  const facilitiesOptions = facilities.map((facility) => {
    return { label: facility.name, value: facility.facilityId };
  });

  const userSearchStatus = () => {
    if (usersError) {
      return "error";
    }

    if (usersIsFetching) {
      return "loading";
    } else if (usersHasNextPage) {
      return "pending";
    } else {
      return "finished";
    }
  };

  const userSearchOptions = usersResult?.pages.flatMap((page) => {
    const options = page.page.map((user) => {
      return {
        value: user.username,
        label: user.name,
        tags: [user.username],
      };
    });
    return options;
  });

  const removeUser = (user: string) => {
    const deleteUserFacilityRequest: DeleteUserFacilityRequest = {
      userName: user,
      facilityId: selectedFacility?.value!,
    };

    facilityDeleteUsersMutation
      .mutateAsync(deleteUserFacilityRequest)
      .then(() => {})
      .catch(() => dispatch(setError("Unable to unmap user from facility")));
  };

  const addUserButtonEnabled = (): boolean => {
    return !!selectedUser && !!selectedFacility;
  };

  const addUserButtonLoading = (): boolean => {
    return facilityAddUserMutation.isLoading;
  };

  const addUser = () => {
    const addUserFacilityRequest: AddUserFacilityRequest = {
      userName: selectedUser!,
      addUserFacilityRequestContent: {
        facilityId: selectedFacility?.value!,
      },
    };

    facilityAddUserMutation
      .mutateAsync(addUserFacilityRequest)
      .then(() =>
        dispatch(
          setSuccess(
            `User ${selectedUser} added to facilty ${selectedFacility?.value}`,
          ),
        ),
      )
      .catch(() =>
        dispatch(
          setError("Unable to map user from facility. Please try again."),
        ),
      );
  };

  if (facilitiesIsFetching || facilitiesHasNextPage) return <Spinner />;

  return (
    <PageWrapper
      title="Administrator: Map users to facilities"
      description="Manage which users have access to which facilities"
    >
      <SpaceBetween direction="vertical" size="l">
        <Container header={<Header>Select a facility</Header>}>
          <Select
            selectedOption={selectedFacility}
            onChange={({ detail }) =>
              setSelectedFacility(detail.selectedOption)
            }
            options={facilitiesOptions}
          />
        </Container>
        <Container
          header={<Header>Select users that can access this facility</Header>}
        >
          <SpaceBetween direction="vertical" size="l">
            <Autosuggest
              onChange={({ detail }) => setSearchUsersText(detail.value)}
              onSelect={({ detail }) => setSelectedUser(detail.value)}
              onLoadItems={({ detail }) => {
                if (detail.firstPage) {
                  // no-op, this is done in `onChange`
                } else if (detail.samePage) {
                  // no-op, this is done in `onChange`
                } else {
                  usersFetchNextPage()
                    .then(() => {})
                    .catch(() =>
                      dispatch(
                        setError(
                          "Unable to map user from facility. Please try again.",
                        ),
                      ),
                    );
                }
              }}
              value={searchUsersText}
              options={userSearchOptions}
              placeholder="Enter a user's first or last name, or their username"
              loadingText="Search users..."
              statusType={userSearchStatus()}
              empty="No matches found"
              recoveryText="An error has occurred searching for users. Please reload the page and try again."
            />
            <Button
              variant="primary"
              disabled={!addUserButtonEnabled()}
              loading={addUserButtonLoading()}
              loadingText="Adding user..."
              onClick={() => addUser()}
            >
              Add User to this Facility
            </Button>
          </SpaceBetween>
        </Container>
        <Container header={<Header>Current users in this facility</Header>}>
          {selectedFacility
            ? facilityUsers && (
                <CompactUsersList
                  users={facilityUsers}
                  deleteCallback={removeUser}
                />
              )
            : "Select a Facility to see its assigned users"}
        </Container>
      </SpaceBetween>
    </PageWrapper>
  );
};
