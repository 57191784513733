/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Area } from './Area';
import {
    AreaFromJSON,
    AreaFromJSONTyped,
    AreaToJSON,
} from './Area';
import type { ContactPerson } from './ContactPerson';
import {
    ContactPersonFromJSON,
    ContactPersonFromJSONTyped,
    ContactPersonToJSON,
} from './ContactPerson';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './Location';

/**
 * 
 * @export
 * @interface FacilityFieldsComplete
 */
export interface FacilityFieldsComplete {
    /**
     * 
     * @type {string}
     * @memberof FacilityFieldsComplete
     */
    name: string;
    /**
     * 
     * @type {Location}
     * @memberof FacilityFieldsComplete
     */
    location: Location;
    /**
     * 
     * @type {string}
     * @memberof FacilityFieldsComplete
     */
    description?: string;
    /**
     * 
     * @type {Area}
     * @memberof FacilityFieldsComplete
     */
    area?: Area;
    /**
     * 
     * @type {Array<ContactPerson>}
     * @memberof FacilityFieldsComplete
     */
    siteContacts?: Array<ContactPerson>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FacilityFieldsComplete
     */
    transportationNearby?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FacilityFieldsComplete
     */
    pointsOfInterestNearby?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof FacilityFieldsComplete
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof FacilityFieldsComplete
     */
    updatedAt: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof FacilityFieldsComplete
     */
    pictureUriList?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FacilityFieldsComplete
     */
    facilityId: string;
}


/**
 * Check if a given object implements the FacilityFieldsComplete interface.
 */
export function instanceOfFacilityFieldsComplete(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "location" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "facilityId" in value;

    return isInstance;
}

export function FacilityFieldsCompleteFromJSON(json: any): FacilityFieldsComplete {
    return FacilityFieldsCompleteFromJSONTyped(json, false);
}

export function FacilityFieldsCompleteFromJSONTyped(json: any, ignoreDiscriminator: boolean): FacilityFieldsComplete {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'location': LocationFromJSON(json['location']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'area': !exists(json, 'area') ? undefined : AreaFromJSON(json['area']),
        'siteContacts': !exists(json, 'siteContacts') ? undefined : ((json['siteContacts'] as Array<any>).map(ContactPersonFromJSON)),
        'transportationNearby': !exists(json, 'transportationNearby') ? undefined : json['transportationNearby'],
        'pointsOfInterestNearby': !exists(json, 'pointsOfInterestNearby') ? undefined : json['pointsOfInterestNearby'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'pictureUriList': !exists(json, 'pictureUriList') ? undefined : json['pictureUriList'],
        'facilityId': json['facilityId'],
    };
}

export function FacilityFieldsCompleteToJSON(value?: FacilityFieldsComplete | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'location': LocationToJSON(value.location),
        'description': value.description,
        'area': AreaToJSON(value.area),
        'siteContacts': value.siteContacts === undefined ? undefined : ((value.siteContacts as Array<any>).map(ContactPersonToJSON)),
        'transportationNearby': value.transportationNearby,
        'pointsOfInterestNearby': value.pointsOfInterestNearby,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'pictureUriList': value.pictureUriList,
        'facilityId': value.facilityId,
    };
}

