import { SVGProps } from "react";
import { JSX } from "react/jsx-runtime";

export const Bookmark = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>,
) => (
  <span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={16}
      focusable="false"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        stroke="#E9EBED"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M2.26 1h7.99v11.82L5.8 8.68 1 13.14V1h1.26Z"
      />
    </svg>
  </span>
);
