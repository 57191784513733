import { Autosuggest } from "@cloudscape-design/components";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useSearchResources } from "api-typescript-react-query-hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setError } from "../../../app/reducers/appSlice";
import { useAppDispatch } from "../../../app/store";
import { DashboardIcon } from "../../../design/DashboardIcon";
import { FacilityIcon } from "../../../design/FacilityIcon";

export const SearchBar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState<string>("");

  const {
    data: searchResult,
    isFetching,
    hasNextPage,
    fetchNextPage,
    error,
  } = useSearchResources(
    { text: searchTerm },
    { enabled: searchTerm?.length > 0 },
  );

  const options: OptionDefinition[] =
    searchResult?.pages.flatMap((page) => {
      return page.page.map((resource) => {
        return {
          value: resource.resourceId,
          label: resource.name,
          iconSvg:
            // @ts-ignore
            resource.resourceType == 0 ? <FacilityIcon /> : <DashboardIcon />,
          tags:
            // TODO FIX THIS IN THE BACKEND, IT SHOULD BE A STRING
            // @ts-ignore
            resource.resourceType == 0 ? ["Facility"] : ["Dashboard"],
        };
      });
    }) || [];

  const redirectToResult = (selected?: OptionDefinition) => {
    if (selected?.tags?.find((e) => e == "Facility")) {
      navigate(`/facilities/${selected.value}`);
    } else if (selected?.tags?.find((e) => e == "Dashboard")) {
      navigate(`/dashboard/${selected.value}`);
    } else {
      dispatch(
        setError(
          `There was an error redirecting to the selected element. Element: ${selected}`,
        ),
      );
    }
  };

  const searchStatus = () => {
    if (error) {
      return "error";
    }

    if (isFetching) {
      return "loading";
    } else if (hasNextPage) {
      return "pending";
    } else {
      return "finished";
    }
  };

  return (
    <Autosuggest
      onChange={({ detail }) => setSearchTerm(detail.value)}
      onSelect={({ detail }) => {
        redirectToResult(detail.selectedOption);
        setSearchTerm("");
      }}
      onLoadItems={({ detail }) => {
        if (detail.firstPage) {
          // no-op, this is done in `onChange`
        } else if (detail.samePage) {
          // no-op, this is done in `onChange`
        } else {
          fetchNextPage()
            .then(() => {})
            .catch(() =>
              dispatch(
                setError("Unable to map user from facility. Please try again."),
              ),
            );
        }
      }}
      value={searchTerm}
      options={options}
      statusType={searchStatus()}
      placeholder="Enter the name of a facility or network dashboard"
      loadingText="Searching..."
      empty="No matches found"
      recoveryText="An error has occurred searching. Please reload the page and try again."
    />
  );
};
