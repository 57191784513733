/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SchematicObject } from './SchematicObject';
import {
    SchematicObjectFromJSON,
    SchematicObjectFromJSONTyped,
    SchematicObjectToJSON,
} from './SchematicObject';

/**
 * 
 * @export
 * @interface ListSchematicsResponseContent
 */
export interface ListSchematicsResponseContent {
    /**
     * 
     * @type {Array<SchematicObject>}
     * @memberof ListSchematicsResponseContent
     */
    schematics: Array<SchematicObject>;
    /**
     * 
     * @type {string}
     * @memberof ListSchematicsResponseContent
     */
    token?: string;
}


/**
 * Check if a given object implements the ListSchematicsResponseContent interface.
 */
export function instanceOfListSchematicsResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "schematics" in value;

    return isInstance;
}

export function ListSchematicsResponseContentFromJSON(json: any): ListSchematicsResponseContent {
    return ListSchematicsResponseContentFromJSONTyped(json, false);
}

export function ListSchematicsResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListSchematicsResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'schematics': ((json['schematics'] as Array<any>).map(SchematicObjectFromJSON)),
        'token': !exists(json, 'token') ? undefined : json['token'],
    };
}

export function ListSchematicsResponseContentToJSON(value?: ListSchematicsResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'schematics': ((value.schematics as Array<any>).map(SchematicObjectToJSON)),
        'token': value.token,
    };
}

