import {
  Alert,
  Box,
  Button,
  Icon,
  SpaceBetween,
} from "@cloudscape-design/components";
import Table from "@cloudscape-design/components/table";
import {
  useListAlerts,
  FacilityFieldsComplete,
  AlertObject,
  AlertSeverityTypeEnum,
  useGetDashboard,
} from "api-typescript-react-query-hooks";
import { useEffect, useRef, useState } from "react";
import { epochTimestampToDate, strToTitleCase } from "../../../utils";
import { errorUnknown } from "../../../utils/errorlog";
import { DashboardModal } from "../../Dashboard/components/DashboardModal";

export const FacilityAlert = ({
  facility,
}: {
  facility: FacilityFieldsComplete;
}): JSX.Element => {
  const { data, error, fetchNextPage, hasNextPage, isFetching } = useListAlerts(
    { facilityId: facility.facilityId, sourceType: "facility", size: 10 },
  );

  const [dashboardVisible, setDashboardVisible] = useState<boolean>(false);
  const [dashboardId, setDashboardId] = useState<string | undefined>(undefined);
  //const [dashboardUrl, setDashboardUrl] = useState<string>("");

  const { data: dashboardResult, isLoading } = useGetDashboard(
    {
      dashboardId: dashboardId!,
    },
    {
      enabled: dashboardId ? true : false,
    },
  );

  const dashboardUrl = dashboardResult?.dashboard?.embedUrl;

  const nextPage = () => {
    fetchNextPage().catch((err) => {
      errorUnknown(err);
    });
  };

  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          nextPage();
        }
      },
      { threshold: 1 },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget]);

  return (
    <Box padding={{ horizontal: "xl", bottom: "l" }}>
      <SpaceBetween direction="vertical" size="l">
        {error && (
          <Box>
            <Alert statusIconAriaLabel="Error" type="error" header={error.name}>
              {error.message}
            </Alert>
          </Box>
        )}
        <Table
          header={
            <Box variant="h2" margin={{ vertical: "xs" }}>
              <strong>{`${strToTitleCase(facility.name)} Alerts`}</strong>
            </Box>
          }
          columnDefinitions={[
            {
              id: "severity",
              header: "Severity",
              cell: (item: AlertObject) => <Severity status={item.severity} />,
              sortingField: "severity",
              isRowHeader: true,
            },
            {
              id: "dashboardId",
              header: "Dashboard",
              cell: (item: AlertObject) => (
                <Button
                  iconName="external"
                  variant="inline-icon"
                  onClick={() => {
                    setDashboardId(item.dashboardId);
                    setDashboardVisible(true);
                  }}
                />
              ),
            },
            {
              id: "description",
              header: "Description",
              cell: (item: AlertObject) => {
                const description = item.description;
                return description
                  ? description.at(0)?.toUpperCase() +
                      description.slice(1, description.length)
                  : "";
              },
              isRowHeader: true,
            },
            {
              id: "valueTrigger",
              header: "Trigger value",
              cell: (item: AlertObject) => item.valueTrigger || "",
              isRowHeader: true,
            },
            {
              id: "valueThreshold",
              header: "Threshold value",
              cell: (item: AlertObject) => item.valueThreshold || "",
              isRowHeader: true,
            },
            {
              id: "triggeredAt",
              header: "Date/Time",
              cell: (item: AlertObject) =>
                epochTimestampToDate(item.triggeredAt),
              sortingField: "triggeredAt",
              isRowHeader: true,
            },
          ]}
          trackBy="alertId"
          items={
            data?.pages.flatMap((page) => {
              return page.alerts;
            }) || []
          }
          wrapLines
          resizableColumns
          loadingText="Loading alerts"
          empty={
            <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
              <b>No resources</b>
            </Box>
          }
          footer={
            <Box textAlign="center" margin={{ bottom: "l" }}>
              <div ref={observerTarget}>
                <Button
                  onClick={() => {
                    hasNextPage && !isFetching && nextPage();
                  }}
                  disabled={!hasNextPage}
                  loading={isFetching}
                  loadingText="Loading..."
                >
                  {isFetching
                    ? "Loading..."
                    : hasNextPage
                      ? "View more"
                      : "All alerts loaded"}
                </Button>
              </div>
            </Box>
          }
        />
      </SpaceBetween>
      <DashboardModal
        visible={dashboardVisible}
        setVisible={setDashboardVisible}
        title={`Dashboard for alert`}
        embedUrl={dashboardUrl}
        isLoading={isLoading}
      />
    </Box>
  );
};

const Severity = ({ status }: { status: AlertSeverityTypeEnum }) => {
  return (
    <Box>
      {status === "red" && <Icon name="status-negative" variant="error" />}
      {status === "yellow" && <Icon name="status-warning" variant="warning" />}
      <Box variant="span" padding={{ left: "xs" }}>
        {status === "red" ? "Critical" : "Warning"}
      </Box>
    </Box>
  );
};
