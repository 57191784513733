/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export type AlertSeverityTypeEnum =
  'red' | 
  'yellow'


export function AlertSeverityTypeEnumFromJSON(json: any): AlertSeverityTypeEnum {
    return AlertSeverityTypeEnumFromJSONTyped(json, false);
}

export function AlertSeverityTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertSeverityTypeEnum {
    return json as AlertSeverityTypeEnum;
}

export function AlertSeverityTypeEnumToJSON(value?: AlertSeverityTypeEnum | null): any {
    return value as any;
}

