import {
  Alert,
  Box,
  Button,
  Container,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import Link from "@cloudscape-design/components/link";
import Table from "@cloudscape-design/components/table";
import {
  useListFacilities,
  FacilitiesListOutput,
} from "api-typescript-react-query-hooks";
import { useEffect, useRef } from "react";
import { useAuth } from "../../../app/components/AuthProvider";
import { PageWrapper } from "../../../layout/PageWrapper";
import { errorUnknown } from "../../../utils/errorlog";

const FacilityList = (): JSX.Element => {
  const authData = useAuth();
  const isAdmin = authData.isAdmin;

  const { data, error, fetchNextPage, hasNextPage, isFetching } =
    useListFacilities({});

  // This is to always have a catch of exceptions
  // However, it doesn't appear to be called even if
  // the calls fail (e.g. connection refused, 404)
  // but the `error` parameter is updated
  const nextPage = () => {
    fetchNextPage().catch((err) => {
      errorUnknown(err);
    });
  };

  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          nextPage();
        }
      },
      { threshold: 1 },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget]);

  return (
    <PageWrapper
      title="Facilities"
      description="List of Facilities you have access to"
    >
      <Container
        header={
          <Header
            variant="h2"
            description=""
            actions={
              isAdmin && (
                <Button href="/facilities/new" variant="primary">
                  New Facility
                </Button>
              )
            }
          >
            Facilities List
          </Header>
        }
      >
        <SpaceBetween direction="vertical" size="l">
          {error && (
            <Box>
              <Alert
                statusIconAriaLabel="Error"
                type="error"
                header={error.name}
              >
                {error.message}
              </Alert>
            </Box>
          )}
          <Table
            columnDefinitions={[
              {
                id: "name",
                header: "Name",
                cell: (item: FacilitiesListOutput) => (
                  <Link href={`facilities/${item.facilityId}`}>
                    {item.name}
                  </Link>
                ),
                sortingField: "name",
                isRowHeader: true,
              },
              {
                id: "description",
                header: "Description",
                cell: (item: FacilitiesListOutput) => item.description,
                isRowHeader: true,
              },
              {
                id: "location",
                header: "Location",
                cell: (item: FacilitiesListOutput) =>
                  item.location.address || "",
                isRowHeader: true,
              },
            ]}
            wrapLines
            trackBy="facilityId"
            items={
              data?.pages.flatMap((page) => {
                return page.facilitiesList;
              }) || []
            }
            loadingText="Loading resources"
            empty={
              <Box
                margin={{ vertical: "xs" }}
                textAlign="center"
                color="inherit"
              >
                <b>No resources</b>
              </Box>
            }
            footer={
              <Box textAlign="center">
                <div ref={observerTarget}>
                  <Button
                    onClick={() => {
                      hasNextPage && !isFetching && nextPage();
                    }}
                    disabled={!hasNextPage}
                    loading={isFetching}
                    loadingText="Loading..."
                  >
                    {isFetching
                      ? "Loading..."
                      : hasNextPage
                        ? "View more"
                        : "All facilities loaded"}
                  </Button>
                </div>
              </Box>
            }
          />
        </SpaceBetween>
      </Container>
    </PageWrapper>
  );
};

export default FacilityList;
