/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { QuicksightDashboardObject } from './QuicksightDashboardObject';
import {
    QuicksightDashboardObjectFromJSON,
    QuicksightDashboardObjectFromJSONTyped,
    QuicksightDashboardObjectToJSON,
} from './QuicksightDashboardObject';

/**
 * 
 * @export
 * @interface Quicksight
 */
export interface Quicksight {
    /**
     * 
     * @type {QuicksightDashboardObject}
     * @memberof Quicksight
     */
    quicksight: QuicksightDashboardObject;
}


/**
 * Check if a given object implements the Quicksight interface.
 */
export function instanceOfQuicksight(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "quicksight" in value;

    return isInstance;
}

export function QuicksightFromJSON(json: any): Quicksight {
    return QuicksightFromJSONTyped(json, false);
}

export function QuicksightFromJSONTyped(json: any, ignoreDiscriminator: boolean): Quicksight {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'quicksight': QuicksightDashboardObjectFromJSON(json['quicksight']),
    };
}

export function QuicksightToJSON(value?: Quicksight | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quicksight': QuicksightDashboardObjectToJSON(value.quicksight),
    };
}

