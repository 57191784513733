/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Quicksight,
    instanceOfQuicksight,
    QuicksightFromJSON,
    QuicksightFromJSONTyped,
    QuicksightToJSON,
} from './Quicksight';

/**
 * @type DataSourceObject
 * 
 * @export
 */
export type DataSourceObject = Quicksight;

export function DataSourceObjectFromJSON(json: any): DataSourceObject {
    return DataSourceObjectFromJSONTyped(json, false);
}

export function DataSourceObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSourceObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...QuicksightFromJSONTyped(json, true) };
}

export function DataSourceObjectToJSON(value?: DataSourceObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfQuicksight(value)) {
        return QuicksightToJSON(value as Quicksight);
    }

    return {};
}

