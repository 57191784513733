import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  ReactNode,
} from "react";

interface ConfigContextProps {
  data: any;
  loading: boolean;
  error: Error | null;
}

const ConfigContext = createContext<ConfigContextProps | undefined>(undefined);

interface ConfigProviderProps {
  children: ReactNode;
  configUrl: string;
}

const ConfigProvider: React.FC<ConfigProviderProps> = ({
  children,
  configUrl,
}) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = async (url: string, options: RequestInit = {}) => {
    setLoading(true);
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error("Unable to fetch config from network");
      }
      const result = await response.json();
      setData(result);
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void (async () => {
      try {
        await fetchData(configUrl);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  return (
    <ConfigContext.Provider value={{ data, loading, error }}>
      {!!data ? children : <p>Loading config...</p>}
    </ConfigContext.Provider>
  );
};

const useConfig = (): ConfigContextProps => {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error("useConfig must be used within a ConfigProvider");
  }
  return context;
};

export { ConfigProvider, useConfig };
