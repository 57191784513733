import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BookmarkObject } from "api-typescript-react-query-hooks";

export interface BookmarksState {
  bookmarks: BookmarkObject[];
}

const initialState: BookmarksState = {
  bookmarks: [],
};

export const bookmarksSlice = createSlice({
  name: "bookmarks",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<BookmarkObject[]>) => {
      state.bookmarks = action.payload;
    },
    add: (state, action: PayloadAction<BookmarkObject>) => {
      state.bookmarks = [...state.bookmarks, action.payload];
    },
    remove: (state, action: PayloadAction<BookmarkObject>) => {
      state.bookmarks = state.bookmarks.filter(
        (b) =>
          b.objectId != action.payload.objectId ||
          b.objectType != action.payload.objectType,
      );
    },
  },
});

export const { set, add, remove } = bookmarksSlice.actions;

export default bookmarksSlice.reducer;
