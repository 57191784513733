import { SVGProps } from "react";
import { JSX } from "react/jsx-runtime";

export const DashboardIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>,
) => (
  <span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      focusable="false"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        stroke="#E9EBED"
        strokeMiterlimit={10}
        strokeWidth={1}
        d="M0 8A1 1 0 0016 8 1 1 0 000 8M8 8 8 8 13 2M8 8 16 8"
      />
    </svg>
  </span>
);
