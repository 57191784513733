import { BookmarkObjectType } from "api-typescript-react-query-hooks";

export function resourceUrlBuilder(
  resourceType: BookmarkObjectType,
  resourceId: string,
) {
  if (resourceType == "FacilitySite") {
    return `/facilities/${resourceId}`;
  }
  return `/dashboards/${resourceId}`;
}
