import styled from "@emotion/styled";
import { useLayoutEffect, useRef, useState } from "react";

export const EmbedDashboard = ({
  embed_url,
}: {
  embed_url: string;
}): JSX.Element => {
  const wrapper = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLIFrameElement>(null);
  const [width, setWidth] = useState(500);
  const [height, setHight] = useState(800);

  useLayoutEffect(() => {
    const measureWidth = () => {
      if (wrapper.current) {
        setWidth(wrapper.current.getBoundingClientRect().width);
        setHight(wrapper.current.getBoundingClientRect().height);
      }
    };
    measureWidth();

    const handleResize = () => {
      measureWidth();
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [wrapper, ref]);

  const Iframe = styled.iframe`
    border: none;
    overflow: hidden;
  `;

  return (
    <div ref={wrapper}>
      <Iframe
        ref={ref}
        src={embed_url}
        width={width}
        height={height}
        allowFullScreen={true}
        scrolling="no"
      />
    </div>
  );
};
