import {
  Box,
  Button,
  ColumnLayout,
  Container,
  Header,
  SpaceBetween,
  Spinner,
  TextFilter,
} from "@cloudscape-design/components";
import styled from "@emotion/styled";
import {
  DashboardOutput,
  FacilityFieldsComplete,
  ListFacilityDashboardsRequest,
  useListFacilityDashboards,
} from "api-typescript-react-query-hooks";
import { Fragment, useEffect, useRef, useState } from "react";
import { DashboardCard } from "./DashboardCard";
import { useAuth } from "../../../app/components/AuthProvider";
import { strToTitleCase } from "../../../utils";
import { errorUnknown } from "../../../utils/errorlog";

export const FacilityDashboard = ({
  facility,
}: {
  facility: FacilityFieldsComplete;
}): JSX.Element => {
  const [filteringText, setFilteringText] = useState("");
  const [checkedDashboard, setCheckedDashboard] = useState<
    string | undefined
  >();
  const observerTarget = useRef(null);
  const auth = useAuth();

  const params: ListFacilityDashboardsRequest = {
    facilityId: facility.facilityId,
  };

  const { data, hasNextPage, fetchNextPage, isFetching } =
    useListFacilityDashboards(params);
  const dashboards: DashboardOutput[] =
    data?.pages.flatMap((page) => page.facilityDashboardList) || [];

  const nextPage = () => {
    fetchNextPage().catch((err) => {
      errorUnknown(err);
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          nextPage();
        }
      },
      { threshold: 1 },
    );
    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }
    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget]);

  if (isFetching)
    return (
      <Box padding={{ horizontal: "xl" }}>
        <Spinner variant="disabled" />
        <Box variant="span" color="text-status-inactive" margin={"xs"}>
          Loading dashboards
        </Box>
      </Box>
    );
  if (!dashboards.length) {
    return (
      <Box padding={{ horizontal: "xl" }}>
        <Box variant="span" margin={"xs"}>
          No dashboards found
        </Box>
      </Box>
    );
  }

  return (
    <Box padding={{ horizontal: "xl" }}>
      <SpaceBetween size="xl">
        <Container>
          <SpaceBetween direction="vertical" size="l">
            <Header
              variant="h2"
              actions={
                <Fragment>
                  {auth.isAdmin && (
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        href={
                          checkedDashboard
                            ? `/dashboards/${checkedDashboard}/edit`
                            : undefined
                        }
                        disabled={!checkedDashboard}
                      >
                        Edit Dashboard
                      </Button>
                      <Button
                        iconName="add-plus"
                        variant="primary"
                        href="/dashboards/create"
                      >
                        Create Dashboard
                      </Button>
                    </SpaceBetween>
                  )}
                </Fragment>
              }
            >
              <strong>{`${strToTitleCase(facility.name)} Dashboards`}</strong>
            </Header>
            <FlexSpace>
              <Search
                filteringText={filteringText}
                filteringPlaceholder="Find dashboards"
                filteringAriaLabel="Find dashboards"
                onChange={({ detail }) =>
                  setFilteringText(detail.filteringText)
                }
              />
            </FlexSpace>
          </SpaceBetween>
        </Container>
        <ColumnLayout columns={2}>
          {dashboards.map((dashboard, key) => {
            return (
              <DashboardCard
                key={key}
                dashboardId={dashboard.dashboardId}
                title={dashboard.title}
                dashboardType={dashboard.dashboardType}
                dashboardReportsType={dashboard.dashboardReportsType}
                description={dashboard?.description}
                embedUrl={dashboard.embedUrl}
                selected={checkedDashboard}
                setSelectedDashboard={setCheckedDashboard}
              />
            );
          })}
        </ColumnLayout>
        <Box textAlign="center">
          <div ref={observerTarget}>
            <Button
              onClick={() => {
                hasNextPage && !isFetching && nextPage();
              }}
              disabled={!hasNextPage}
              loading={isFetching}
              loadingText="Loading..."
            >
              {isFetching
                ? "Loading..."
                : hasNextPage
                  ? "View more"
                  : "All dashboards loaded"}
            </Button>
          </div>
        </Box>
      </SpaceBetween>
    </Box>
  );
};

const FlexSpace = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2em;
`;

const Search = styled(TextFilter)`
  flex-grow: 1;
`;
