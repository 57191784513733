/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SchematicFields } from './SchematicFields';
import {
    SchematicFieldsFromJSON,
    SchematicFieldsFromJSONTyped,
    SchematicFieldsToJSON,
} from './SchematicFields';

/**
 * 
 * @export
 * @interface UpdateSchematicRequestContent
 */
export interface UpdateSchematicRequestContent {
    /**
     * 
     * @type {SchematicFields}
     * @memberof UpdateSchematicRequestContent
     */
    schematic: SchematicFields;
}


/**
 * Check if a given object implements the UpdateSchematicRequestContent interface.
 */
export function instanceOfUpdateSchematicRequestContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "schematic" in value;

    return isInstance;
}

export function UpdateSchematicRequestContentFromJSON(json: any): UpdateSchematicRequestContent {
    return UpdateSchematicRequestContentFromJSONTyped(json, false);
}

export function UpdateSchematicRequestContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSchematicRequestContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'schematic': SchematicFieldsFromJSON(json['schematic']),
    };
}

export function UpdateSchematicRequestContentToJSON(value?: UpdateSchematicRequestContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'schematic': SchematicFieldsToJSON(value.schematic),
    };
}

