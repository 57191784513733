/* tslint:disable */
/* eslint-disable */
/**
 * FacilityHubService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuicksightDashboardObject
 */
export interface QuicksightDashboardObject {
    /**
     * 
     * @type {string}
     * @memberof QuicksightDashboardObject
     */
    quicksightDashboardId: string;
    /**
     * 
     * @type {string}
     * @memberof QuicksightDashboardObject
     */
    awsAccountId: string;
    /**
     * 
     * @type {string}
     * @memberof QuicksightDashboardObject
     */
    awsRegion: string;
    /**
     * 
     * @type {string}
     * @memberof QuicksightDashboardObject
     */
    namespace?: string;
}


/**
 * Check if a given object implements the QuicksightDashboardObject interface.
 */
export function instanceOfQuicksightDashboardObject(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "quicksightDashboardId" in value;
    isInstance = isInstance && "awsAccountId" in value;
    isInstance = isInstance && "awsRegion" in value;

    return isInstance;
}

export function QuicksightDashboardObjectFromJSON(json: any): QuicksightDashboardObject {
    return QuicksightDashboardObjectFromJSONTyped(json, false);
}

export function QuicksightDashboardObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuicksightDashboardObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'quicksightDashboardId': json['quicksightDashboardId'],
        'awsAccountId': json['awsAccountId'],
        'awsRegion': json['awsRegion'],
        'namespace': !exists(json, 'namespace') ? undefined : json['namespace'],
    };
}

export function QuicksightDashboardObjectToJSON(value?: QuicksightDashboardObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quicksightDashboardId': value.quicksightDashboardId,
        'awsAccountId': value.awsAccountId,
        'awsRegion': value.awsRegion,
        'namespace': value.namespace,
    };
}

